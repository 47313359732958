<template>
	<div>
		<p class="text-center">
			{{ $t("confirm_msg", [$t(actionMsg)]) }}
		</p>
		<div class="row justify-content-center mt-5">
			<div class="col-auto">
				<c-button
					:loading="loading"
					classes="btn-gray px-5 mb-3 mb-sm-0"
					:text="$t('cancel')"
					:clicked="cancel"
				/>
			</div>
			<div class="col-auto">
				<c-button
					:loading="loading"
					classes="px-5 mb-3 mb-sm-0"
					:text="$t('confirm')"
					:clicked="confirm"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "confirm",
		components: {
			cButton: () => import("@/components/button"),
		},
		props: {
			actionMsg: {
				type: String,
				default: "",
			},
			loading: {
				type: Boolean,
				default: false,
			},
			cancel: {
				type: Function,
				default: () => {},
			},
			confirm: {
				type: Function,
				default: () => {},
			},
		},
	};
</script>
