<template>
	<div class="d-flex justify-content-center">
		<div style="max-width: 500px">
			<h5 class="text-center">
				<small>
					{{ $t("account.send_two_fac_every_login") }}
				</small>
			</h5>
			<c-input
				type="number"
				id="phone_number"
				classes="mx-0 mb-0 mt-4"
				titleAlign="top"
				:title="$t('account.phone_number')"
				:placeholder="$t('account.input_phone_number')"
				v-model="phone"
			>
				<template v-slot:prepend>
					<div class="input-group-prepend">
						<c-select
							selectClasses="no-focus"
							:placeholder="$t('register.select_country')"
							:list="translate(countries, 'countries')"
							optionKey="text"
							v-model="country"
							:changed="changeCountry"
						/>
					</div>
					<div v-if="countryCode" class="input-group-prepend">
						<span class="input-group-text">
							{{ countryCode }}
						</span>
					</div>
				</template>
			</c-input>
			<p class="mt-2">
				<small class="color-orange">
					{{ $t("account.agree_sms_provide_phone_number") }}
				</small>
			</p>
			<div class="d-flex justify-content-center mt-5">
				<c-button
					color="gray"
					classes="px-5 mr-2"
					:text="$t('account.cancel')"
					:clicked="cancel"
				/>
				<c-button
					loading
					classes="px-5 ml-2"
					:text="$t('account.next_step')"
					:disabled="!phone || !countryCode"
					:clicked="clickNext"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, helper } from "@/utils";

	export default {
		name: "enableTwoFac",
		components: {
			cInput: () => import("@/components/input"),
			cSelect: () => import("@/components/select"),
			cButton: () => import("@/components/button"),
		},
		props: {
			data: {
				type: Object,
				default: {},
			},
			cancel: {
				type: Function,
				default: () => {},
			},
			next: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				phone: "",
				countries: Object.keys(
					this.$i18n.messages[this.$i18n.locale].countries
				),
				country: "",
				countryCode: "",
			};
		},
		methods: {
			translate: helper.translate,
			changeCountry(item) {
				this.countryCode = "+" + keys.countryCodes[item.key];
			},
			clickNext() {
				this.next({
					phone: this.phone,
					country: this.countries[this.country],
					countryCode: this.countryCode,
				});
			},
		},
		created() {
			if (this.data) {
				this.phone = this.data.phone;
				let countryIndex = this.countries.findIndex(
					(i) => i === this.data.country
				);
				this.country = `${countryIndex}`;
				this.countryCode = this.data.countryCode;
			}
		},
	};
</script>
