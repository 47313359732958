<template>
	<div class="container-fluid">
		<!-- margin-bottom for footer total price -->
		<div
			class="bg-white p-2 p-sm-4 shadow-sm"
			style="margin-top: 0.5rem; margin-bottom: 100px"
		>
			<stepper :steps="steps" :step="step" />
			<div class="inner-box p-3 p-sm-4 mb-4">
				<order-state
					:state="state"
					:isUploadVoucher="isUploadVoucher"
					:noUpload="noUpload"
					:orderDetails="orderDetails"
					:repayRouteName="repayRouteName"
					:repayRouteParams="repayRouteParams"
				/>
				<!-- <template v-else>
					<div class="d-flex justify-content-center mb-3">
						<vue-skeleton-loader
							width="123px"
							height="98px"
							:radius="0"
						/>
					</div>
					<div class="d-flex justify-content-center mb-5">
						<vue-skeleton-loader
							width="350px"
							height="38px"
							:radius="0"
						/>
					</div>
					<div class="d-flex justify-content-center">
						<vue-skeleton-loader
							width="150px"
							height="38px"
							:radius="0"
						/>
					</div>
				</template> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, api } from "@/utils";

	export default {
		name: "buyLineOrderS",
		components: {
			stepper: () => import("@/components/stepper"),
			orderState: () => import("@/components/flashMessage/order"),
		},
		data() {
			return {
				// stepper
				steps: [
					this.$t("buyLine.choose_purchase_spec"),
					this.$t("buyLine.pay_order"),
					this.$t("buyLine.config_line"),
				],
				step: 2,
				state: null,
				isUploadVoucher: false,
				noUpload: false,
				orderDetails: "",
				repayRouteName: keys.buyLine + keys.buyLinePayOrder,
				repayRouteParams: "",
			};
		},
		created() {
			if (!this.$route.params) {
				this.$router.push({
					name: keys.buyLine + keys.buyLinePurchaseSpec,
				});
			} else {
				const {
					stepper,
					state,
					isUploadVoucher,
					noUpload,
					orderDetails,
					repayRouteParams,
				} = this.$route.params;
				this.step = stepper;
				this.state = state;
				this.isUploadVoucher = isUploadVoucher;
				this.noUpload = noUpload;
				this.orderDetails = orderDetails;
				this.repayRouteParams = repayRouteParams;
			}
		},
	};
</script>
