<template>
	<div>
		<div id="alert"></div>
		<!-- normal -->
		<div v-if="!isLogin">
			<app-header />
			<router-view :key="$i18n.locale" />
			<!-- key: $i18n.locale (is for force update all UI) -->
		</div>
		<!-- dashboard -->
		<div v-else>
			<!-- side bar -->
			<div id="id_left_sidenav" class="sidenav sidenav-gradient-bg-body">
				<div class="sidenav-gradient-bg-head text-center py-3">
					<a
						href="javascript:void(0)"
						class="closebtn text-white d-block d-lg-none"
						@click="closeNav"
						>&times;</a
					>
					<img
						src="@/assets/imgs/logo_blue_title.png"
						alt="logo"
						style="max-width: 125px"
					/>
				</div>
				<div class="px-3 py-3">
					<template v-for="item in sideMenu">
						<div
							v-if="item.el === 'hr'"
							:key="item.title"
							class="divider bg-light-opacity mx-4 my-3"
						></div>
						<router-link
							v-else-if="item.el === 'link'"
							:key="item.title"
							:to="item.to"
							:class="
								'sidenav-link text-center px-3' +
								(item.to === $route.path ||
								item.to.includes($route.path.split('/')[1])
									? ' active'
									: '')
							"
						>
							<div
								class="d-flex mx-auto pl-0 pl-md-2 pl-lg-4"
								:style="'align-items: center; ' + (item.style || '')"
							>
								<img
									:src="item.icon"
									:alt="item.title"
									class="mr-2"
									style="max-width: 16px"
								/>
								{{ $t(`sideMenu.${item.title}`) }}
							</div>
						</router-link>
					</template>
				</div>
			</div>
			<div id="main" class="overflow-auto">
				<app-header :handlerOpenNav="openNav" />
				<breadcrumb :breadcrumbs="breads" />
				<router-view :key="$i18n.locale" />
				<!-- key: $i18n.locale (is for force update all UI) -->
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, localS, helper } from "@/utils";

	export default {
		name: "App",
		components: {
			appHeader: () => import("@/components/header"),
			breadcrumb: () => import("@/components/breadcrumb"),
		},
		data() {
			return {
				sideMenu: keys.sideMenu,
				breads: this.getBreadcrumb(),
			};
		},
		computed: {
			isLogin() {
				const routeName = this.$route.name;
				return (
					!!this.$store.getters.uid &&
					routeName !== keys.register &&
					routeName !== keys.resetPassword &&
					routeName !== keys.forgetPassword
				);
			},
			expiredDate() {
				return this.$store.getters.expiredDate;
			},
		},
		watch: {
			$route: function () {
				this.checkVersion();
			},
			"$i18n.locale": function () {
				this.breads = this.getBreadcrumb();
			},
		},
		methods: {
			checkVersion() {
				const currentVersion = localS.get(keys.v);
				if (!currentVersion || currentVersion !== process.env.APP_VERSION) {
					// clear localstorage
					localS.clear();

					localS.set(keys.v, process.env.APP_VERSION);
					location.reload();
				} else {
					this.getQuery();
					this.checkLoginExpired();
				}
			},
			checkLoginExpired() {
				const dateToday = this.$moment().format("YYYY-MM-DD");
				if (
					this.$moment(dateToday, "YYYY-MM-DD").isSame(
						this.expiredDate,
						"date"
					)
				) {
					helper.logout();
				} else {
					this.breads = this.getBreadcrumb();
				}
			},
			getQuery() {
				const urlParams = new URLSearchParams(window.location.search);
				const channel = urlParams.get("channel");
				if (channel) {
					localS.set(keys.channel, channel);
				}
			},
			getBreadcrumb() {
				const breads = window.location.pathname
					.split("/")
					.filter((i) => i !== null && i !== "");
				const breadcrumbs = breads.map((i, index) => {
					if (breads.length > 1 && index < breads.length - 1) {
						return {
							title: this.$t(`${i}.title`),
							link: `/${i}`,
						};
					} else {
						return this.$t(`${i}.title`);
					}
				});
				return breadcrumbs;
			},
			// nav
			openNav() {
				document.getElementById("id_left_sidenav").style.width = "250px";
				// window add event
				document.addEventListener("click", this.screenTouch);
			},
			closeNav() {
				document.getElementById("id_left_sidenav").style.width = null;
				// window add event
				document.removeEventListener("click", this.screenTouch);
			},
			// event
			screenTouch(event) {
				const nav = document.getElementById("id_left_sidenav");
				let isClickInsideElement = nav.contains(event.target);
				if (
					!isClickInsideElement &&
					event.target.id !== "id_btn_open_sidenav"
				) {
					//Do something click is outside specified element
					this.closeNav();
				}
			},
		},
		mounted() {
			// get menus
			const navs = [...document.getElementsByClassName("sidenav-link")];
			const _this = this;
			// mobile close menu event
			navs.forEach((e) => {
				e.addEventListener("click", function () {
					_this.closeNav();
				});
			});
		},
		created() {
			window.addEventListener(
				"popstate",
				function (e) {
					// remove model child element
					const elements = document.getElementsByClassName("modal-backdrop");
					while (elements.length > 0) {
						elements[0].parentNode.removeChild(elements[0]);
					}
				},
				false
			);
		},
	};
</script>

<style>
	@import "~bootstrap/dist/css/bootstrap.css";
	@import "~bootstrap-icons/font/bootstrap-icons.css";
	@import "@/assets/styles/main.css";
</style>