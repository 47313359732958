<template>
	<modal
		:closable="closable"
		ref="modal"
		:title="modalTitle"
		classes="modal-lg"
		:onClose="onModalClose"
	>
		<!-- show QR -->
		<div v-if="payment.indexOf('ALIPAY')>-1 || payment.indexOf('UNION')>-1 " class="row text-center">
			<div class="col-12">
				<p class="f14 mb-1 mr-1 user-select-none">
					{{ $t("buyLine.scan_to_pay") }}
				</p>
			</div>
			<div class="col-12">
				<qr-code :size="150" level="M" :value="transferInfo.link_url" />
			</div>
			<div class="col-12">
				<div
					class="
						d-flex
						flex-wrap
						justify-content-center
						align-items-center
					"
				>
					<p class="w-100 mt-2 mb-0">
						{{ $t("buyLine.payment_amount") }}
					</p>
					<p
						class="
							f24
							mb-0
							user-select-none
							color-dark-orange
							font-weight-bold
						"
					>
						{{
							transferInfo.rate === 0 || transferInfo.rate === 1
								? "$ " + transferInfo.price
								: `￥ ${transferInfo.totalMoney} ( $ ${transferInfo.price} )`
						}}
					</p>
				</div>
			</div>
			<div class="col-12 d-flex justify-content-center mt-3">
				<c-button
					classes="mx-2 mt-2"
					styles="min-width: 130px"
					:text="$t('i_have_paid')"
					:clicked="checkPaid"
				/>
				<c-button
					classes="mx-2 mt-2"
					styles="min-width: 130px"
					:text="$tc('unpaid')"
					:clicked="checkPaid"
				/>
			</div>
		</div>
		<!-- offline payment info -->
		<transfer-info
			v-else
			:orderId="transferInfo.parent_order_id"
			:orderAmount="
				transferInfo.rate === 0 || transferInfo.rate === 1
					? '$ ' + transferInfo.price
					: `￥ ${transferInfo.totalMoney} ( $ ${transferInfo.price} )`
			"
			:accBank="
				transferInfo.bank_info ? transferInfo.bank_info.bank_name || '' : ''
			"
			:payee="
				transferInfo.bank_info
					? transferInfo.bank_info.account_name || ''
					: ''
			"
			:receiveAcc="
				transferInfo.bank_info
					? transferInfo.bank_info.bank_account || ''
					: ''
			"
		/>
		<!-- btton for upload voucher -->
		<template v-if="payment === 'OFFLINEPAY'" v-slot:footer>
			<c-button
				styles="min-width: 200px"
				:text="$t('buyLine.upload_payment_voucher')"
				:clicked="clickUploadVoucher"
			/>
			<input
				type="file"
				id="input_payment_voucher"
				style="display: none"
				accept="image/png, image/jpeg, image/jpg"
				@change="onVoucherUploaded"
			/>
			<!--  -->
			<c-button
				v-if="!hideNotUpload"
				styles="min-width: 200px"
				:text="$t('buyLine.not_upload_yet')"
				:clicked="clicknotUpload"
			/>
		</template>
	</modal>
</template>

<script>
	import { danger } from "@/components/alert/index.js";
	import { api } from "@/utils";

	export default {
		name: "paymentModal",
		components: {
			cButton: () => import("@/components/button"),
			modal: () => import("@/components/modal"),
			transferInfo: () => import("@/components/templates/transferInfo"),
			qrCode: () => import("@/components/qrCode"),
		},
		props: {
			closable: {
				type: Boolean,
				default: false,
			},
			modalTitle: {
				type: String,
				default: "",
			},
			hideNotUpload: {
				type: Boolean,
				default: false,
			},
			payment: String,
			transferInfo: Object,
			callback: Function,
		},
		methods: {
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.$store.dispatch("setIsLoading", false);
				this.callback({
					clickCloseModal: this.clickCloseModal,
					closeModal: true,
				});
			},
			// paid or unpaid
			checkPaid() {
				this.callback({ clickCloseModal: this.clickCloseModal });
			},
			// trigger input image
			clickUploadVoucher() {
				document.getElementById("input_payment_voucher").click();
			},
			// on select image, upload to server and get image url
			onVoucherUploaded(e) {
				this.$store.dispatch("setIsLoading", true);

				const voucher = e.target.files[0];
				api.postUploadImg({ file: voucher })
					.then((response) => {
						const { data } = response;
						this.submitPayVoucher(data.file_url || "");
					})
					.catch((err) => {
						document.getElementById("input_payment_voucher").value = "";
						this.$store.dispatch("setIsLoading", false);
						danger(this.$t("please_try_again"));
					});
			},
			// upload pay voucher url
			submitPayVoucher(voucher_url) {
				api.postPayVoucher({
					parent_order_id: this.transferInfo.parent_order_id,
					voucher_url,
				})
					.then((response) => {
						this.callback({
							clickCloseModal: this.clickCloseModal,
							isUploadVoucher: true,
							isUploaded: true,
						});
					})
					.catch((err) => {
						document.getElementById("input_payment_voucher").value = "";
						this.$store.dispatch("setIsLoading", false);
						danger(this.$t("please_try_again"));
					});
			},
			//
			clicknotUpload() {
				this.callback({
					clickCloseModal: this.clickCloseModal,
					isUploadVoucher: true,
					noUpload: true,
				});
			},
		},
	};
</script>
