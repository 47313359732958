<template>
	<nav>
		<ul :class="`pagination pagination-sm justify-content-${align} m-0`">
			<li :class="'page-item' + (current < 2 ? ' disabled' : '')">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					aria-label="Previous"
					@click="prev"
				>
					<span aria-hidden="true">&#10094;</span>
				</a>
			</li>
			<li v-if="current > 14" class="page-item">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="clicked(current - 10 >= 1 ? current - 10 : 1)"
					>{{ current - 10 >= 1 ? current - 10 : 1 }}</a
				>
			</li>
			<li v-if="current > 14" class="page-item">
				<span>&hellip;</span>
			</li>
			<li
				v-for="index in pages()"
				:key="index"
				:class="'page-item' + (index === current ? ' active' : '')"
			>
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="clicked(index)"
					>{{ index }}</a
				>
			</li>
			<li v-if="pageMax > 15 && current < pageMax - 5" class="page-item">
				<span>&hellip;</span>
			</li>
			<li v-if="pageMax > 15 && current < pageMax - 5" class="page-item">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					@click="
						clicked(current + 10 >= pageMax ? pageMax : current + 10)
					"
					>{{ current + 10 >= pageMax ? pageMax : current + 10 }}</a
				>
			</li>
			<li :class="'page-item' + (current === pageMax ? ' disabled' : '')">
				<a
					:class="'page-link' + (noBorder ? ' border-0' : '')"
					href="javascript:void(0);"
					aria-label="Next"
					@click="next"
				>
					<span aria-hidden="true">&#10095;</span>
				</a>
			</li>
			<li>
				<span
					class="d-block text-muted"
					style="padding: 0.25rem 0.5rem; font-size: 0.87rem"
					>{{ $t("total_page_count", [total]) }}</span
				>
			</li>
		</ul>
	</nav>
</template>

<script>
	export default {
		name: "pagination",
		props: {
			align: {
				type: String,
				default: "center",
			},
			noBorder: {
				type: Boolean,
				default: false,
			},
			limit: {
				type: Number,
				default: 15,
			},
			total: {
				type: Number,
				default: 5,
			},
			current: {
				type: Number,
				default: 1,
			},
			onPageChange: {
				type: Function,
				default: () => {},
			},
		},
		watch: {
			total: function (newVal) {
				this.pageMax = Math.ceil(newVal / this.limit);
			},
		},
		data() {
			return {
				pageMax: Math.ceil(this.total / this.limit),
			};
		},
		methods: {
			prev() {
				if (this.current > 1) {
					this.onPageChange(this.current - 1);
				}
			},
			next() {
				if (this.current < this.pageMax) {
					this.onPageChange(this.current + 1);
				}
			},
			clicked(page) {
				this.onPageChange(page);
			},
			//
			pages() {
				const maxPages = 5;
				let startPage, endPage;
				if (this.pageMax <= maxPages) {
					// total pages less than max so show all pages
					startPage = 1;
					endPage = this.pageMax;
				} else {
					// total pages more than max so calculate start and end pages
					let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
					let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
					if (this.current <= maxPagesBeforeCurrentPage) {
						// current page near the start
						startPage = 1;
						endPage = maxPages;
					} else if (
						this.current + maxPagesAfterCurrentPage >=
						this.pageMax
					) {
						// current page near the end
						startPage = this.pageMax - maxPages + 1;
						endPage = this.pageMax;
					} else {
						// current page somewhere in the middle
						startPage = this.current - maxPagesBeforeCurrentPage;
						endPage = this.current + maxPagesAfterCurrentPage;
					}
				}

				// create an array of pages to ng-repeat in the pager control
				let pages = Array.from(Array(endPage + 1 - startPage).keys()).map(
					(i) => startPage + i
				);

				// startPage, endPage
				return pages;
			},
		},
	};
</script>
