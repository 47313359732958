import i18n from "@/i18n";
import * as XLSX from "xlsx";
import * as FileSaver from "file-saver";
import { danger } from "@/components/alert/index.js";
import { del } from "@/utils/localstorage";
import keys from "@/utils/key";
import router from "@/router";

export function queryString(params) {
  return Object.keys(params)
    .map(key => key + "=" + params[key])
    .join("&");
}

export function toFormData(params) {
  let bodyFormData = new FormData();
  Object.keys(params).forEach(key => {
    bodyFormData.append(key, params[key]);
  });
  return bodyFormData;
}

export function getFormatedStringFromDays(numberOfDays) {
  const years = Math.floor(numberOfDays / 365);
  const months = Math.floor((numberOfDays % 365) / 30);
  const days = Math.floor((numberOfDays % 365) % 30);

  return [
    {
      i18n: `date.${years > 1 ? "years" : "year"}`,
      number: years
    },
    {
      i18n: `date.${months > 1 ? "months" : "month"}`,
      number: months
    },
    {
      i18n: `date.${days > 1 ? "days" : "day"}`,
      number: days
    }
  ];
}

export function copyToClipboard(id) {
  if (document.selection) {
    // IE
    let range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(id));
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(document.getElementById(id));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }

  let copyStatus = false;
  try {
    copyStatus = document.execCommand("copy"); // 不是 hidden 才能複製
  } catch (errr) {}

  window.getSelection().removeAllRanges();

  return copyStatus;
}

export function decodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerHTML = text;
  return textArea.value;
}
export function encodeHTMLEntities(text) {
  var textArea = document.createElement("textarea");
  textArea.innerText = text;
  return textArea.innerHTML;
}

export function sortArrayOfObject(ary, key, order = "asc") {
  ary.sort(function(a, b) {
    let valA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key]; // ignore upper and lowercase
    let valB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key]; // ignore upper and lowercase

    let comparison = 0;
    if (valA < valB) {
      comparison = -1;
    }
    if (valA > valB) {
      comparison = 1;
    }

    return order === "desc" ? comparison * -1 : comparison;
  });

  return ary;
}

export function range(min, max) {
  let array = [],
    j = 0;
  for (let i = min; i <= max; i++) {
    array[j] = i;
    j++;
  }
  return array;
}

export function translate(ary, tKey, key) {
  return ary.map(item => ({
    ...(key ? item[key] : { key: item }),
    text: i18n.t(`${tKey}.${key ? item[key] : item}`)
  }));
}

export function validateEmail(value) {
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (value.match(validRegex)) {
    return true;
  } else {
    return false;
  }
}

export function exportToExcel(body = [], head = []) {
  try {
    var isFileSaverSupported = !!new Blob();
    if (isFileSaverSupported) {
      // content inside the sheet
      var ws_data = [];
      if (head.length) {
        ws_data.push(head); // push a row
      }
      ws_data.push(...body); // push a row

      // create the sheet from array
      var ws = XLSX.utils.aoa_to_sheet(ws_data);

      // Creating a Workbook
      var wb = XLSX.utils.book_new();

      // update the workbook properties
      wb.Props = {
        Title: window.location.pathname
          .split("/")
          .filter(i => i !== null && i !== "")
          .map(i => i18n.t(`${i}.title`)),
        Subject: "",
        Author: "GT NET",
        CreatedDate: new Date()
      };

      // create a worksheet
      wb.SheetNames.push("Sheet");

      // assign the sheet object to the workbook Sheets array
      wb.Sheets["Sheet"] = ws;

      // export the workbook as xlsx binary
      var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

      // excel file is octet stream
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
        var view = new Uint8Array(buf); //create uint8array as viewer
        for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
        return buf;
      }

      saveAs(
        new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
        "GT_NET.xlsx"
      );
    } else {
      danger(i18n.t("browser_not_support"));
    }
  } catch (e) {
    console.error(e);
    danger(i18n.t("browser_not_support"));
  }
}

export function logout() {
  del(keys.stateSys);
  del(keys.stateUser);
  del(keys.stateBuy);
  del(keys.stateLine);
  del(keys.stateSubAcc);
  del(keys.stateOrder);
  router.go({ path: keys.login }); // loginMode
}

export const mergeArrayObject = (a, b, p) =>
  a.filter(aa => !b.find(bb => aa[p] === bb[p])).concat(b);

export function roundDecPoint(n) {
  const s = String(n).split(".");
  let dec = s[1];

  if (dec && dec.length > 2) {
    const t = dec.substr(0, 2);
    let t2 = "";
    if (parseInt(dec.substr(2, 1)) > 0) {
      t2 = parseInt(t[1]) + 1;
    }
    dec = t[0] + String(t2);
  }

  return dec ? parseFloat(s[0] + "." + dec) : parseInt(s[0]);
}
