<template>
	<div class="container">
		<div class="position-relative">
			<img
				src="@/assets/imgs/repeat_dot.png"
				alt="dot"
				class="position-absolute d-none d-sm-block"
				style="left: -37px; top: -26px; z-index: -1"
			/>
			<img
				src="@/assets/imgs/repeat_dot.png"
				alt="dot"
				class="position-absolute d-none d-sm-block"
				style="right: -50px; bottom: -41px; z-index: -1"
			/>
			<div v-if="!twoVerify" class="row mb-5">
				<div
					class="
						col-6
						bg-gradient-main
						rounded-left
						pt-5
						pb-4
						px-4
						d-none d-lg-block
					"
				>
					<h4 class="text-white font-weight-bold px-2">
						{{ $t("login.hi") }}
					</h4>
					<h4 class="text-white font-weight-bold px-2">
						{{ $t("login.welcome") }}
					</h4>
					<img
						src="@/assets/imgs/product.png"
						alt="product"
						class="mt-5"
					/>
				</div>
				<div class="col-lg-6 bg-white rounded-right py-5">
					<h4 class="text-center">
						<small class="font-weight-bold">
							{{ $t("login.login_account") }}
						</small>
					</h4>
					<div class="mx-sm-5 px-2 px-sm-5 mt-5">
						<!-- input username -->
						<c-input
							id="username"
							titleAlign="top"
							:title="$t('login.email')"
							:placeholder="$t('login.input_email')"
							v-model="username"
						>
							<template v-slot:prepend>
								<div class="input-group-prepend">
									<div class="input-group-text">
										<img src="@/assets/imgs/user.png" />
									</div>
								</div>
							</template>
						</c-input>
						<!-- input password -->
						<c-input
							class="mt-3"
							:type="inputPwsdType"
							id="password"
							titleAlign="top"
							:title="$t('login.password')"
							:placeholder="$t('login.input_password')"
							v-model="password"
							:onEnter="clickLogin"
						>
							<template v-slot:prepend>
								<div class="input-group-prepend">
									<div class="input-group-text">
										<img src="@/assets/imgs/lock.png" />
									</div>
								</div>
							</template>
							<template v-slot:append>
								<div class="input-group-append">
									<span
										class="input-group-text bg-transparent"
										@click="togglePassword()"
									>
										<img
											src="@/assets/imgs/eyeOpen.png"
											alt="show/hide"
											class="imgTogglePassword"
										/>
									</span>
								</div>
							</template>
						</c-input>
						<!-- forget password -->
						<router-link :to="linkForgetPswd" class="float-right mb-5">
							{{ $t("login.forget_password") }}
						</router-link>
						<!-- btn next -->
						<c-button
							fullWidth
							:text="$t('login.btn_login')"
							:disabled="!username || !password"
							:clicked="clickLogin"
						/>
						<!-- register -->
						<div class="d-flex justify-content-center mt-2">
							<p class="text-muted">
								<small>{{ $t("login.no_account") }}</small>
							</p>
							<router-link :to="linkRegister">
								{{ $t("login.go_register") }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- verify -->
			<div v-else class="bg-white py-5 px-3 px-sm-0 mb-5">
				<h5 class="text-center font-weight-bold my-4">
					{{ $t("login.login_account") }}
				</h5>
				<div class="row justify-content-center">
					<div class="col-md-7 text-center">
						<h5 class="text-muted mb-5">
							<small>
								{{ $t("login.already_send_to", [verifyPhone]) }}
							</small>
						</h5>
						<!-- 4 pin -->
						<pincode-input
							:length="4"
							:autofocus="true"
							v-model="captcha"
							class="mb-5"
						/>
						<!-- btn login -->
						<c-button
							classes="mb-4"
							styles="min-width: 230px"
							:text="$t('login.btn_login')"
							:disabled="captcha.length !== 4"
							:clicked="clickVerify"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, api, helper } from "@/utils";
	import { danger } from "@/components/alert/index.js";
	import PincodeInput from "vue-pincode-input";

	export default {
		name: "login",
		components: {
			PincodeInput,
			cButton: () => import("@/components/button"),
			cInput: () => import("@/components/input"),
		},
		data() {
			return {
				username: "",
				password: "",
				inputPwsdType: "password",
				twoVerify: false,
				captcha: "",
				linkForgetPswd: keys.forgetPassword,
				linkRegister: keys.register,
			};
		},
		computed: {
			isLoading() {
				return this.$store.getters.isLoading;
			},
			verifyPhone() {
				return this.$store.getters.verifyPhone;
			},
			lineList() {
				return this.$store.getters.lineList;
			},
		},
		watch: {
			captcha(newVal) {
				if (newVal.length === 4) {
					this.clickVerify();
				}
			},
		},
		methods: {
			togglePassword() {
				this.inputPwsdType =
					this.inputPwsdType == "text" ? "password" : "text";
				$(".imgTogglePassword").attr(
					"src",
					this.inputPwsdType == "text"
						? require("@/assets/imgs/eyeClose.png")
						: require("@/assets/imgs/eyeOpen.png")
				);
			},
			clickLogin() {
				const _this = this;

				if (!this.username || !helper.validateEmail(this.username)) {
					danger(this.$t("invalid_email"));
					return;
				}
				if (this.password.length < 6) {
					danger(this.$t("password_length"));
					return;
				}

				this.$store.dispatch("setIsLoading", true);

				api.login({
					username: this.username,
					password: this.password,
				})
					.then((response) => {
						_this.$store.dispatch("setIsLoading", false);
						_this.fetchUser(response.data, response.code === 401);
					})
					.catch((error) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			clickVerify() {
				this.$store.dispatch("setIsLoading", true);

				const _this = this;
				api.postCaptcha({ captcha: this.captcha })
					.then((response) => {
						_this.$store.dispatch("setIsLoading", false);
						_this.fetchUser(response.data);
					})
					.catch((error) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			fetchUser(payload, verify = false) {
				const _this = this;
				this.$store.dispatch("fetchUser", payload).then(() => {
					if (verify) {
						_this.twoVerify = true;
						_this.$store.dispatch("setIsLoading", true);
						api.getCaptcha()
							.then((response) => {
								_this.$store.dispatch("setIsLoading", false);
							})
							.catch((error) => {
								_this.$store.dispatch("setIsLoading", false);
							});
					} else {
						// default to buy line page
						_this.$router.push({
							name: keys.buyLine + keys.buyLinePurchaseSpec,
						});

						_this.$store
							.dispatch("fetchLineInfo", {
								page: 1, // int
								limit: 15, // int  defaut:15
							})
							.then(() => {
								if (_this.lineList && _this.lineList.length) {
									_this.$router.push({
										name: keys.lineManagement + keys.lineManagementList,
									});
								}
							});
					}
				});
			},
		},
	};
</script>
