<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<tab-bar id="id_order_filter_tab">
				<tabs
					active
					id="success"
					:title="$t('order.succeeded')"
					:clicked="() => changeTab(1)"
				/>
				<tabs
					id="confirm"
					:title="$t('order.to_be_confirm')"
					:clicked="() => changeTab(2)"
				/>
				<tabs
					id="config"
					:title="$t('order.to_be_config')"
					:clicked="() => changeTab(3)"
				/>
			</tab-bar>
			<div class="border-right border-bottom border-left p-3">
				<!-- filter -->
				<div class="order-filterbar">
					<!-- min-width box -->
					<div class="container-fluid">
						<div class="row align-items-center">
							<div class="col-4">
								<c-select
									rounded
									titleClasses="filter-input text-right pr-0"
									:title="$tc('order.order_type', 2)"
									:placeholder="$t('order.all_types')"
									:list="filterOrderTypeList"
									optionKey="text"
									v-model="filterOrderType"
								/>
							</div>
							<div class="col-4">
								<c-input
									id="order_number"
									titleClasses="text-right pr-0"
									:title="$tc('order.order_number', 2)"
									:placeholder="$t('order.input_order_number')"
									v-model="filterOrderId"
								/>
							</div>
							<div class="col-4">
								<c-select
									rounded
									titleClasses="text-right pr-0"
									:title="$tc('order.payment_method', 2)"
									:placeholder="$t('order.all_types')"
									:list="filterOrderPayMethodList"
									optionKey="alias_name"
									v-model="filterOrderPayMethod"
								/>
							</div>
						</div>
						<div class="row align-items-center">
							<div class="col-6">
								<date-picker
									style="margin-bottom: 0px"
									titleClasses="text-right pr-0"
									:title="$tc('order.creation_time', 2)"
									:onChange="changeFilterDate"
								/>
							</div>
							<div class="col">
								<div class="d-flex justify-content-end">
									<c-button
										loading
										classes="px-4 mr-2"
										:clicked="clickSearch"
									>
										<img
											src="@/assets/imgs/search.png"
											alt="search"
											class="pr-1"
											style="margin-top: -3px"
										/>
										{{ $t("order.search") }}
									</c-button>
									<c-button classes="px-4 ml-2" :clicked="clickExport">
										<img
											src="@/assets/imgs/export.png"
											alt="export"
											class="pr-1"
											style="margin-top: -3px"
										/>
										{{ $t("order.export") }}
									</c-button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div
					class="row mt-4"
					style="
						margin-left: -17px;
						margin-right: -17px;
						margin-bottom: -1rem;
					"
				>
					<!-- table -->
					<c-table
						style="margin-bottom: -17px"
						:tableClass="
							orderList && !orderList.length
								? 'th-style1 table-hover-non'
								: 'th-style1'
						"
						tableStyle="min-width: 860px"
						:columns="columns"
						:data="orderList"
						:limit="limit"
						:rowCounts="count"
						:page="page"
						:toPage="onPageChanged"
					/>
					<!-- input for upload voucher -->
					<input
						type="file"
						id="input_payment_voucher"
						style="display: none"
						accept="image/png, image/jpeg, image/jpg"
						@change="onVoucherUploaded"
					/>
				</div>
			</div>
		</div>
		<modal-image
			v-if="showImage"
			:modalTitle="$t('order.payment_voucher')"
			:imgUrl="showImageUrl"
			:callback="viewImageCallback"
		/>
		<payment-modal
			v-if="showPaymentModal"
			closable
			hideNotUpload
			:modalTitle="$t('buyLine.transfer_info')"
			:payment="transferInfo.payment"
			:transferInfo="{ ...transferInfo }"
			:callback="paymentModalCallback"
		/>
	</div>
</template>

<script>
	import { tabBar, tabs } from "@/components/tab";
	import { columnOrder, columnOrderToBeConfirm } from "@/components/table/data";
	import { keys, api, helper } from "@/utils";
	import { danger, success } from "@/components/alert/index.js";

	export default {
		name: "orderList",
		components: {
			tabBar,
			tabs,
			cSelect: () => import("@/components/select"),
			cInput: () => import("@/components/input"),
			datePicker: () => import("@/components/datePicker"),
			cButton: () => import("@/components/button"),
			cTable: () => import("@/components/table"),
			modalImage: () => import("@/components/templates/modalImage"),
			paymentModal: () => import("@/components/templates/paymentModal"),
		},
		data() {
			return {
				// list
				filterOrderTypeList: [
					{
						type: 0,
						text: this.$t("order.new_purchase"),
					},
					{
						type: 1,
						text: this.$t("order.renew"),
					},
					{
						type: 2,
						text: this.$t("order.edit_bandwidth_device"),
					},
				],
				filterOrderPayMethodList: helper.mergeArrayObject(
					keys.defaultPayMethod,
					this.$store.getters.paymentList
						? this.$store.getters.paymentList
						: [],
					"payment"
				),
				// filter
				tab: 1,
				filterOrderType: "",
				filterOrderPayMethod: "",
				filterOrderId: "",
				filterDate: null,
				// table
				page: 1,
				limit: 15,
				columns: columnOrder({ operate: this.operate }),
				// view image btn
				showImage: false,
				showImageUrl: "",
				// payment modal
				showPaymentModal: false,
				transferInfo: {},
			};
		},
		computed: {
			orderList() {
				return this.$store.getters.orderList;
			},
			count() {
				return this.$store.getters.orderCount;
			},
		},
		methods: {
			changeTab(tab) {
				this.tab = tab;
				// clear
				this.filterOrderType = "";
				this.filterOrderPayMethod = "";
				this.filterOrderId = "";
				this.page = 1;

				if (tab === 2) {
					this.columns = columnOrderToBeConfirm({ operate: this.operate });
				} else {
					this.columns = columnOrder({ operate: this.operate });
				}

				this.getOrder();
			},
			// filter
			changeFilterDate(date) {
				this.filterDate = date;
			},
			clickSearch() {
				this.page = 1;
				this.getOrder();
			},
			clickExport() {
				this.getOrder({ export: 1 });
			},
			// table
			onPageChanged(page) {
				this.page = page;
				this.getOrder();
			},
			operate(index, params) {
				switch (index) {
					case 5: // show payment info modal upload payment voucher
					case 6: // show payment info modal edit payment voucher
						this.transferInfo = {
							payment: params.payment,
							parent_order_id: params.parent_order_id,
							rate: 0,
							price: helper.roundDecPoint(params.total_money / 100),
							bank_info: params.bank_info,
						};
						this.showPaymentModal = true;
						break;
					case 7: // view payment voucher
						this.showImage = true;
						this.showImageUrl = params.voucher_url;
						break;
					default:
						// view details
						this.$router.push({
							name: keys.order + keys.orderDetails,
							params: { data: params },
						});
				}
			},
			// on select image, upload to server and get image url
			onVoucherUploaded(e) {
				this.$store.dispatch("setIsLoading", true);

				const voucher = e.target.files[0];
				api.postUploadImg({ file: voucher })
					.then((response) => {
						const { data } = response;
						this.submitPayVoucher(data.file_url || "");
					})
					.catch((err) => {
						document.getElementById("input_payment_voucher").value = "";
						this.$store.dispatch("setIsLoading", false);
						danger(this.$t("please_try_again"));
					});
			},
			// upload pay voucher url
			submitPayVoucher(voucher_url) {
				api.postPayVoucher({
					parent_order_id: this.transferInfo.parent_order_id,
					voucher_url,
				})
					.then((response) => {
						document.getElementById("input_payment_voucher").value = "";
						this.clickCloseModal();
						success(
							this.$tc("uploaded_successful", 2, [
								this.$t("buyLine.payment_voucher"),
							])
						);
					})
					.catch((err) => {
						document.getElementById("input_payment_voucher").value = "";
						this.$store.dispatch("setIsLoading", false);
						danger(this.$t("please_try_again"));
					});
			},
			// view image
			viewImageCallback(params) {
				if (params.closeModal) {
					this.showImage = false;
					this.showImageUrl = "";
				}
			},
			// payment modal
			paymentModalCallback(params) {
				if (params.closeModal) {
					this.transferInfo = {};
					this.showPaymentModal = false;
				} else if (params.isUploaded) {
					this.transferInfo = {};
					this.showPaymentModal = false;
					success(this.$tc("edit_success", 2));
				}
			},
			// get table list
			getOrder(othersParams = {}) {
				// filter
				let searchParams = {
					type: this.filterOrderType
						? this.filterOrderTypeList[this.filterOrderType].type
						: "",
					payment: this.filterOrderPayMethod
						? this.filterOrderPayMethodList[this.filterOrderPayMethod]
								.payment
						: "",
					parent_order_id: this.filterOrderId,
					created: this.filterDate
						? `${this.filterDate.from}~${this.filterDate.to}`
						: "",
				};
				Object.keys(searchParams).forEach((key) => {
					if (!searchParams[key]) {
						delete searchParams[key];
					}
				});

				let params = {
					page: this.page, // int
					limit: this.limit, // int  defaut:15
					tab: this.tab,
					searchParams: Object.keys(searchParams).length
						? JSON.stringify(searchParams)
						: "",
					...othersParams,
				};
				if (!params.searchParams) {
					delete params.searchParams;
				}
				this.$store
					.dispatch("export" in params ? "exportOrder" : "fetchOrder", params)
					.then(() => {
						this.$store.dispatch("setIsLoading", false);
					})
					.catch(() => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
		},
		mounted() {
			if (this.$route.params) {
				const params = this.$route.params;

				if (params.tab) {
					$(`#id_order_filter_tab li:nth-child(${params.tab}) a`).tab("show");
					this.tab = params.tab;
				}
				if (params.orderType) {
					this.filterOrderType = String(params.orderType);
				}
			}
			this.getOrder();
		},
	};
</script>
