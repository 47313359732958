<template>
    <div class="flex-box">
      <div>请使用对应的app进行扫码</div>
      <vue-qr class="qr-code" :logoSrc="imageUrl" :text="qrCodeUrl" :size="150" />
    </div>
  </template>
   
  <script>
  import vueQr from 'vue-qr'; // vue2.0
  // import vueQr from 'vue-qr/src/packages/vue-qr.vue'; // vue3.0 (support vite)
   
  export default {
    components: { vueQr },
    data() {
      return {
        qrCodeUrl: 'none', // 二维码的内容
        imageUrl: require('@/assets/imgs/circleWhiteUser2x.png') // icon路径
      };
    },
    methods: {}
  };
  </script>
   
  <style scoped>
  .flex-box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .qr-code {
    margin-top: 20px;
    background-color: #fff;
    border: 1px solid red;
  }
  </style>