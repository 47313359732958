<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<c-table tableStyle="min-width: 700px" :columns="columns" :data="rows">
				<template v-slot:tr>
					<tr class="table-hover-non">
						<td :colspan="columns.length">
							<div class="d-flex justify-content-end">
								<c-button
									:rounded="false"
									class="mr-2 mr-sm-3"
									classes="px-4"
									:text="$t('lineManagement.renew')"
									:clicked="clickRenew"
								/>
								<c-button
									:rounded="false"
									class="mr-2 mr-sm-3"
									classes="btn-outline-main px-5"
									:text="$t('lineManagement.edit_bandwidth_device')"
									:clicked="clickEditBandwidthDeviceNum"
								/>
								<c-button
									:rounded="false"
									:classes="`btn-outline-${
										rows[0].state === 1 ? 'orange' : 'main'
									} px-5`"
									:text="
										$t(
											`lineManagement.${
												rows[0].state === 1
													? 'pause_line'
													: 'start_line'
											}`
										)
									"
									:clicked="clickPauseLine"
								/>
							</div>
						</td>
					</tr>
				</template>
			</c-table>
			<!-- 子账户 -->
			<div class="row justify-content-between mt-4 mb-3">
				<div class="col-auto mb-2">
					<h5>
						<small class="font-weight-bold">
							{{ $t("lineManagement.sub_account") }}
						</small>
					</h5>
				</div>
				<div class="col-auto">
					<div class="row align-items-center">
						<div class="col-auto px-sm-0 pr-0">
							<c-input
								id="search_sub_account"
								class="mb-2"
								style="max-width: 180px"
								inputClasses="bg-light-gray color-dark-gray border-right-0"
								:placeholder="$t('lineManagement.search_sub_account')"
								v-model="searchSubAccount"
							/>
						</div>
						<div class="col-auto">
							<c-button
								loading
								classes="px-2 px-sm-4 mb-2"
								:text="$t('lineManagement.search')"
								:clicked="clickSearchSubAccount"
							/>
						</div>
					</div>
				</div>
			</div>
			<c-table
				tableStyle="min-width: 450px"
				:columns="columnsSubAccount"
				:data="rowsSubAccount"
				:limit="subAccountLimit"
				:rowCounts="subAccountCount"
				:page="subAccountPage"
				:toPage="onSubAccountPageChanged"
			></c-table>
			<!-- 白名单 -->
			<div class="row justify-content-between mt-4 mb-3">
				<div class="col-auto">
					<h5>
						<small class="font-weight-bold">
							{{ $t("lineManagement.whitelist") }}
						</small>
					</h5>
				</div>
				<div class="col-auto">
					<c-button
						classes="px-4"
						:text="'+' + $t('lineManagement.add')"
						:clicked="clickWhitelistAdd"
					/>
				</div>
			</div>
			<c-table
				tableStyle="min-width: 450px"
				:columns="columnsWhitelist"
				:data="rowsWhitelist"
				:limit="whitelistLimit"
				:rowCounts="whitelistCount"
				:page="whitelistPage"
				:toPage="onWhitelistPageChanged"
			></c-table>
		</div>
		<modal
			v-if="modalShow"
			ref="modal"
			:title="modalTitle"
			classes="modal-lg"
			:onClose="onModalClose"
		>
			<t-whitelist
				loading
				v-if="modalCase === 1"
				:clickAdd="clickAddWhitelist"
			/>
			<t-edit-password
				v-if="modalCase === 2"
				loading
				:oldPassword="false"
				:edit="clickEditPaswd"
				:cancel="clickCloseModal"
			/>
			<t-confirm
				v-if="modalCase === 3 || modalCase === 4"
				loading
				:actionMsg="actionMsg"
				:cancel="clickCloseModal"
				:confirm="clickConfirmAction"
			/>
		</modal>
	</div>
</template>

<script>
	import { keys, api } from "@/utils";
	import {
		columnLineDetails,
		columnLineSubAccount,
		columnLineWhitelist,
	} from "@/components/table/data";
	import { success, danger } from "@/components/alert/index.js";

	export default {
		name: "lineManagementDetails",
		components: {
			cTable: () => import("@/components/table"),
			cInput: () => import("@/components/input"),
			cButton: () => import("@/components/button"),
			modal: () => import("@/components/modal"),
			tWhitelist: () => import("@/components/templates/whitelist"),
			tEditPassword: () => import("@/components/templates/editPassword"),
			tConfirm: () => import("@/components/templates/confirm"),
		},
		data() {
			return {
				searchSubAccount: "",
				currentEditSubAcc: null,
				currentWhitelist: null,
				// details
				columns: columnLineDetails(),
				rows: [],
				// 子账户
				columnsSubAccount: columnLineSubAccount({
					switchState: this.switchSubAccountState,
					operate: this.subAccountOperate,
				}),
				rowsSubAccount: [],
				subAccountPage: 1,
				subAccountLimit: 15,
				subAccountCount: 0,
				// 白名单
				columnsWhitelist: columnLineWhitelist({
					operate: this.whitelistOperate,
				}),
				rowsWhitelist: [],
				whitelistPage: 1,
				whitelistLimit: 15,
				whitelistCount: 0,
				// modal
				modalShow: false,
				modalTitle: "",
				modalCase: "",
				actionMsg: "",
			};
		},
		methods: {
			// details
			clickRenew() {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementRenew,
					params: { id: this.rows[0].id },
				});
			},
			clickEditBandwidthDeviceNum() {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementModifyBandwidth,
					params: { id: this.rows[0].id },
				});
			},
			clickPauseLine() {
				this.modalTitle = this.$t("confirm");
				this.modalCase = 4;
				this.modalShow = true;
				this.actionMsg = this.$t(
					`lineManagement.${
						this.rows[0].state === 1 ? "pause_line" : "start_line"
					}`
				).toLowerCase();
			},
			// 子账户
			clickSearchSubAccount() {
				this.getSubAccount();
			},
			switchSubAccountState(params) {
				api.patchCompanyUserInfo({
					uid: params.uid,
					state: params.state ? 0 : 1,
				}).then((response) => {
					this.getSubAccount();
				});
			},
			subAccountOperate(index, params) {
				switch (index) {
					default:
						this.modalTitle = this.$t("lineManagement.change_password");
						this.modalCase = 2;
						this.modalShow = true;
						this.currentEditSubAcc = params;
						break;
				}
			},
			onSubAccountPageChanged(page) {
				this.subAccountPage = page;
				this.getSubAccount();
			},
			// modal
			clickEditPaswd(i) {
				api.patchCompanyUserInfo({
					uid: this.currentEditSubAcc.uid,
					password: i.newPassword,
				})
					.then((response) => {
						this.currentEditSubAcc = null;
						this.getSubAccount();
						this.clickCloseModal();
					})
					.catch((err) => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
			// 白名单
			clickWhitelistAdd() {
				this.modalTitle = this.$t("lineManagement.add_whitelist");
				this.modalCase = 1;
				this.modalShow = true;
			},
			whitelistOperate(index, params) {
				switch (index) {
					default:
						this.modalTitle = this.$t("confirm");
						this.modalCase = 3;
						this.modalShow = true;
						this.actionMsg = this.$t(
							"lineManagement.remove_whitelist"
						).toLowerCase();
						this.currentWhitelist = params;
						break;
				}
			},
			onWhitelistPageChanged(page) {
				this.whitelistPage = page;
				this.getWhitelist();
			},
			// modal
			clickAddWhitelist(whitelist) {
				let white = [];
				// filter repeat
				whitelist.forEach((i) => {
					if (this.rowsWhitelist.findIndex((j) => j.white === i) < 0) {
						white.push(i);
					}
				});
				if (white.length) {
					api.postRouteWhitelist({
						route_list: [this.rows[0].id],
						white: white.join("\n"),
					})
						.then((response) => {
							this.getWhitelist();
							this.clickCloseModal();
						})
						.catch((err) => {
							this.$store.dispatch("setIsLoading", false);
						});
				} else {
					danger(this.$t("lineManagement.cannot_add_duplicate_white"));
					this.$store.dispatch("setIsLoading", false);
				}
			},
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.modalShow = false;
				this.modalTitle = "";
				this.modalCase = "";
				this.actionMsg = "";
				this.currentWhitelist = null;
				this.$store.dispatch("setIsLoading", false);
			},
			clickConfirmAction() {
				switch (this.modalCase) {
					case 3:
						api.delRouteWhitelist({
							route_id: this.rows[0].id,
							id: this.currentWhitelist.id,
						})
							.then(() => {
								this.getWhitelist();
								this.clickCloseModal();
							})
							.catch((err) => {
								this.$store.dispatch("setIsLoading", false);
							});
						break;

					case 4:
						api.patchRouteInfo({
							id: this.rows[0].id,
							state: this.rows[0].state === 1 ? 2 : 1,
							// id label state
						})
							.then(() => {
								success(
									this.$t("action_success", [
										this.$t(
											`lineManagement.${
												this.rows[0].state === 1
													? "pause_line"
													: "start_line"
											}`
										),
									])
								);
								this.getLineDetail();
								this.clickCloseModal();
							})
							.catch((err) => {
								this.clickCloseModal();
							});
						break;

					default:
						this.$store.dispatch("setIsLoading", false);
						break;
				}
			},
			// get current line detail
			getLineDetail() {
				api.getRouteInfo({
					searchParams: JSON.stringify({ id: this.rows[0].id }),
				}).then((response) => {
					const { data } = response;

					let rows = [];
					if (data.list && data.list.length) {
						data.list.forEach((i) => {
							const currentDate = this.$moment();
							const expireDate = this.$moment(i.expire_time * 1000);
							const isExpired = expireDate.diff(currentDate, "days") < 0;
							rows.push({
								...i,
								bandwidthText: i.bandwidth + "M",
								expireTime: expireDate.format("YYYY-MM-DD HH:mm:ss"),
								createdTime: i.created
									? this.$moment(i.created * 1000).format(
											"YYYY-MM-DD HH:mm:ss"
									  )
									: "",
								status: this.$t(
									isExpired
										? "lineManagement.expired"
										: i.state === 1
										? "lineManagement.in_use"
										: i.state === 2
										? "lineManagement.paused"
										: "lineManagement.to_be_config"
								),
								statusClass: isExpired
									? "gray-light"
									: i.state === 1
									? "main-light"
									: i.state === 2
									? "orange-light"
									: "orange-light",
							});
						});
					}
					this.rows = rows;
				});
			},
			// get sub account
			getSubAccount() {
				let searchParams = {
					route_id: this.rows[0].id,
					username: this.searchSubAccount,
				};

				Object.keys(searchParams).forEach((key) => {
					if (!searchParams[key]) {
						delete searchParams[key];
					}
				});

				let params = {
					page: this.subAccountPage,
					limit: this.subAccountLimit,
					tab: 1, // all
					searchParams: Object.keys(searchParams).length
						? JSON.stringify(searchParams)
						: "", // object to string
					// page limit uid export tab searchParams ip label created username
				};
				if (!params.searchParams) {
					delete params.searchParams;
				}
				api.getCompanyUserInfo(params)
					.then((response) => {
						const { data, count } = response;
						this.rowsSubAccount = data.list;
						this.subAccountCount = count;
						this.$store.dispatch("setIsLoading", false);
					})
					.catch((err) => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
			getWhitelist() {
				api.getRouteWhitelist({
					page: this.whitelistPage,
					limit: this.whitelistLimit,
					searchParams: JSON.stringify({
						route_id: this.rows[0].id,
					}),
				})
					.then((res) => {
						this.rowsWhitelist = res.data.list;
						this.whitelistCount = res.count;
					})
					.catch((err) => {});
			},
		},
		created() {
			if (!this.$route.params.data) {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementList,
				});
			} else {
				this.rows.push(this.$route.params.data);
				this.getSubAccount();
				this.getWhitelist();
			}
		},
	};
</script>