<template>
	<div id="alert" class="position-fixed fixed-top" style="z-index: 1051">
		<div class="container">
			<div :class="`alert alert-${alertType} fade show`" role="alert">
				{{ message }}
				<button
					v-if="closable"
					@click="clickClose()"
					type="button"
					class="close"
					aria-label="Close"
				>
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "alert",
		props: {
			alertType: {
				type: String,
				default: "main",
			},
			message: {
				default: "",
			},
			closable: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			clickClose() {
				document.getElementById("alert").innerHTML = "";
			},
		},
	};
</script>
