<template>
	<div class="container-fluid">
		<!-- margin-bottom for footer total price -->
		<div
			class="bg-white p-2 p-sm-4 shadow-sm"
			style="margin-top: 0.5rem; margin-bottom: 100px"
		>
			<stepper :steps="steps" :step="2" />
			<div class="inner-box p-3 p-sm-4 mb-4">
				<div class="row mt-3">
					<div class="col-auto">
						<p class="f16 font-weight-bold" style="min-width: 100px">
							{{ $t("buyLine.pending_order") }}
						</p>
					</div>
					<div class="col mt-sm-4">
						<c-table
							tableClass="th-style2 table-hover-non"
							tableStyle="min-width: 450px"
							:columns="columns"
							:data="placeOrderRows"
						/>
					</div>
				</div>
				<div class="row mt-5 mb-3">
					<div class="col-sm-auto">
						<p class="f16 font-weight-bold" style="min-width: 100px">
							{{ $t("buyLine.payment_method") }}
						</p>
					</div>
					<div class="col mt-sm-4">
						<payment-method
							:list="paymentList"
							keyImg="payment"
							keyText="alias_name"
							:defaultSelect="paymentMethodIndex"
							:onClicked="clickPayment"
						/>
					</div>
				</div>
				<!-- bottom price -->
				<div class="fixed-bottom dashboard-bottom-bar">
					<div
						class="
							d-flex
							justify-content-center justify-content-sm-end
							p-2 p-sm-4
						"
					>
						<div class="d-flex align-items-center">
							<p class="f14 mb-0 mr-1 user-select-none">
								{{ $t("buyLine.current_config_price") }}
							</p>
							<p
								class="f14 mb-0 mr-1 user-select-none color-dark-orange"
							>
								$
							</p>
							<p
								class="
									f24
									mb-0
									mr-3
									user-select-none
									color-dark-orange
									font-weight-bold
								"
							>
								{{ price }}
							</p>
						</div>
						<c-button
							loading
							classes="px-5"
							:text="$t('buyLine.settlement')"
							:clicked="clickSettle"
						/>
					</div>
				</div>
			</div>
		</div>
		<payment-modal
			v-if="modalShow"
			:modalTitle="$t('buyLine.transfer_info')"
			:payment="payment"
			:transferInfo="{ ...transferInfo }"
			:callback="paymentModalCallback"
		/>
	</div>
</template>

<script>
	import { danger } from "@/components/alert/index.js";
	import { api, keys, helper } from "@/utils";
	import { tablePayOrder } from "@/components/table/data";

	export default {
		name: "buyLinePayOrder",
		components: {
			stepper: () => import("@/components/stepper"),
			paymentMethod: () => import("@/components/button/paymentMethod"),
			cTable: () => import("@/components/table"),
			cButton: () => import("@/components/button"),
			paymentModal: () => import("@/components/templates/paymentModal"),
		},
		data() {
			return {
				// stepper
				steps: [
					this.$t("buyLine.choose_purchase_spec"),
					this.$t("buyLine.pay_order"),
					this.$t("buyLine.config_line"),
				],
				// table
				columns: tablePayOrder(),
				// payment type
				paymentMethodIndex: "",
				payment: "",
				// footer price
				price: "0",
				// modal
				modalShow: false,
				transferInfo: null,
			};
		},
		computed: {
			paymentList() {
				return this.$store.getters.paymentList;
			},
			placeOrderRows() {
				return this.$store.getters.placeOrderRows;
			},
			placeOrderInfo() {
				return this.$store.getters.placeOrderInfo;
			},
		},
		methods: {
			getPrice() {
				let price = 0;
				this.placeOrderRows.forEach((i) => {
					price += i.money;
				});
				this.price = price;
			},
			clickPayment(item, idx) {
				this.paymentMethodIndex = idx;
				this.payment = item.payment;
			},
			clickSettle() {
				if (this.payment) {
					let listObject = {};
					keys.placeOrderItems.forEach((key) => {
						if (key === "day") {
							listObject[key] = this.placeOrderInfo.purchaseTime[key];
						} else if (key === "postage_id") {
							listObject[key] = this.placeOrderInfo.bandwidthInfo.id;
						} else if (key === "money") {
							listObject[key] = parseInt(this.placeOrderRows[0].money * 100);
						} else if (key in this.placeOrderInfo) {
							listObject[key] = this.placeOrderInfo[key];
						} else if (key in this.placeOrderInfo.bandwidthInfo) {
							listObject[key] = this.placeOrderInfo.bandwidthInfo[key];
						}
					});

					// post order
					api.postOrderPay({
						type: 0,
						payment: this.payment,
						money: parseInt(this.price * 100),
						list: [listObject],
					})
						.then((response) => {
							const { data } = response;
							if (data.link_url || data.bank_info) {
								if (
									this.payment.indexOf("ALIPAY")>-1 || this.payment.indexOf("UNION")>-1 ||
									this.payment === "OFFLINEPAY"
								) {
									const price = data.total_origin_money;
									this.transferInfo = {
										...data,
										price: helper.roundDecPoint(price / 100),
										totalMoney: helper.roundDecPoint(
											data.total_money / 100
										),
									};
									// force loading
									this.$store.dispatch("setIsLoading", false);
									// show modal
									this.modalShow = true;
								} else {
									this.$store.dispatch("setIsLoading", false);
									window.open(data.link_url, "_self");
								}
							} else {
								// force loading
								this.$store.dispatch("setIsLoading", false);
								danger(this.$t("please_try_again"));
							}
						})
						.catch((err) => {
							this.$store.dispatch("setIsLoading", false);
						});
				} else {
					this.$store.dispatch("setIsLoading", false);
					danger(this.$t("choose_payment_method"));
				}
			},
			// modal
			paymentModalCallback(params = {}) {
				if (params.closeModal) {
					this.modalShow = false;
				} else {
					this.$store.dispatch("setIsLoading", true);

					api.getOrderList({
						page: 1, // int
						limit: 15, // int  defaut:15
						//tab: 2, // to be comfirm
						searchParams: JSON.stringify({
							parent_order_id: this.transferInfo.parent_order_id,
						}),
					})
						.then((response) => {
							this.$store.dispatch("setIsLoading", false);

							if (params.clickCloseModal) {
								params.clickCloseModal();
							}

							const { data } = response;
							let routeName = keys.buyLine + keys.buyLinePayOrder;
							let stepper = 3;
							let state = true;

							if (params.noUpload) {
								routeName += keys.buyLineOrderVNotUploaded;
								stepper = 2;
								state = false;
							} else if (params.isUploadVoucher) {
								routeName += keys.buyLineOrderVUploaded;
							} else if (
								data.list.length &&
								data.list[0].parent_order_id ===
									this.transferInfo.parent_order_id &&
								data.list[0].state === 9
							) {
								routeName += keys.buyLineOrderS;
							} else {
								routeName += keys.buyLineOrderF;
								stepper = 2;
								state = false;
							}

							this.$router.push({
								name: routeName,
								params: {
									stepper,
									state,
									isUploadVoucher: params.isUploadVoucher
										? params.isUploadVoucher
										: false,
									noUpload: params.noUpload,
									orderDetails: data.list[0],
									repayRouteParams: {
										parent_order_id: this.$route.params.parent_order_id,
										paymentIdx: this.paymentMethodIndex,
										...this.transferInfo,
									},
								},
							});
						})
						.catch(() => {
							this.$store.dispatch("setIsLoading", false);
						});
				}
			},
		},
		created() {
			if (!this.placeOrderInfo || !this.placeOrderRows.length) {
				this.$router.push({
					name: keys.buyLine + keys.buyLinePurchaseSpec,
				});
			} else {
				this.getPrice();
				if (this.$route.params && this.$route.params.parent_order_id) {
					const { paymentIdx, ...res } = this.$route.params;
					this.transferInfo = {
						...res,
					};
					this.paymentMethodIndex = paymentIdx;
					this.payment = this.paymentList[paymentIdx].payment;
					this.modalShow = true;
				}
			}
		},
	};
</script>
