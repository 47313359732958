<template>
	<div>
		<button
			v-if="!isLoading"
			type="button"
			:class="
				`btn btn-${color} ${fullWidth ? 'btn-block' : ''} ${
					rounded ? 'btn-rounded-circle' : ''
				} py-2 ` + classes
			"
			:style="styles"
			:disabled="disabled"
			@click="onClick"
		>
			<slot>
				{{ text }}
			</slot>
		</button>
		<button
			v-else
			type="button"
			:class="
				`btn btn-${color} ${fullWidth ? 'btn-block' : ''} ${
					rounded ? 'btn-rounded-circle' : ''
				} py-2 ` + classes
			"
			:style="styles"
			disabled
		>
			<span
				class="spinner-grow spinner-grow-sm"
				role="status"
				aria-hidden="true"
			></span>
			<span class="sr-only">{{ $t("loading") }}</span>
		</button>
	</div>
</template>

<script>
	export default {
		name: "cButton",
		props: {
			fullWidth: {
				type: Boolean,
				default: false,
			},
			rounded: {
				type: Boolean,
				default: true,
			},
			color: {
				type: String,
				default: "main",
			},
			classes: {
				type: String,
				default: "",
			},
			styles: {
				type: String,
				default: "",
			},
			text: {
				type: String,
				default: "",
			},
			loading: {
				type: Boolean,
				default: false,
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			clicked: {
				type: Function,
				default: () => {},
			},
		},
		computed: {
			isLoading() {
				return this.$store.getters.isLoading;
			},
		},
		methods: {
			onClick() {
				if (this.loading) {
					this.$store.dispatch("setIsLoading", true);
				}
				this.clicked();
			},
		},
	};
</script>
