import Vue from "vue";
import AlertTemplate from "@/components/alert/index.vue";

// create component constructor
const AlertConstructor = props => {
  const VueAlert = Vue.extend(AlertTemplate);
  let vm = new VueAlert({
    propsData: { ...props }
  }).$mount("#alert");

  setTimeout(function() {
    vm.$destroy(); // cleanup in component
    vm.$el.innerHTML = ""; // remove inner content
    vm = null; // I drop the reference so it can be GC'd
  }, 3000);
};

export const info = (message, closable = false) => {
  AlertConstructor({
    message,
    closable
  });
};

export const success = (message, closable = false) => {
  AlertConstructor({
    alertType: "success",
    message,
    closable
  });
};

export const warning = (message, closable = false) => {
  AlertConstructor({
    alertType: "warning",
    message,
    closable
  });
};

export const danger = (message, closable = false) => {
  AlertConstructor({
    alertType: "danger",
    message,
    closable
  });
};
