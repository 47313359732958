<template>
	<div>
		<div v-if="state" class="text-center">
			<img
				src="@/assets/imgs/circleBlueTick.png"
				alt="success"
				class="mt-4"
			/>
			<h5 class="font-weight-bold mt-4 mb-3">
				{{ $t("account.success_on_two_fac") }}
			</h5>
			<p class="text-muted mx-auto" style="max-width: 500px">
				{{ $t("account.success_on_two_fac_desc", [phone]) }}
			</p>
			<c-button
				classes="mt-5 mb-4 px-5"
				:text="$t('account.complete')"
				:clicked="clickFinish"
			/>
		</div>
		<div v-else class="text-center">
			<img src="@/assets/imgs/circleError.png" alt="fail" class="mt-4" />
			<h5 class="font-weight-bold mt-4 mb-3">
				{{ $t("account.confirm_disable_two_fac") }}
			</h5>
			<p class="text-muted mx-auto" style="max-width: 500px">
				{{ $t("account.confirm_disable_two_fac_desc") }}
			</p>
			<c-button
				loading
				classes="mt-4 mb-3 px-5"
				:text="$t('account.confirm')"
				:clicked="clickConfirm"
			/>
		</div>
	</div>
</template>

<script>
	export default {
		name: "flashMessageTwoFac",
		components: {
			cButton: () => import("@/components/button"),
		},
		props: {
			state: {
				type: Boolean,
				default: true,
			},
			phone: {
				type: String,
				default: "",
			},
			clickFinish: {
				type: Function,
				default: () => {},
			},
			clickConfirm: {
				type: Function,
				default: () => {},
			},
		},
	};
</script>
