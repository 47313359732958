<template>
	<div>
		<button
			v-if="!value"
			type="button"
			class="btn btn-sm btn-text-main p-1"
			@click="callback(data)"
		>
			+ {{ $t("table.increase_btn") }}
		</button>
		<div v-else class="d-flex justify-content-center">
			{{ value }}
			<button
				type="button"
				class="btn btn-sm btn-text-main py-0 pl-2 pr-0"
				@click="callback(data)"
			>
				<i class="bi bi-pencil-fill"></i>
			</button>
		</div>
	</div>
</template>

<script>
	export default {
		name: "tEditableLabel",
		props: {
			value: {
				type: String,
				default: "",
			},
			data: Object,
			callback: Function,
		},
	};
</script>
