<template>
	<div class="d-flex justify-content-center">
		<div style="width: 300px">
			<h5>
				<small class="font-weight-bold">
					{{ $t("lineManagement.whitelist_link") }}
				</small>
			</h5>
			<div class="overflow-auto pr-2" style="max-height: 250px">
				<template v-for="(i, index) in list">
					<c-input
						:key="index"
						:id="'whitelist' + index"
						:classes="i.err ? '' : 'mb-3'"
						placeholder=""
						:value="i.val"
						inputClasses="border-right-0"
						:onChange="(e) => changeValue(e, index)"
					>
						<template v-slot:append>
							<div class="input-group-append">
								<div class="input-group-text bg-white p-0">
									<button
										class="btn btn-sm no-focus py-0 px-2"
										@click="removeList(index)"
									>
										<img
											src="@/assets/imgs/roundClose.png"
											alt="close"
										/>
									</button>
								</div>
							</div>
						</template>
					</c-input>
					<p
						v-if="i.err"
						:key="'errmsg_' + index"
						class="text-danger mb-3"
					>
						<small>
							{{ i.err }}
						</small>
					</p>
				</template>
			</div>
			<c-button
				fullWidth
				:rounded="false"
				classes="btn-gray mt-3"
				:text="'+ ' + $t('lineManagement.continue_increase')"
				:clicked="clickContinueAdd"
			/>
			<c-button
				fullWidth
				:loading="loading"
				classes="mt-4"
				:text="$t('lineManagement.increase')"
				:clicked="clickIncrease"
			/>
		</div>
	</div>
</template>

<script>
	import { danger } from "@/components/alert/index.js";

	const regexIP =
		/^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
	const regexDomain =
		/^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+$/;

	export default {
		name: "whiteList",
		components: {
			cInput: () => import("@/components/input"),
			cButton: () => import("@/components/button"),
		},
		props: {
			loading: {
				type: Boolean,
				default: false,
			},
			clickAdd: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				list: [{ val: "", err: "" }],
			};
		},
		methods: {
			changeValue(e, index) {
				this.list[index] = { val: e.target.value };
			},
			removeList(index) {
				this.list.splice(index, 1);
			},
			clickContinueAdd() {
				this.list.push({ val: "", err: "" });
			},
			clickIncrease() {
				let whitelist = [];
				let updateErr = [];
				let gotErr = false;
				this.list.forEach((i, idx) => {
					if (i.val && (regexIP.test(i.val) || regexDomain.test(i.val))) {
						updateErr.push({ ...i, err: "" });
						if (regexDomain.test(i)) {
							whitelist.push(i.replace(/(^\w+:|^)\/\//, ""));
						} else {
							whitelist.push(i);
						}
					} else {
						gotErr = true;
						updateErr.push({ ...i, err: this.$t("wrong_format") });
					}
				});

				if (gotErr) {
					this.list = updateErr;
				} else {
					if (whitelist.length) {
						this.clickAdd(whitelist);
					} else {
						danger(this.$t("input_whitelist_link"));
						this.$store.dispatch("setIsLoading", false);
					}
				}
			},
		},
	};
</script>