<template>
	<textarea
		v-if="!title"
		:class="'form-control ' + classes"
		:id="'id_textarea_' + id"
		:placeholder="placeholder"
		:rows="rows"
		:disabled="disabled"
		:value="value"
		@input="$emit('input', $event.target.value)"
		@change="onChange($event)"
	></textarea>
	<div v-else-if="titleAlign === 'top'" class="form-group">
		<label
			:for="'id_textarea_' + id"
			:class="'font-weigit-bold ' + titleClasses"
			:style="titleStyle"
			>{{ title }}</label
		>
		<textarea
			:class="'form-control ' + classes"
			:id="'id_textarea_' + id"
			:placeholder="placeholder"
			:rows="rows"
			:disabled="disabled"
			:value="value"
			@input="$emit('input', $event.target.value)"
			@change="onChange($event)"
		></textarea>
	</div>
	<div v-else-if="titleAlign === 'left'" class="form-group row">
		<label
			:for="'id_input_' + id"
			:class="'col-auto col-form-label font-weigit-bold ' + titleClasses"
			:style="titleStyle"
		>
			{{ title }}
		</label>
		<div class="col-sm-7 p-0">
			<textarea
				:class="'form-control ' + classes"
				:id="'id_textarea_' + id"
				:placeholder="placeholder"
				:rows="rows"
				:disabled="disabled"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="onChange($event)"
			></textarea>
		</div>
	</div>
</template>

<script>
	export default {
		name: "cTextarea",
		props: {
			titleAlign: {
				type: String,
				default: "left",
			},
			titleClasses: {
				type: String,
				default: "",
			},
			titleStyle: {
				type: String,
				default: "",
			},
			id: {
				type: String,
				default: "",
			},
			classes: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: "",
			},
			rows: {
				type: String,
				default: "3",
			},
			value: {
				type: String,
				default: "",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			onChange: {
				type: Function,
				default: () => {},
			},
			onEnter: {
				type: Function,
				default: null,
			},
		},
		mounted() {
			if (this.onEnter) {
				const _this = this;
				const input = document.getElementById("id_textarea_" + this.id);
				input.addEventListener("keyup", function (e) {
					var key = e.key || e.keyCode;
					if (key === "Enter" || key === 13) {
						_this.onEnter();
					}
				});
			}
		},
	};
</script>