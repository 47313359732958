import Vue from "vue";
Vue.use(require("vue-moment"));

import {
  SET_LINEMODE,
  SET_LOADING,
  SET_NOTIFICATIONS,
  FETCH_POSTAGE
} from "@/store/mutations-type";
import { localS, keys, api } from "@/utils";

const defaultState = {
  lineMode: 2,
  isLoading: false,
  notifications: [],
  notifyBadge: null,
  postages: null
};

const sys = {
  state: { ...localS.getJSON(keys.stateSys, defaultState) },
  getters: {
    isLoading: state => state.isLoading,
    lineMode: state => state.lineMode,
    notifications: state => state.notifications,
    notifyBadge: state => state.notifyBadge,
    postages: state => state.postages
  },
  mutations: {
    [SET_LINEMODE](state, payload) {
      state.lineMode = payload;

      localS.setJSON(keys.stateSys, state);
    },
    [SET_LOADING](state, payload) {
      state.isLoading = payload;

      localS.setJSON(keys.stateSys, state);
    },
    [SET_NOTIFICATIONS](state, payload) {
      state.notifications = payload.list;
      state.notifyBadge = payload.badge;

      localS.setJSON(keys.stateSys, state);
    },
    [FETCH_POSTAGE](state, payload) {
      state.postages = payload.postages;

      localS.setJSON(keys.stateSys, state);
    }
  },
  actions: {
    setLineMode({ commit }, payload) {
      commit(SET_LINEMODE, payload);
    },
    setIsLoading({ commit }, payload) {
      commit(SET_LOADING, payload);
    },
    fetchNotification({ commit, state }) {
      api.getBoardcast().then(response => {
        const { data } = response;
        if (data.list && data.list.length) {
          let newNotifications = [];
          let notificationBadge = 0;
          data.list.forEach(i => {
            if (
              !state.notifications.length ||
              (state.notifications.length &&
                state.notifications.filter(j => i.created === i.created)
                  .length < 1)
            ) {
              newNotifications.push({
                ...i,
                notification: i.content,
                link: "",
                date: Vue.moment(i.created * 1000).format("YYYY/MM/DD"),
                seen: false
              });
              // badge
              notificationBadge++;
            }
          });

          if (state.notifications.length) {
            state.notifications.forEach(i => {
              newNotifications.unshift(i);
              // badge
              if (!i.seen) {
                notificationBadge++;
              }
            });
          }

          commit(SET_NOTIFICATIONS, {
            list: newNotifications,
            badge: notificationBadge
          });
        }
      });
    },
    notificationSeen({ commit, state }) {
      let newNotifications = [];
      state.notifications.forEach(i => {
        newNotifications.push({ ...i, seen: true });
      });
      commit(SET_NOTIFICATIONS, { list: newNotifications, badge: 0 });
    },
    fetchPostage({ commit }) {
      return new Promise((resolve, reject) => {
        api
          .getPostage()
          .then(response => {
            const { data } = response;
            commit(FETCH_POSTAGE, { postages: data.list });
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    }
  }
};

export default sys;
