<template>
	<li class="nav-item position-relative" @click="clicked">
		<a
			:class="'nav-link text-nowrap user-select-none px-5' + (isActive ? ' active' : '')"
			data-toggle="tab"
			:href="`#${id}`"
			>{{ title }}</a
		>
		<!-- blue line -->
		<div
			v-if="isActive"
			class="bg-main position-absolute"
			style="width: 100%; height: 5px; top: 0"
		></div>
	</li>
</template>

<script>
	export default {
		name: "tabs",
		props: {
			id: {
				type: String,
				default: "tabs1",
			},
			title: {
				type: String,
				default: "Tabs",
			},
			active: {
				type: Boolean,
				default: false,
			},
			clicked: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				isActive: this.active,
			};
		},
		mounted() {
			const _this = this;
			$(`a[href="#${this.id}"]`).on("hide.bs.tab", function (e) {
				_this.isActive = false;
			});
			$(`a[href="#${this.id}"]`).on("show.bs.tab", function (e) {
				_this.isActive = true;
			});
		},
	};
</script>
