<template>
	<div class="container">
		<div class="bg-white pt-4 pb-5 px-4 mb-5">
			<div class="text-center mt-4">
				<img src="@/assets/imgs/circleBlueLock.png" alt="lock" />
				<h5 class="font-weight-bold mt-2 mb-3">
					{{ $t("resetPassword.title") }}
				</h5>
			</div>
			<div class="row justify-content-center">
				<div class="col-sm-9 col-md-6">
					<!-- input username -->
					<c-input
						id="username"
						titleAlign="top"
						:title="$t('resetPassword.email')"
						:placeholder="$t('resetPassword.input_email')"
						v-model="username"
					>
						<template v-slot:prepend>
							<div class="input-group-prepend">
								<div class="input-group-text">
									<img src="@/assets/imgs/mailLight.png" />
								</div>
							</div>
						</template>
					</c-input>
					<!-- input password -->
					<c-input
						class="mt-3"
						:type="inputPwsdType"
						id="password"
						titleAlign="top"
						:title="$t('resetPassword.password')"
						:placeholder="$t('resetPassword.input_password')"
						v-model="password"
					>
						<template v-slot:prepend>
							<div class="input-group-prepend">
								<div class="input-group-text">
									<img src="@/assets/imgs/lock.png" />
								</div>
							</div>
						</template>
						<template v-slot:append>
							<div class="input-group-append">
								<span
									class="input-group-text bg-transparent"
									@click="togglePassword()"
								>
									<img
										src="@/assets/imgs/eyeOpen.png"
										alt="show/hide"
										class="imgTogglePassword"
									/>
								</span>
							</div>
						</template>
					</c-input>
					<!-- input confirm password -->
					<c-input
						class="mt-3"
						:type="inputPwsdType"
						id="confirm_password"
						titleAlign="top"
						:title="$t('resetPassword.confirm_password')"
						:placeholder="$t('resetPassword.input_confirm_password')"
						v-model="confPassword"
					>
						<template v-slot:prepend>
							<div class="input-group-prepend">
								<div class="input-group-text">
									<img src="@/assets/imgs/lock.png" />
								</div>
							</div>
						</template>
						<template v-slot:append>
							<div class="input-group-append">
								<span
									class="input-group-text bg-transparent"
									@click="togglePassword()"
								>
									<img
										src="@/assets/imgs/eyeOpen.png"
										alt="show/hide"
										class="imgTogglePassword"
									/>
								</span>
							</div>
						</template>
					</c-input>
				</div>
			</div>
			<!-- btn next -->
			<div class="row justify-content-center mt-5 mb-3">
				<div class="col-sm-7 col-md-4">
					<c-button
						fullWidth
						:text="$t('resetPassword.reset_n_login')"
						:disabled="!username || !password || !confPassword"
						:clicked="clickReset"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { danger } from "@/components/alert/index.js";

	export default {
		name: "forgetPassword",
		components: {
			cButton: () => import("@/components/button"),
			cInput: () => import("@/components/input"),
		},
		data() {
			return {
				username: "",
				password: "",
				confPassword: "",
				inputPwsdType: "password",
			};
		},
		methods: {
			clickReset() {
				if (this.password.length < 6 || this.confPassword.length < 6) {
					danger(this.$t("password_not_match"));
					return;
				}
				if (this.password !== this.confPassword) {
					danger(this.$t("password_length"));
					return;
				}
				// this.$store.dispatch("setIsLoading", true);
				// call api reset
			},
			togglePassword() {
				this.inputPwsdType =
					this.inputPwsdType == "text" ? "password" : "text";
				$(".imgTogglePassword").attr(
					"src",
					this.inputPwsdType == "text"
						? require("@/assets/imgs/eyeClose.png")
						: require("@/assets/imgs/eyeOpen.png")
				);
			},
		},
	};
</script>
