<template>
	<div class="container-fluid">
		<!-- margin-bottom for footer total price -->
		<div
			class="bg-white p-4 shadow-sm"
			style="margin-top: 0.5rem; margin-bottom: 100px"
		>
			<div class="form-group row mt-4">
				<label
					class="col-auto col-form-label f14 font-weight-bold"
					style="width: 120px"
				>
					{{ $t("lineManagement.select_duration") }}
				</label>
				<div class="col-sm-10">
					<button-duration
						:list="rateList"
						:defaultVal="purchaseTimeIndex"
						:onClicked="clickPurchaseTime"
					/>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-auto" style="width: 120px">
					<p class="f14 font-weight-bold">
						{{ $t("lineManagement.pending_order") }}
					</p>
				</div>
				<div class="col-sm-10">
					<c-table
						tableClass="th-style2 table-hover-non"
						tableStyle="min-width: 615px"
						:columns="columns"
						:data="rows"
					/>
				</div>
			</div>
			<div class="row mt-4 mb-3">
				<div class="col-auto" style="width: 120px">
					<p class="f14 font-weight-bold">
						{{ $t("lineManagement.payment_method") }}
					</p>
				</div>
				<div class="col-sm-10">
					<payment-method
						:list="paymentList"
						keyImg="payment"
						keyText="alias_name"
						:defaultSelect="paymentMethodIndex"
						:onClicked="clickPayment"
					/>
				</div>
			</div>
			<!-- bottom price -->
			<div class="fixed-bottom dashboard-bottom-bar">
				<div
					class="
						d-flex
						justify-content-center justify-content-sm-end
						p-2 p-sm-4
					"
				>
					<div class="d-flex align-items-center">
						<p class="f14 mb-0 mr-1 user-select-none">
							{{ $t("lineManagement.current_config_price") }}
						</p>
						<p class="f14 mb-0 mr-1 user-select-none color-dark-orange">
							$
						</p>
						<p
							class="
								f24
								mb-0
								mr-3
								user-select-none
								color-dark-orange
								font-weight-bold
							"
						>
							{{ price }}
						</p>
					</div>
					<c-button
						loading
						classes="px-4 px-sm-5"
						:text="$t('lineManagement.settlement')"
						:disabled="!purchaseTime || !payment"
						:clicked="clickSettle"
					/>
				</div>
			</div>
		</div>
		<payment-modal
			v-if="modalShow"
			:modalTitle="$t('buyLine.transfer_info')"
			:payment="payment"
			:transferInfo="{ ...transferInfo }"
			:callback="paymentModalCallback"
		/>
	</div>
</template>

<script>
	import { keys, api, helper } from "@/utils";
	import { columnRenewLine } from "@/components/table/data";
	import { danger } from "@/components/alert/index.js";

	export default {
		name: "lineManagementRenew",
		components: {
			buttonDuration: () => import("@/components/button/duration"),
			cTable: () => import("@/components/table"),
			paymentMethod: () => import("@/components/button/paymentMethod"),
			cButton: () => import("@/components/button"),
			paymentModal: () => import("@/components/templates/paymentModal"),
		},
		data() {
			return {
				purchaseTimeIndex: 4,
				purchaseTime: null,
				columns: columnRenewLine(),
				rows: null,
				paymentMethodIndex: "",
				payment: "",
				price: 0,
				// modal
				modalShow: false,
				transferInfo: null,
			};
		},
		computed: {
			paymentList() {
				return this.$store.getters.paymentList || [];
			},
			rateList() {
				return this.$store.getters.rateList;
			},
			renewList() {
				return this.$store.getters.buyList;
			},
			deviceUnitPrice() {
				return this.$store.getters.deviceUnitPrice;
			},
		},
		methods: {
			// 计算价格
			calcPrice() {
				const { renewList, purchaseTime } = this;
				if (renewList.length && purchaseTime) {
					// price
					let itemPrice = 0;
					let discountPrice = 0;
					let devices = 0;
					let price = 0;
					let total = 0;
					// list
					let rows = [];
					// loop
					renewList.forEach((i) => {
						// format = (price + ((numOfDevice - 1) * deviceUnitPrice)*months * discount
						// num of device
						devices = i.device_num - 1;
						// (price * months)
						itemPrice = i.money;
						itemPrice += devices * this.deviceUnitPrice;
						itemPrice *= purchaseTime.month;
						// discount
						discountPrice = purchaseTime.rate / 100;
						itemPrice *= discountPrice;
						// total price (format 0.00)

						price = helper.roundDecPoint(itemPrice / 100);

						// footer total amount
						total += itemPrice;

						// list
						rows.push({
							...i,
							bandwidthText: i.bandwidth + "M",
							monthText: this.purchaseTime.title,
							amount: price,
							amountText: "$ " + price,
						});
					});

					this.price = helper.roundDecPoint(total/100);

					this.rows = rows;
				} else {
					this.rows = [];
				}
			},
			clickPurchaseTime(item, idx) {
				this.purchaseTimeIndex = idx;
				this.purchaseTime = item;
				this.calcPrice();
			},
			clickPayment(item, idx) {
				this.paymentMethodIndex = idx;
				this.payment = item.payment;
			},
			clickSettle() {
				if (!this.purchaseTime) {
					danger(this.$t("lineManagement.select_renew_duration"));
					this.$store.dispatch("setIsLoading", false);
					return;
				}
				if (!this.payment) {
					danger(this.$t("lineManagement.select_payment_method"));
					this.$store.dispatch("setIsLoading", false);
					return;
				}

				let list = [];
				this.rows.forEach((i) => {
					list.push({
						id: i.id,
						// flag_name: i.flag_name,
						number: i.number,
						// bandwidth: 20,
						// device_num: 2,
						day: this.purchaseTime.day,
						postage_id: i.postage_id,
						money: parseInt(i.amount * 100), // for api
					});
				});

				// post order
				api.postOrderPay({
					type: 1,
					payment: this.payment,
					money: parseInt(this.price * 100), // for api
					list,
				})
					.then((response) => {
						const { data } = response;
						if (data.link_url || data.bank_info) {
							if (
								this.payment === "ALIPAY" ||
								this.payment === "OFFLINEPAY"
							) {
								const price = data.total_origin_money;
								this.transferInfo = {
									...data,
									price: helper.roundDecPoint(price / 100),
									totalMoney: helper.roundDecPoint(
										data.total_money / 100
									),
								};
								// force loading
								this.$store.dispatch("setIsLoading", false);
								// show modal
								this.modalShow = true;
							} else {
								this.$store.dispatch("setIsLoading", false);
								window.open(data.link_url, "_self");
							}
						} else {
							// force loading
							this.$store.dispatch("setIsLoading", false);
							danger(this.$t("please_try_again"));
						}
					})
					.catch((err) => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
			// modal
			paymentModalCallback(params = {}) {
				if (params.closeModal) {
					this.modalShow = false;
				} else {
					this.$store.dispatch("setIsLoading", true);

					// check payment is success
					api.getOrderList({
						page: 1, // int
						limit: 15, // int  defaut:15
						// tab: 2, // to be comfirm
						searchParams: JSON.stringify({
							parent_order_id: this.transferInfo.parent_order_id,
						}),
					})
						.then((response) => {
							this.$store.dispatch("setIsLoading", false);

							if (params.clickCloseModal) {
								params.clickCloseModal();
							}

							const { data } = response;
							let routeName =
								keys.lineManagement + keys.lineManagementRenew;
							let state = false;

							if (params.noUpload) {
								routeName += keys.lineManagementPaymentVNotUploaded;
								state = false;
							} else if (params.isUploadVoucher) {
								routeName += keys.lineManagementPaymentVUploaded;
								state = true;
							} else if (
								data.list.length &&
								data.list[0].parent_order_id ===
									this.transferInfo.parent_order_id &&
								data.list[0].state === 9
							) {
								routeName += keys.lineManagementPaymentS;
								state = true;
							} else {
								routeName += keys.lineManagementPaymentF;
								state = false;
							}

							// to status page
							this.$router.push({
								name: routeName,
								params: {
									state,
									isUploadVoucher: params.isUploadVoucher
										? params.isUploadVoucher
										: false,
									noUpload: params.noUpload,
									orderDetails: data.list[0],
									repayRouteName:
										keys.lineManagement + keys.lineManagementRenew,
									repayRouteParams: {
										id: this.$route.params.id,
										purchaseTimeIndex: this.purchaseTimeIndex,
										paymentIdx: this.paymentMethodIndex,
										...this.transferInfo,
									},
								},
							});
						})
						.catch(() => {
							this.$store.dispatch("setIsLoading", false);
							danger(this.$t("please_try_again"));
						});
				}
			},
		},
		created() {
			if (!this.$route.params.id) {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementList,
				});
			} else {
				const { id, purchaseTimeIndex, paymentIdx, ...res } =
					this.$route.params;

				if (paymentIdx !== undefined) {
					this.purchaseTimeIndex = purchaseTimeIndex + 1;
					this.paymentMethodIndex = paymentIdx;
					this.transferInfo = { ...res };
				}

				// get renew info
				this.$store.dispatch("fetchBuyInfo", { id, type: 1 }).then(() => {
					// set default select
					if (paymentIdx !== undefined) {
						this.purchaseTime = this.rateList[purchaseTimeIndex];
						this.payment = this.paymentList[paymentIdx].payment;
						this.modalShow = true;
					} else {
						this.purchaseTimeIndex =
							this.rateList.length > 1 ? this.rateList.length - 1 : 0;
						this.purchaseTime =
							this.rateList.length > 1
								? this.rateList[this.rateList.length - 1]
								: this.rateList[0];
					}
					this.calcPrice();
				});
			}
		},
	};
</script>