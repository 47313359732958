<template>
	<div class="row justify-content-center p-3 p-sm-0">
		<div class="col-12">
			<c-input
				id="email"
				titleStyles="width: 115px"
				classes="justify-content-left justify-content-sm-center"
				:title="$t('feedback.receive_mailbox')"
				:placeholder="$t('feedback.inut_receive_mailbox')"
				v-model="email"
			/>
			<c-textarea
				class="justify-content-left justify-content-sm-center"
				titleStyle="width: 115px"
				:title="$t('feedback.description')"
				rows="5"
				v-model="desc"
			/>
			<c-input
				id="image"
				type="file"
				titleStyles="width: 115px"
				classes="justify-content-left justify-content-sm-center"
				:title="$t('feedback.upload_image')"
				:rendered="inputImgRendered"
			/>
			<div class="d-flex justify-content-center mt-5 mt-sm-4">
				<c-button
					loading
					classes="px-5"
					:text="$t('feedback.submit_feedback')"
					:disabled="!email || !desc"
					:clicked="submit"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	import { api } from "@/utils";
	import { success } from "@/components/alert/index.js";

	export default {
		name: "feedback",
		components: {
			cInput: () => import("@/components/input"),
			cTextarea: () => import("@/components/textarea"),
			cButton: () => import("@/components/button"),
		},
		props: {
			closeModal: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				email: "",
				desc: "",
			};
		},
		methods: {
			inputImgRendered() {
				document.getElementById("id_input_image").accept =
					"image/png, image/jpeg, image/jpg";
			},
			submit() {
				const files = document.getElementById("id_input_image").files;
				if (files.length) {
					this.uploadImg(files[0]);
				} else {
					this.submitFeedback();
				}
			},
			uploadImg(file) {
				const _this = this;
				api.postUploadImg({ file })
					.then((response) => {
						const { data } = response;
						_this.submitFeedback(data.file_url || "");
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			submitFeedback(image_url = "") {
				const _this = this;
				api.postFeedback({
					email: this.email,
					content: this.desc,
					image_url,
				})
					.then((response) => {
						_this.closeModal();
						success(this.$t("submit_success"));
						_this.$store.dispatch("setIsLoading", false);
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
		},
	};
</script>
