<template>
	<div class="container-fluid">
		<!-- margin-bottom for footer total price -->
		<div
			class="bg-white p-2 p-sm-4 shadow-sm"
			style="margin-top: 0.5rem; margin-bottom: 100px"
		>
			<stepper :steps="steps" :step="1" />
			<div class="inner-box p-3 p-sm-4 mb-4">
				<div class="row justify-content-center">
					<div class="col-auto">
						<!-- area -->
						<div class="form-group row mt-0 mt-md-4">
							<label
								class="col-auto col-form-label"
								style="width: 100px"
							>
								{{ $t("buyLine.select_line") }}
							</label>
							<div class="col-sm-4 col-md-3 pl-sm-0 mb-3">
								<c-select
									rounded
									:optionAll="false"
									:placeholder="$t('buyLine.input_select_line')"
									:list="areaList"
									optionKey="area_name"
									v-model="area"
									:changed="changeArea"
								/>
							</div>
							<div v-if="area && areaList[area].desc" class="col-auto">
								<div
									class="alert alert-orange rounded-0 user-select-none"
									style="max-width: 350px; line-height: 1.1"
								>
									<small>
										{{ areaList[area].desc }}
									</small>
								</div>
							</div>
						</div>
						<!-- bandwidth -->
						<div class="form-group row mt-0 mt-md-4">
							<label
								class="col-auto col-form-label"
								style="width: 100px"
							>
								{{ $t("buyLine.select_bandwidth") }}
							</label>
							<div class="col-sm-4 col-md-3 pl-sm-0">
								<c-select
									rounded
									:optionAll="false"
									:placeholder="$t('buyLine.input_select_bandwidth')"
									:list="bandwidthList"
									optionKey="bandwidth"
									optionEnd="M"
									v-model="bandwidth"
									:changed="changeBandwidth"
									:disabled="!area"
								/>
							</div>
						</div>
						<!-- num -->
						<div class="form-group row mt-0 mt-md-4">
							<label
								class="col-auto col-form-label"
								style="width: 100px"
							>
								{{ $t("buyLine.num_of_device") }}
							</label>
							<div class="col-sm-4 col-md-3 pl-sm-0">
								<input-counter
									rounded
									:count="numOfDevice"
									:max="
										bandwidthList && bandwidth
											? bandwidthList[bandwidth].max_device_num
											: 0
									"
									:onNumberChange="changeNumOfDevice"
									:disabled="!bandwidth"
								/>
							</div>
							<div class="col-auto">
								<div
									class="alert alert-orange rounded-0 user-select-none"
									style="max-width: 350px; line-height: 1.1"
								>
									<small>
										{{ $t("buyLine.device_desc") }}
									</small>
								</div>
							</div>
						</div>
						<!-- time -->
						<div class="form-group row mt-0 mt-md-4">
							<label
								class="col-auto col-form-label"
								style="width: 100px"
							>
								{{ $t("buyLine.purchase_time") }}
							</label>
							<div class="col-sm-10 pl-sm-0">
								<button-duration
									rounded
									:list="rateList"
									:defaultVal="purchaseTimeIndex"
									:onClicked="clickPurchaseTime"
								/>
							</div>
						</div>
						<!-- count -->
						<div class="form-group row">
							<label
								class="col-auto col-form-label"
								style="width: 100px"
							>
								{{ $t("buyLine.quantity") }}
							</label>
							<div class="col-sm-4 col-md-3 pl-sm-0">
								<input-counter
									rounded
									:onNumberChange="changePurchaseQty"
								/>
							</div>
						</div>
					</div>
				</div>
				<!-- bottom price -->
				<div class="fixed-bottom dashboard-bottom-bar">
					<div
						class="
							d-flex
							justify-content-center justify-content-sm-end
							p-2 p-sm-4
						"
					>
						<div class="d-flex align-items-center">
							<p class="f14 mb-0 mr-1 user-select-none">
								{{ $t("buyLine.current_config_price") }}
							</p>
							<p
								class="f14 mb-0 mr-1 user-select-none color-dark-orange"
							>
								$
							</p>
							<p
								class="
									f24
									mb-0
									mr-3
									user-select-none
									color-dark-orange
									font-weight-bold
								"
							>
								{{ price }}
							</p>
						</div>
						<c-button
							loading
							classes="px-4 px-sm-5"
							:text="$t('buyLine.settlement')"
							:clicked="clickSettle"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { danger } from "@/components/alert/index.js";
	import { keys, helper } from "@/utils";

	export default {
		name: "buyLinePurchaseSpec",
		components: {
			stepper: () => import("@/components/stepper"),
			cSelect: () => import("@/components/select"),
			buttonDuration: () => import("@/components/button/duration"),
			inputCounter: () => import("@/components/input/counter"),
			cButton: () => import("@/components/button"),
		},
		data() {
			return {
				// stepper
				steps: [
					this.$t("buyLine.choose_purchase_spec"),
					this.$t("buyLine.pay_order"),
					this.$t("buyLine.config_line"),
				],
				// list
				areaList: null,
				bandwidthList: null,
				// purchase spec
				area: "",
				bandwidth: "",
				numOfDevice: 1,
				purchaseTime: null,
				purchaseTimeIndex: 4,
				purchaseQty: 1,
				// footer calculate price
				price: "0",
			};
		},
		computed: {
			postageList() {
				return this.$store.getters.postageList;
			},
			rateList() {
				return this.$store.getters.rateList;
			},
			deviceUnitPrice() {
				return this.$store.getters.deviceUnitPrice;
			},
		},
		methods: {
			changeArea(item) {
				// reselect
				this.bandwidth = "";
				this.price = "0";
				// rerender bandwidth options list
				this.bandwidthList = this.postageList.filter(
					(i) =>
						i.flag_name === item.flag_name && i.area_name === item.area_name
				);
			},
			changeBandwidth(item) {
				// check num of device
				if (this.numOfDevice > item.max_device_num) {
					this.numOfDevice = item.max_device_num;
				}
				this.calcPrice();
			},
			changeNumOfDevice(number) {
				this.numOfDevice = number;
				this.calcPrice();
			},
			clickPurchaseTime(item) {
				this.purchaseTime = item;
				this.calcPrice();
			},
			changePurchaseQty(number) {
				this.purchaseQty = number;
				this.calcPrice();
			},
			// 计算价格
			calcPrice() {
				const {
					bandwidthList,
					bandwidth,
					numOfDevice,
					purchaseQty,
					purchaseTime,
				} = this;
				if (bandwidth !== "" && purchaseTime) {
					// format = (price + ((numOfDevice - 1) * deviceUnitPrice)*months * discount
					// num of device
					const devices = numOfDevice - 1;
					// (price * months)
					let purchasePrice = bandwidthList[bandwidth].money;
					purchasePrice += devices * this.deviceUnitPrice;
					purchasePrice *= purchaseTime.month;
					// discount
					const discount = purchaseTime.rate / 100;
					purchasePrice *= discount;
					// total price
					const price = purchasePrice * purchaseQty;

					// this.deviceUnitPrice
					this.price = helper.roundDecPoint(price / 100);
				}
			},
			// 结算
			clickSettle() {
				if (!this.area) {
					danger(this.$t("buyLine.input_select_line"));
				} else if (!this.bandwidth) {
					danger(this.$t("buyLine.input_select_bandwidth"));
				} else if (!this.purchaseTime) {
					danger(this.$t("buyLine.input_purchase_time"));
				} else {
					// save purchase data and to pay order page
					this.$store
						.dispatch("fetchPlaceOrderList", {
							type: 0,
							bandwidthInfo: this.bandwidthList[this.bandwidth],
							number: this.purchaseQty,
							device_num: this.numOfDevice,
							purchaseTime: this.purchaseTime,
						})
						.then(() => {
							this.$router.push({
								name: keys.buyLine + keys.buyLinePayOrder,
							});
						});
				}
				this.$store.dispatch("setIsLoading", false);
			},
		},
		beforeCreate() {
			// get buy info
			this.$store
				.dispatch("fetchBuyInfo", { type: 0 })
				.then(() => {
					// set default select
					this.purchaseTimeIndex =
						this.rateList.length > 1 ? this.rateList.length - 1 : 0;
					this.purchaseTime =
						this.rateList.length > 1
							? this.rateList[this.rateList.length - 1]
							: this.rateList[0];

					// create area list
					let areaList = [];
					this.postageList.forEach((i) => {
						if (
							areaList.filter((j) => j.area_name === i.area_name).length <=
							0
						) {
							areaList.push({
								area_name: i.area_name,
								flag_name: i.flag_name,
								desc: i.desc,
							});
						}
					});
					this.areaList = areaList;
					// set empty
					this.bandwidthList = [];
				})
				.catch((err) => {});
		},
	};
</script>
