<template>
	<div class="tab-content">
		<slot />
	</div>
</template>

<script>
	export default {
		name: "tabContent",
	};
</script>
