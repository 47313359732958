<template>
	<button
		type="button"
		class="btn btn-sm rounded-circle no-focus p-0 ml-1"
		style="line-height: 0.5; width: unset"
		data-container="body"
		data-toggle="popover"
		data-trigger="focus"
		data-placement="right"
		:title="title"
		:data-content="content"
	>
		<!-- popover-body -->
		<img src="@/assets/imgs/question.png" alt="question" />
	</button>
</template>

<script>
	export default {
		name: "tPopover",
		props: {
			column: Object,
		},
		computed: {
			title() {
				return this.column.popover.title || "";
			},
			content() {
				return this.column.popover.content || "";
			},
			customClass() {
				return this.column.popover.customClass || "body-color-orange";
			},
		},
		mounted() {
			$('[data-toggle="popover"]').popover({
				customClass: this.customClass,
			});
		},
	};
</script>
