<template>
	<div>
		<tab-bar>
			<tabs
				active
				id="using"
				:title="$t('lineManagement.in_use')"
				:clicked="() => changeTab(1)"
			/>
			<tabs
				id="config"
				:title="$t('lineManagement.to_be_config')"
				:clicked="() => changeTab(2)"
			/>
			<tabs
				id="expired"
				:title="$t('lineManagement.expired')"
				:clicked="() => changeTab(3)"
			/>
		</tab-bar>
		<tab-content class="line-filterbar">
			<tab-pane active id="using">
				<div class="row">
					<div class="col-3">
						<c-input
							id="ipaddress"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.ip_address')"
							:placeholder="$t('lineManagement.input_ip_address')"
							v-model="searchIp"
						/>
					</div>
					<div class="col-3">
						<c-select
							rounded
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.line_name')"
							:placeholder="$t('lineManagement.select_line_name')"
							:list="areaList"
							optionKey="area_name"
							v-model="searchArea"
						/>
					</div>
					<div class="col-auto">
						<date-picker
							style="margin-bottom: 0px"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.expiry_time')"
							:onChange="searchExpDateChange"
						/>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-3">
						<c-input
							id="routelabel"
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.label')"
							:placeholder="$t('lineManagement.input_label')"
							v-model="searchLabel"
						/>
					</div>
					<div class="col-3">
						<c-select
							rounded
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.bandwidth')"
							:placeholder="$t('lineManagement.select_bandwidth')"
							:list="bandwidthList"
							optionKey="bandwidthText"
							v-model="searchBandwidth"
						/>
					</div>
					<div class="col-auto">
						<date-picker
							style="margin-bottom: 0px"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.creation_time')"
							:onChange="searchCreatDateChange"
						/>
					</div>
					<div class="col">
						<c-button
							loading
							classes="float-right px-4"
							styles="min-width: 105px"
							:clicked="clickSearch"
						>
							<img
								src="@/assets/imgs/search.png"
								alt="search"
								class="pr-1"
								style="margin-top: -3px"
							/>
							{{ $t("lineManagement.search") }}
						</c-button>
					</div>
				</div>
			</tab-pane>
			<!-- 待配置 -->
			<tab-pane id="config">
				<div class="row">
					<div class="col-4">
						<c-input
							id="orderNum"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.order_number')"
							:placeholder="$t('lineManagement.input_order_number')"
							v-model="searchOrderIds"
						/>
					</div>
					<div class="col-4">
						<c-select
							rounded
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.line_name')"
							:placeholder="$t('lineManagement.select_line_name')"
							:list="areaList"
							optionKey="area_name"
							v-model="searchArea"
						/>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-4">
						<c-select
							rounded
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.bandwidth')"
							:placeholder="$t('lineManagement.input_bandwidth')"
							:list="bandwidthList"
							optionKey="bandwidthText"
							v-model="searchBandwidth"
						/>
					</div>
					<div class="col-6">
						<date-picker
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.expiry_time')"
							:onChange="searchExpDateChange"
						/>
					</div>
					<div class="col">
						<c-button
							loading
							classes="float-right px-4"
							:clicked="clickSearch"
						>
							<img
								src="@/assets/imgs/search.png"
								alt="search"
								class="pr-1"
								style="margin-top: -3px"
							/>
							{{ $t("lineManagement.search") }}
						</c-button>
					</div>
				</div>
			</tab-pane>
			<!-- 已过期 -->
			<tab-pane id="expired">
				<div class="row">
					<div class="col-3">
						<c-input
							id="ipaddress2"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.ip_address')"
							:placeholder="$t('lineManagement.input_ip_address')"
							v-model="searchIp"
						/>
					</div>
					<div class="col-3">
						<c-select
							rounded
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.line_name')"
							:placeholder="$t('lineManagement.select_line_name')"
							:list="areaList"
							optionKey="area_name"
							v-model="searchArea"
						/>
					</div>
					<div class="col-6">
						<date-picker
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.expiry_time')"
							:onChange="searchExpDateChange"
						/>
					</div>
				</div>
				<div class="row align-items-center">
					<div class="col-3">
						<c-input
							id="routelabel2"
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.label')"
							:placeholder="$t('lineManagement.input_label')"
							v-model="searchLabel"
						/>
					</div>
					<div class="col-3">
						<c-select
							rounded
							class="mb-0"
							titleClasses="filter-input text-right pr-0"
							:title="$t('lineManagement.bandwidth')"
							:placeholder="$t('lineManagement.input_bandwidth')"
							:list="bandwidthList"
							optionKey="bandwidthText"
							v-model="searchBandwidth"
						/>
					</div>
					<div class="col-6">
						<c-button
							loading
							classes="float-right px-4"
							style="max-width: 150px"
							:clicked="clickSearch"
						>
							<img
								src="@/assets/imgs/search.png"
								alt="search"
								class="pr-1"
								style="margin-top: -3px"
							/>
							{{ $t("lineManagement.search") }}
						</c-button>
					</div>
				</div>
			</tab-pane>
		</tab-content>
	</div>
</template>

<script>
	import { tabBar, tabs, tabContent, tabPane } from "@/components/tab";

	export default {
		name: "filterTab",
		components: {
			tabBar,
			tabs,
			tabContent,
			tabPane,
			cSelect: () => import("@/components/select"),
			datePicker: () => import("@/components/datePicker"),
			cButton: () => import("@/components/button"),
			cInput: () => import("@/components/input"),
		},
		props: {
			onClickSearch: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				// list
				areaList: null,
				bandwidthList: null,
				searchTab: 1,
				// 使用中
				searchIp: "",
				searchArea: "",
				searchExpireDate: "",
				searchCreateDate: "",
				searchLabel: "",
				searchBandwidth: "",
				// 待配置
				searchOrderIds: "",
				// 已过期
			};
		},
		computed: {
			postages() {
				return this.$store.getters.postages;
			},
			lineList() {
				return this.$store.getters.lineList;
			},
		},
		watch: {
			lineList: function (newArr) {
				if (newArr && newArr.length && this.searchBandwidth === "") {
					let bandwidthList = [];

					newArr.forEach((i) => {
						if (
							bandwidthList.filter((j) => j.bandwidth === i.bandwidth)
								.length <= 0
						) {
							bandwidthList.push({
								bandwidth: i.bandwidth,
								bandwidthText: i.bandwidthText,
							});
						}
					});

					this.bandwidthList = bandwidthList;
				}
			},
		},
		methods: {
			changeTab(tab) {
				this.searchTab = tab;
				// clear
				this.searchIp = "";
				this.searchArea = "";
				this.searchExpireDate = "";
				this.searchCreateDate = "";
				this.searchLabel = "";
				this.searchBandwidth = "";
				this.searchOrderIds = "";

				this.clickSearch();
			},
			// 使用中
			searchExpDateChange(dates) {
				if (dates.from && dates.to) {
					this.searchExpireDate = dates.from + "~" + dates.to;
				} else {
					this.searchExpireDate = "";
				}
			},
			searchCreatDateChange(dates) {
				if (dates.from && dates.to) {
					this.searchCreateDate = dates.from + "~" + dates.to;
				} else {
					this.searchCreateDate = "";
				}
			},
			//
			clickSearch(defParams = {}) {
				let searchParams =
					this.searchTab === 2
						? {
								id: this.searchOrderIds
									? this.searchOrderIds.split(",")
									: "", // int64
								postage_id:
									this.searchArea && this.areaList && this.areaList.length
										? this.areaList[Number(this.searchArea)].id
										: "", // string
								bandwidth:
									this.searchBandwidth &&
									this.bandwidthList &&
									this.bandwidthList.length
										? this.bandwidthList[this.searchBandwidth].bandwidth
										: "", // int
								expire: this.searchExpireDate,
								created: this.searchCreateDate, // string 2021-07-18~2021-07-20
						  }
						: {
								ip: this.searchIp, // string
								postage_id:
									this.searchArea && this.areaList && this.areaList.length
										? this.areaList[Number(this.searchArea)].id
										: "", // string
								label: this.searchLabel, // string
								bandwidth:
									this.searchBandwidth &&
									this.bandwidthList &&
									this.bandwidthList.length
										? this.bandwidthList[this.searchBandwidth].bandwidth
										: "", // int
								expire: this.searchExpireDate,
								created: this.searchCreateDate, // string 2021-07-18~2021-07-20
						  };
				Object.keys(searchParams).forEach((key) => {
					if (!searchParams[key]) {
						delete searchParams[key];
					}
				});

				let params = {
					tab: this.searchTab, // int  1:使用中.2:待配置.3:已过期
					searchParams: Object.keys(searchParams).length
						? JSON.stringify(searchParams)
						: "", // object to string
				};
				if (!params.searchParams) {
					delete params.searchParams;
				}
				this.onClickSearch({ ...params, ...defParams });
			},
		},
		created() {
			this.$store
				.dispatch("fetchPostage")
				.then(() => {
					if (this.postages && this.postages.length) {
						const areaList = [];
						this.postages.forEach((i) => {
							if (
								areaList.filter((j) => j.area_name === i.area_name)
									.length <= 0
							) {
								areaList.push({
									area_name: i.area_name,
									area_name_en: i.area_name_en,
									id: [i.id],
								});
							} else {
								const indexArea = areaList.findIndex(
									(j) => j.area_name === i.area_name
								);
								areaList[indexArea].id = [...areaList[indexArea].id, i.id];
							}
						});
						this.areaList = areaList;
					}
				})
				.catch(() => {
					this.areaList = [];
				});

			this.bandwidthList = [];

			this.clickSearch({
				field: "created", // string order column
				order: "desc", //
			});
		},
	};
</script>
