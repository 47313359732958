var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('modal',{ref:"modal",attrs:{"closable":_vm.closable,"title":_vm.modalTitle,"classes":"modal-lg","onClose":_vm.onModalClose},scopedSlots:_vm._u([(_vm.payment === 'OFFLINEPAY')?{key:"footer",fn:function(){return [_c('c-button',{attrs:{"styles":"min-width: 200px","text":_vm.$t('buyLine.upload_payment_voucher'),"clicked":_vm.clickUploadVoucher}}),_vm._v(" "),_c('input',{staticStyle:{"display":"none"},attrs:{"type":"file","id":"input_payment_voucher","accept":"image/png, image/jpeg, image/jpg"},on:{"change":_vm.onVoucherUploaded}}),_vm._v(" "),(!_vm.hideNotUpload)?_c('c-button',{attrs:{"styles":"min-width: 200px","text":_vm.$t('buyLine.not_upload_yet'),"clicked":_vm.clicknotUpload}}):_vm._e()]},proxy:true}:null],null,true)},[(_vm.payment.indexOf('ALIPAY')>-1 || _vm.payment.indexOf('UNION')>-1 )?_c('div',{staticClass:"row text-center"},[_c('div',{staticClass:"col-12"},[_c('p',{staticClass:"f14 mb-1 mr-1 user-select-none"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("buyLine.scan_to_pay"))+"\n\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('qr-code',{attrs:{"size":150,"level":"M","value":_vm.transferInfo.link_url}})],1),_vm._v(" "),_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"\n\t\t\t\t\td-flex\n\t\t\t\t\tflex-wrap\n\t\t\t\t\tjustify-content-center\n\t\t\t\t\talign-items-center\n\t\t\t\t"},[_c('p',{staticClass:"w-100 mt-2 mb-0"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.$t("buyLine.payment_amount"))+"\n\t\t\t\t")]),_vm._v(" "),_c('p',{staticClass:"\n\t\t\t\t\t\tf24\n\t\t\t\t\t\tmb-0\n\t\t\t\t\t\tuser-select-none\n\t\t\t\t\t\tcolor-dark-orange\n\t\t\t\t\t\tfont-weight-bold\n\t\t\t\t\t"},[_vm._v("\n\t\t\t\t\t"+_vm._s(_vm.transferInfo.rate === 0 || _vm.transferInfo.rate === 1
							? "$ " + _vm.transferInfo.price
							: ("￥ " + (_vm.transferInfo.totalMoney) + " ( $ " + (_vm.transferInfo.price) + " )"))+"\n\t\t\t\t")])])]),_vm._v(" "),_c('div',{staticClass:"col-12 d-flex justify-content-center mt-3"},[_c('c-button',{attrs:{"classes":"mx-2 mt-2","styles":"min-width: 130px","text":_vm.$t('i_have_paid'),"clicked":_vm.checkPaid}}),_vm._v(" "),_c('c-button',{attrs:{"classes":"mx-2 mt-2","styles":"min-width: 130px","text":_vm.$tc('unpaid'),"clicked":_vm.checkPaid}})],1)]):_c('transfer-info',{attrs:{"orderId":_vm.transferInfo.parent_order_id,"orderAmount":_vm.transferInfo.rate === 0 || _vm.transferInfo.rate === 1
				? '$ ' + _vm.transferInfo.price
				: ("￥ " + (_vm.transferInfo.totalMoney) + " ( $ " + (_vm.transferInfo.price) + " )"),"accBank":_vm.transferInfo.bank_info ? _vm.transferInfo.bank_info.bank_name || '' : '',"payee":_vm.transferInfo.bank_info
				? _vm.transferInfo.bank_info.account_name || ''
				: '',"receiveAcc":_vm.transferInfo.bank_info
				? _vm.transferInfo.bank_info.bank_account || ''
				: ''}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }