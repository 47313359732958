import Vue from "vue";
Vue.use(require("vue-moment"));

import {
  FETCH_USER,
  FETCH_USER_INFO,
  FETCH_USER_LOGIN_DEVICE
} from "@/store/mutations-type";
import { localS, keys, api } from "@/utils";

const defaultState = {
  uid: "",
  token: "",
  username: "",
  phone: "",
  telegram: "",
  qq: "",
  company_name: "",
  country: "",
  address: "",
  two_verify: 0, // 是否开启2步验证, 0: 否   1: 是
  token_verify: "",
  verify_phone: "",
  list: null, // login device
  expiredDate: "" // set expired (for frontend only)
};

const user = {
  state: { ...localS.getJSON(keys.stateUser, defaultState) },
  getters: {
    uid: state => state.uid,
    username: state => state.username,
    phone: state => state.phone,
    telegram: state => state.telegram,
    companyName: state => state.company_name,
    country: state => state.country,
    address: state => state.address,
    verifyPhone: state => state.verify_phone,
    twoVerify: state => state.two_verify,
    loginRecord: state => state.list,
    expiredDate: state => state.expiredDate
  },
  mutations: {
    [FETCH_USER](state, payload) {
      state.uid = payload.uid;
      state.token = payload.token;
      state.username = payload.username;
      state.phone = payload.phone;
      state.telegram = payload.telegram;
      state.qq = payload.qq;
      state.company_name = payload.company_name;
      state.country = payload.country;
      state.address = payload.address;
      state.two_verify = payload.two_verify;
      state.token_verify = payload.token_verify;
      state.verify_phone = payload.verify_phone;
      // set expired (for frontend only)
      state.expiredDate = payload.expiredDate;

      localS.setJSON(keys.stateUser, state);
    },
    [FETCH_USER_INFO](state, payload) {
      state.uid = payload.uid;
      state.username = payload.username;
      state.phone = payload.phone;
      state.telegram = payload.telegram;
      state.qq = payload.qq;
      state.company_name = payload.company_name;
      state.country = payload.country;
      state.address = payload.address;
      state.two_verify = payload.two_verify;
      state.token_verify = payload.token_verify;
      state.verify_phone = payload.verify_phone;

      localS.setJSON(keys.stateUser, state);
    },
    [FETCH_USER_LOGIN_DEVICE](state, payload) {
      state.list = payload;

      localS.setJSON(keys.stateUser, state);
    }
  },
  actions: {
    fetchUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        let newState = {
          ...defaultState,
          ...payload,
          expiredDate: Vue.moment()
            .add(7, "d")
            .format("YYYY-MM-DD")
        };
        commit(FETCH_USER, newState);
        resolve();
      });
    },
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        api
          .getCompanyInfo()
          .then(response => {
            const { data } = response;

            let list = [];
            data.list.forEach(i => {
              list.push({
                ...i,
                loginTime: Vue.moment(i.created * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              });
            });

            let newState = { ...state, ...data.info };
            commit(FETCH_USER_INFO, newState);
            commit(FETCH_USER_LOGIN_DEVICE, list);
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    }
  }
};

export default user;
