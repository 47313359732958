<template>
	<div v-if="!title" class="row flex-nowrap align-items-center mx-0">
		<div class="col-auto px-0">
			<div class="input-group">
				<input
					type="date"
					class="form-control from"
					v-model="from"
					@change="changeFrom($event)"
				/>
				<div v-show="from" class="input-group-append">
					<button
						class="btn btn-outline-default py-0 rounded-0"
						type="button"
						@click="clearFrom"
					>
						&times;
					</button>
				</div>
			</div>
		</div>
		<div class="col-auto px-2">
			<div class="divider" style="height: 2px; width: 20px"></div>
		</div>
		<div class="col-auto px-0">
			<div class="input-group">
				<input
					type="date"
					:class="'form-control to' + (to ? ' not-last' : '')"
					v-model="to"
					@change="changeTo($event)"
				/>
				<div v-show="to" class="input-group-append">
					<button
						class="btn btn-outline-default py-0"
						type="button"
						@click="clearTo"
					>
						&times;
					</button>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="titleAlign === 'left'" class="form-group row flex-nowrap">
		<label
			:class="'col-auto col-form-label ' + titleClasses"
			:style="titleStyles"
		>
			{{ title }}
		</label>
		<div class="col-auto px-0">
			<div class="row flex-nowrap align-items-center mx-0">
				<div class="col-auto px-0">
					<div class="input-group">
						<input
							type="date"
							class="form-control from"
							v-model="from"
							@change="changeFrom($event)"
						/>
						<div v-show="from" class="input-group-append">
							<button
								class="btn btn-outline-default py-0 rounded-0"
								type="button"
								@click="clearFrom"
							>
								&times;
							</button>
						</div>
					</div>
				</div>
				<div class="col-auto px-2">
					<div class="divider" style="height: 2px; width: 20px"></div>
				</div>
				<div class="col-auto px-0">
					<div class="input-group">
						<input
							type="date"
							:class="'form-control to' + (to ? ' not-last' : '')"
							v-model="to"
							@change="changeTo($event)"
						/>
						<div v-show="to" class="input-group-append">
							<button
								class="btn btn-outline-default py-0"
								type="button"
								@click="clearTo"
							>
								&times;
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div v-else-if="titleAlign === 'top'">
		<label :class="titleClasses" :style="titleStyles">{{ title }}</label>
		<div class="row flex-nowrap align-items-center mx-0">
			<div class="col-auto px-0">
				<div class="input-group">
					<input
						type="date"
						class="form-control from"
						v-model="from"
						@change="changeFrom($event)"
					/>
					<div v-show="from" class="input-group-append">
						<button
							class="btn btn-outline-default py-0 rounded-0"
							type="button"
							@click="clearFrom"
						>
							&times;
						</button>
					</div>
				</div>
			</div>
			<div class="col-auto px-2">
				<div class="divider" style="height: 2px; width: 20px"></div>
			</div>
			<div class="col-auto px-0">
				<div class="input-group">
					<input
						type="date"
						:class="'form-control to' + (to ? ' not-last' : '')"
						v-model="to"
						@change="changeTo($event)"
					/>
					<div v-show="to" class="input-group-append">
						<button
							class="btn btn-outline-default py-0"
							type="button"
							@click="clearTo"
						>
							&times;
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "datePicker",
		props: {
			titleAlign: {
				type: String,
				default: "left",
			},
			titleClasses: {
				type: String,
				default: "",
			},
			titleStyles: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			onChange: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				from: "",
				to: "",
			};
		},
		methods: {
			changeFrom(e) {
				this.from = e.target.value;
				this.onChange({
					from: this.from,
					to: this.to,
				});
			},
			clearFrom() {
				this.from = "";
				this.onChange({
					from: this.from,
					to: this.to,
				});
			},
			changeTo(e) {
				this.to = e.target.value;
				this.onChange({
					from: this.from,
					to: this.to,
				});
			},
			clearTo() {
				this.to = "";
				this.onChange({
					from: this.from,
					to: this.to,
				});
			},
		},
	};
</script>
