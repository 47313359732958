var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row justify-content-center align-items-center"},_vm._l((_vm.steps),function(item,index){return _c('div',{key:index,staticClass:"col-auto px-0 my-3 my-sm-3 my-md-5"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"step-contain d-flex flex-wrap justify-content-center"},[_c('div',{staticClass:"step-box text-center"},[_c('div',{class:'step-box-num user-select-none rounded-circle text-white text-center font-weight-bold h5 my-0 ' +
							(_vm.step >= index + 1 ? 'bg-main' : 'bg-gray')},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(index + 1)+"\n\t\t\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"ml-0 ml-md-2 text-center text-md-left"},[_c('p',{class:'step-title user-select-none font-weight-bold mb-0 ' +
							(_vm.step >= index + 1 ? 'color-main' : ''),staticStyle:{"max-width":"165px"}},[_vm._v("\n\t\t\t\t\t\t"+_vm._s(item.title || item)+"\n\t\t\t\t\t")]),_vm._v(" "),_c('p',{class:'step-progress user-select-none mb-0 ' +
							(_vm.step >= index + 1 ? 'color-main' : 'text-muted')},[_c('small',[_vm._v("\n\t\t\t\t\t\t\t"+_vm._s(_vm.$t(
									_vm.step >= index + 1
										? "step_in_progress"
										: "step_pending"
								))+"\n\t\t\t\t\t\t")])])])]),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(index !== _vm.steps.length - 1),expression:"index !== steps.length - 1"}],class:'divider mx-lg-2 mx-xl-3 ' + (_vm.step >= index + 1 ? 'bg-main' : '')})])])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }