<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	export default {
		name: "order",
	};
</script>
