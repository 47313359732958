<template>
	<div>
		<div class="input-group mb-4">
			<div class="input-group-prepend">
				<button
					:class="`btn btn-outline-default bg-white ${
						rounded ? '' : 'rounded-0'
					}`"
					type="button"
					@click="decrement"
					:disabled="disabled"
				>
					-
				</button>
			</div>
			<input
				type="number"
				:class="`form-control text-center ${
					rounded ? '' : 'rounded-0'
				} mx-3`"
				style="min-height: 34px"
				:value="value"
				@change="onValueChange"
				:disabled="disabled"
			/>
			<div class="input-group-append">
				<button
					:class="`btn btn-outline-default bg-white ${
						rounded ? '' : 'rounded-0'
					}`"
					type="button"
					@click="increment"
					:disabled="disabled"
				>
					+
				</button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "inputCounter",
		props: {
			rounded: {
				type: Boolean,
				default: false,
			},
			count: {
				type: Number,
				default: 1,
			},
			min: {
				type: Number,
				default: 1,
			},
			max: {
				type: Number,
				default: 0,
			},
			onNumberChange: {
				type: Function,
				default: () => {},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		data() {
			return {
				value: 1,
			};
		},
		watch: {
			value: function (newVal) {
				this.onNumberChange(newVal);
			},
			count: function (newVal, OldVal) {
				this.value = newVal;
			},
		},
		methods: {
			increment() {
				if (this.max) {
					if (this.value < this.max) {
						this.value += 1;
					}
				} else {
					this.value += 1;
				}
			},
			decrement() {
				if (this.value > this.min) {
					this.value -= 1;
				}
			},
			onValueChange(e) {
				const inputNumber = Number(e.target.value);
				if (this.max) {
					if (inputNumber > this.max) {
						this.value = this.max;
					} else {
						this.value = inputNumber || 1;
					}
				} else {
					this.value = inputNumber || 1;
				}
			},
		},
	};
</script>
