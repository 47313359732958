<template>
	<div>
		<div class="row">
			<div
				v-for="(item, index) in list"
				:key="index"
				:class="
					'col-12 col-sm-auto mb-4' +
					(index !== list.length - 1 ? ' pr-2' : '')
				"
			>
				<div
					:class="
						'duration-contain border bg-white position-relative' +
						(index === value ? ' border-main' : '') +
						(rounded ? ' rounded' : '')
					"
					@click="clicked(index)"
				>
					<!-- hot -->
					<div
						v-show="item.discount"
						class="
							user-select-none
							position-absolute
							text-white text-center
							bg-img-hot
							py-1
							px-2
						"
						style="
							left: -1px;
							top: -15px;
							font-size: 0.8em;
							border-top-right-radius: 6px;
							border-bottom-left-radius: 6px;
						"
					>
						{{ $t("great_value") }}
					</div>
					<!-- discount -->
					<div v-show="item.discount" class="bg-orange py-1">
						<p
							class="
								user-select-none
								text-center
								font-weight-bold
								color-dark-orange
								m-0
							"
						>
							{{ $t("discount_percent", [item.discount]) }}
						</p>
					</div>
					<!-- text center -->
					<p
						:class="
							'text-center user-select-none ' +
							(item.discount ? 'pt-2 mb-2' : 'py-4 mb-0')
						"
					>
						{{ item.title }}
					</p>
					<!-- border blue box white tick -->
					<img
						v-show="index === value"
						src="@/assets/imgs/rightBottomBlueTick.png"
						alt="white tick"
						class="position-absolute"
						:style="`bottom: -1px; right: -1px; border-bottom-right-radius: ${
							rounded ? '5' : '0'
						}px`"
					/>
				</div>
			</div>
		</div>
		<!-- <div class="d-flex mb-4" v-else>
			<vue-skeleton-loader
				v-for="index in listLength"
				:key="index"
				width="136px"
				height="72px"
				style="margin-right: 23px"
				:rounded="rounded"
				:radius="rounded ? 5 : 0"
			/>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "buttonDuration",
		props: {
			rounded: {
				type: Boolean,
				default: false,
			},
			list: {
				type: Array,
				default: [],
			},
			listLength: {
				type: Number,
				default: 4,
			},
			defaultVal: {
				type: Number,
			},
			onClicked: {
				type: Function,
				default: () => {},
			},
		},
		watch: {
			defaultVal: function (newVal) {
				this.value = newVal;
			},
		},
		data() {
			return {
				value: this.defaultVal !== null ? this.defaultVal - 1 : "",
			};
		},
		methods: {
			clicked(index) {
				this.value = index;
				this.onClicked(this.list[index], index);
			},
		},
	};
</script>

<style scoped>
	.duration-contain {
		width: 139px;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.duration-contain {
			width: 100%;
		}
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (min-width: 576px) and (max-width: 576px) {
		.duration-contain {
			width: 139px;
		}
	}
</style>