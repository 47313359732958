<template>
	<input
		:id="'counter_' + id"
		type="Number"
		min="1"
		:max="max"
		class="form-control rounded-0 spin-btn mx-auto"
		style="max-width: 100px"
		:value="value"
		@input="$emit('input', $event.target.value)"
		@change="onChange($event)"
	/>
</template>

<script>
	export default {
		name: "tCounter",
		props: {
			id: String,
			value: [String, Number],
			index: Number,
			callback: Function,
			data: Object,
		},
		computed: {
			max() {
				return this.data ? this.data.max : 0;
			},
		},
		methods: {
			onChange(e) {
				this.callback(Number(e.target.value), this.index);
			},
		},
	};
</script>
