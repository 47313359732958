<template>
	<div class="row justify-content-center align-items-center">
		<div
			v-for="(item, index) in steps"
			:key="index"
			class="col-auto px-0 my-3 my-sm-3 my-md-5"
		>
			<div class="d-flex">
				<div class="step-contain d-flex flex-wrap justify-content-center">
					<div class="step-box text-center">
						<div
							:class="
								'step-box-num user-select-none rounded-circle text-white text-center font-weight-bold h5 my-0 ' +
								(step >= index + 1 ? 'bg-main' : 'bg-gray')
							"
						>
							{{ index + 1 }}
						</div>
					</div>
					<div class="ml-0 ml-md-2 text-center text-md-left">
						<p
							:class="
								'step-title user-select-none font-weight-bold mb-0 ' +
								(step >= index + 1 ? 'color-main' : '')
							"
							style="max-width: 165px"
						>
							{{ item.title || item }}
						</p>
						<p
							:class="
								'step-progress user-select-none mb-0 ' +
								(step >= index + 1 ? 'color-main' : 'text-muted')
							"
						>
							<small>
								{{
									$t(
										step >= index + 1
											? "step_in_progress"
											: "step_pending"
									)
								}}
							</small>
						</p>
					</div>
				</div>
				<!-- divider -->
				<div
					v-show="index !== steps.length - 1"
					:class="
						'divider mx-lg-2 mx-xl-3 ' + (step >= index + 1 ? 'bg-main' : '')
					"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "stepper",
		props: {
			steps: {
				type: Array,
				default: [],
			},
			step: {
				type: [String, Number],
				default: 1,
			},
		},
	};
</script>

<style scoped>
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.step-contain {
			width: 90px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.step-contain {
			width: 190px;
		}
	}

	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.step-box {
			width: 100%;
			margin-bottom: 10px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.step-box {
			width: 100%;
			margin-bottom: 10px;
		}
	}

	.step-box-num {
		width: 45px;
		height: 45px;
		line-height: 45px;
		align-self: center;
		margin-left: auto;
		margin-right: auto;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.step-box-num {
			width: 30px;
			height: 30px;
			line-height: 30px;
			font-size: 1rem;
		}
	}

	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.step-title {
			font-size: 0.9rem;
		}
	}

	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.step-progress {
			font-size: 0.8rem;
		}
	}

	.divider {
		height: 2px;
		width: 120px;
		margin-top: auto;
		margin-bottom: auto;
	}
	/* Extra small devices (portrait phones, less than 576px) */
	@media (max-width: 576px) {
		.divider {
			width: 35px;
			margin-top: 15px;
			margin-left: -20px;
			margin-right: -20px;
		}
	}
	/* Small devices (landscape phones, 576px and up) */
	@media (min-width: 576px) and (max-width: 767.98px) {
		.divider {
			width: 95px;
			margin-top: 23px;
			margin-left: -60px;
			margin-right: -60px;
		}
	}
	/* Medium devices (tablets, 768px and up) */
	@media (min-width: 768px) and (max-width: 991.98px) {
		.divider {
			width: 70px;
		}
	}
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) and (max-width: 1199.98px) {
		.divider {
			width: 73px;
		}
	}
</style>