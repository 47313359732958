import Vue from "vue";
import VueI18n from "vue-i18n";
import { keys, localS } from "@/utils";

Vue.use(VueI18n);

export default new VueI18n({
  locale: localS.get(keys.lang) ? localS.get(keys.lang) : "zh_cn",
  messages: {
    en_us: require("@/assets/language/en_us.json"),
    // hi_in: require("@/assets/language/hi_in.json"),
    // ja_jp: require("@/assets/language/ja_jp.json"),
    // ms_my: require("@/assets/language/ms_my.json"),
    // th_th: require("@/assets/language/th_th.json"),
    zh_cn: require("@/assets/language/zh_cn.json")
    // zh_tw: require("@/assets/language/zh_tw.json"),
  },
  silentTranslationWarn: true
});
