<template>
	<div class="container">
		<div v-if="!send" class="bg-white pt-4 pb-5 px-3 px-sm-4 mb-5">
			<button
				type="button"
				class="btn btn-sm color-main"
				@click="$router.push({ name: linkLogin })"
			>
				<img
					src="@/assets/imgs/backArrow.png"
					alt="back arrow"
					class="pr-1"
					style="margin-top: -5px"
				/>
				{{ $t("forgetPassword.back_t_login") }}
			</button>
			<div class="text-center mt-4">
				<img src="@/assets/imgs/circleBlueLock.png" alt="lock" />
				<h5 class="font-weight-bold mt-2 mb-3">
					{{ $t("forgetPassword.reset_y_password") }}
				</h5>
			</div>
			<div class="row justify-content-center">
				<div class="col-sm-9 col-md-6">
					<h5 class="text-muted text-center mb-4">
						<small>
							{{ $t("forgetPassword.y_connect_email") }}
						</small>
					</h5>
					<!-- input email -->
					<c-input
						id="email"
						:placeholder="$t('forgetPassword.input_email')"
						v-model="email"
					>
						<template v-slot:prepend>
							<div class="input-group-prepend">
								<div class="input-group-text">
									<img src="@/assets/imgs/mailLight.png" />
								</div>
							</div>
						</template>
					</c-input>
				</div>
			</div>
			<!-- btn next -->
			<div class="row justify-content-center mt-4 mb-5">
				<div class="col-auto">
					<c-button
						loading
						styles="min-width: 135px"
						:text="$t('forgetPassword.next_step')"
						:disabled="!email"
						:clicked="clickSend"
					/>
				</div>
			</div>
		</div>
		<!-- send -->
		<div v-else class="bg-white py-5 px-4 mb-5">
			<div class="text-center mt-5">
				<img src="@/assets/imgs/circleBlueTick.png" alt="tick" />
				<h5 class="font-weight-bold mt-2 mb-3">
					{{ $t("forgetPassword.sent_success") }}
				</h5>
			</div>
			<div class="row justify-content-center">
				<div class="col-6">
					<h5 class="text-muted text-center mb-4">
						<small>
							{{ $t("forgetPassword.sent_t_mail", [email]) }}
						</small>
					</h5>
				</div>
			</div>
			<!-- btn confirm -->
			<div class="row justify-content-center mb-5">
				<div class="col-4">
					<c-button
						fullWidth
						:text="$t('forgetPassword.confirm')"
						:clicked="() => $router.go(-1)"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys } from "@/utils";

	export default {
		name: "forgetPassword",
		data() {
			return {
				linkLogin: keys.login,
				email: "",
				send: false,
			};
		},
		components: {
			cButton: () => import("@/components/button"),
			cInput: () => import("@/components/input"),
		},
		methods: {
			clickSend() {
				this.$store.dispatch("setIsLoading", false);

				// call api send mail

				this.send = true;
			},
		},
	};
</script>
