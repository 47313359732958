<template>
	<div>
		{{ toggle === "password" ? "******" : value || "-" }}
	</div>
</template>

<script>
	export default {
		name: "tToggleText",
		props: {
			value: String,
			column: Object,
		},
		computed: {
			toggle() {
				return this.column.toggle || "password";
			},
		},
	};
</script>
