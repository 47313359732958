
<template>
	<div>
		<div :class="!isLogin ? 'container' : 'container-fluid'">
			<!-- normal -->
			<div
				v-if="!isLogin"
				class="row justify-content-between align-items-center py-5 mb-4"
			>
				<div class="col-auto d-flex align-items-center">
					<!-- <router-link :to="linkLoginMode" class="text-decoration-none"> -->
						<!-- <img src="@/assets/imgs/logo_blue_title.png" alt="logo" /> -->
					<!-- </router-link> -->
					<!-- login -->
					<a href="https://gtnet.cc" class="text-decoration-none"  >
						<img src="@/assets/imgs/logo_blue_title.png" alt="logo" />
					</a>
				</div>
				<!-- language -->
				<div class="col-auto">
					<button-group
						color="white"
						classes="btn-sm dropdown-toggle"
						dropdownClasses="dropdown-menu-right"
						:dropdownItemsDefalut="
							langs.findIndex((item) => item.language === $i18n.locale)
						"
						:dropdownItems="langs"
						:onChange="changeLanguage"
					>
						<img v-if="!showText" src="@/assets/imgs/eart.png" />
						{{ showText ? $t("header.language") : "" }}
					</button-group>
				</div>
			</div>
			<!-- dashboard -->
			<div
				v-else
				class="row justify-content-between p-3 bg-white shadow-sm"
				style="min-height: 66px"
			>
				<div class="col-auto p-0">
					<div class="d-flex align-items-center h-100 d-block d-lg-none">
						<span
							id="id_btn_open_sidenav"
							class="color-black border-1 border-gray rounded px-2 mr-2"
							style="font-size: 21px; cursor: pointer"
							@click="handlerOpenNav"
							>&#9776;</span
						>
						<div>
							<img
								src="@/assets/imgs/logo_blue_title.png"
								alt="logo"
								class="d-none d-sm-block"
								style="max-width: 125px"
							/>
						</div>
					</div>
				</div>
				<div class="col-auto p-0">
					<div class="row">
						<!-- user button -->
						<div class="col-auto p-0">
							<button-group
								color="white"
								groupClasses="drophide"
								classes="btn-sm no-focus text-dark dropdown-toggle font-weight-bold"
								:dropdownItems="accItems"
								:dropdownItemsDefalut="accItemsDefault"
								:onChange="changeAccAction"
							>
								<img
									src="@/assets/imgs/circleWhiteUser.png"
									alt="Icon user"
									class="mr-2"
									style="
										max-width: 30px;
										margin-top: -4px;
										margin-bottom: -3px;
									"
								/>
								<span class="d-none d-sm-inline">{{
									companyName
								}}</span>
							</button-group>
						</div>
						<!-- feetback button -->
						<div class="col-auto p-0">
							<button
								type="button"
								class="btn btn-sm no-focus"
								:title="$t('header.qfeedback')"
								@click="feedback"
							>
								<img
									src="@/assets/imgs/mail.png"
									alt="Icon mail"
									style="max-height: 20px"
								/>
							</button>
						</div>
						<!-- bell -->
						<div class="col-auto p-0">
							<button-group
								id="notify"
								color="white"
								groupClasses="drophide"
								classes="btn-sm no-focus text-dark dropdown-toggle"
								dropdownClasses="dropdown-menu-right"
								:dropdownItems="notificationsList"
								:onCloseCallback="onCloseNotification"
							>
								<img
									src="@/assets/imgs/bell2x.png"
									alt="Icon Bell"
									style="max-width: 20px"
								/>
								<span
									v-if="notifyBadge"
									class="
										badge badge-pill badge-notice
										position-absolute
									"
									style="padding: 2px 4px; top: 0px; right: 4px"
								>
									{{ notifyBadge }}
								</span>
							</button-group>
						</div>
						<!-- language -->
						<div class="col-auto pl-0">
							<button-group
								color="gray-light"
								classes="btn-sm dropdown-toggle"
								:text="$t('header.language')"
								dropdownClasses="dropdown-menu-right"
								:dropdownItemsDefalut="
									langs.findIndex(
										(item) => item.language === $i18n.locale
									)
								"
								:dropdownItems="langs"
								:onChange="changeLanguage"
							>
								<img v-if="!showText" src="@/assets/imgs/eart.png" />
								{{ showText ? $t("header.language") : "" }}
							</button-group>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- modal -->
		<modal
			v-if="modalShow"
			ref="modal"
			:title="$t('header.feedback')"
			classes="modal-lg"
			:onClose="onModalClose"
		>
			<t-feedback :closeModal="clickCloseModal" />
		</modal>
	</div>
</template>

<script>
	import { localS, keys, helper } from "@/utils";

	export default {
		name: "app-header",
		components: {
			buttonGroup: () => import("@/components/button/group"),
			modal: () => import("@/components/modal"),
			tFeedback: () => import("@/components/templates/feedback"),
		},
		props: {
			handlerOpenNav: Function,
		},
		data() {
			let langs = this.$i18n.availableLocales.map((language) => {
				return {
					type: "button",
					language,
					lang: this.$i18n.messages[language].header.lang,
					text: this.$i18n.messages[language].header.language,
				};
			});
			return {
				linkLoginMode: keys.login, // loginMode,
				langs,
				accItems: [
					{
						text: "",
						subText: "",
						classes: "disabled color-black",
					},
					"hr",
					{
						id: 1,
						text: this.$t("header.acc_info"),
						prepend: {
							type: "img",
							src: require("@/assets/imgs/user.png"),
						},
					},
					{
						id: 2,
						text: this.$t("header.sign_out"),
						prepend: {
							type: "img",
							src: require("@/assets/imgs/exit.png"),
						},
					},
				],
				accItemsDefault: -1,
				// modal
				modalShow: false,
				// event
				showText: true,
			};
		},
		computed: {
			isLogin() {
				const routeName = this.$route.name;
				return (
					!!this.$store.getters.uid &&
					routeName !== keys.register &&
					routeName !== keys.resetPassword &&
					routeName !== keys.forgetPassword
				);
			},
			username() {
				return this.$store.getters.username;
			},
			companyName() {
				return this.$store.getters.companyName;
			},
			notificationsList() {
				return this.$store.getters.notifications;
			},
			notifyBadge() {
				return this.$store.getters.notifyBadge;
			},
		},
		watch: {
			"$i18n.locale": function () {
				this.accItems[2].text = this.$t("header.acc_info");
				this.accItems[3].text = this.$t("header.sign_out");
			},
			$route: function () {
				this.checkCurPage();
			},
		},
		methods: {
			changeLanguage(item) {
				localS.set(keys.lang, item.language);
				this.$i18n.locale = item.language;
				document.documentElement.setAttribute("lang", item.lang);
			},
			checkCurPage() {
				const curPage = window.location.pathname.split("/")[1];
				if (curPage === "account") {
					this.accItemsDefault = 2;
				} else {
					this.accItemsDefault = -1;
				}
			},
			changeAccAction(item) {
				if (item.id === 1) {
					this.$router.push({ name: keys.account });
				} else if (item.id === 2) {
					helper.logout();
				}
			},
			feedback() {
				this.modalShow = true;
			},
			onCloseNotification() {
				this.$store.dispatch("notificationSeen");
			},
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.modalShow = false;
				this.$store.dispatch("setIsLoading", false);
			},
			// event
			rezise() {
				if (window.innerWidth < 768) {
					this.showText = false;
				} else {
					this.showText = true;
				}
			},
		},
		created() {
			this.accItems[0].text = this.companyName;
			this.accItems[0].subText = this.username;

			if (this.isLogin) {
				this.checkCurPage();
				this.$store.dispatch("fetchNotification");
			}
		},
		mounted() {
			this.rezise();
			window.addEventListener("resize", this.rezise);
		},
		beforeDestroy() {
			window.removeEventListener("resize", this.rezise);
		},
	};
</script>