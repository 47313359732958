<template>
	<div>
		<input
			type="checkbox"
			:checked="value"
			@input="$emit('input', $event.target.checked)"
			@change="onCheckChange($event)"
			:id="'id_checkbox_' + id"
		/>
		<label :class="'position-relative ' + classes" :for="'id_checkbox_' + id">
			<slot>
				{{ text }}
			</slot>
		</label>
	</div>
</template>

<script>
	export default {
		name: "checkbox",
		props: {
			id: {
				type: String,
				default: "",
			},
			classes: {
				type: String,
				default: "",
			},
			value: {
				type: Boolean,
				default: false,
			},
			text: {
				type: String,
				default: "",
			},
			onCheck: {
				type: Function,
				default: () => {},
			},
		},
		methods: {
			onCheckChange(e) {
				this.onCheck(e.target.checked);
			},
		},
	};
</script>
