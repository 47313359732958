<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<!-- acc info -->
			<div class="row justify-content-between align-items-center mb-2">
				<div class="col-auto">
					<h5>
						<small class="font-weight-bold">
							{{ $t("account.personal_data") }}
						</small>
					</h5>
				</div>
				<div class="col-auto">
					<c-button loading classes="px-4 mr-2" :clicked="clickEdit">
						<img
							src="@/assets/imgs/edit.png"
							alt="edit"
							class="pr-1"
							style="margin-top: -3px"
						/>
						{{ $t(`account.${!editAccInfo ? "edit" : "complete"}`) }}
					</c-button>
				</div>
			</div>
			<div class="row bg-lighter-gray color-darker-gray px-1 py-3 mx-0">
				<div class="col-sm-12 col-md-6">
					<div class="row">
						<div class="col-12 col-sm-4 py-1">
							{{ $t("account.email") }}
						</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							{{ username || "-" }}
						</div>
						<!-- password -->
						<div class="col-12 col-sm-4 py-1">
							{{ $t("account.password") }}
						</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							<span v-if="!editAccInfo">**********</span>
							<template v-else>
								<c-input
									class="mb-2"
									inputClasses="rounded-0"
									:type="editPwsdType"
									id="edit_old_password"
									:placeholder="$t('account.input_old_password')"
									v-model="editOldPassword"
								>
									<template v-slot:append>
										<div class="input-group-append">
											<span
												class="
													input-group-text
													bg-transparent
													rounded-0
												"
												@click="togglePassword()"
											>
												<img
													src="@/assets/imgs/eyeOpen.png"
													alt="show/hide"
													class="imgTogglePassword"
												/>
											</span>
										</div>
									</template>
								</c-input>
								<c-input
									inputClasses="rounded-0"
									:type="editPwsdType"
									id="edit_new_password"
									:placeholder="$t('account.input_new_password')"
									v-model="editNewPassword"
								>
									<template v-slot:append>
										<div class="input-group-append">
											<span
												class="
													input-group-text
													bg-transparent
													rounded-0
												"
												@click="togglePassword()"
											>
												<img
													src="@/assets/imgs/eyeOpen.png"
													alt="show/hide"
													class="imgTogglePassword"
												/>
											</span>
										</div>
									</template>
								</c-input>
							</template>
						</div>
						<!-- phone -->
						<div class="col-12 col-sm-4 py-1">
							{{ $t("account.contact_number") }}
						</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							<span v-if="!editAccInfo">{{ phone || "-" }}</span>
							<div v-else class="row">
								<div class="col-12 col-sm-5 pr-md-0 mb-2 mb-sm-0">
									<c-select
										selectClasses="no-focus"
										:placeholder="$t('register.select_country')"
										:list="translate(editCountries, 'countries')"
										optionKey="text"
										v-model="editPhoneCountry"
										:changed="changePhoneCountry"
									/>
								</div>
								<div class="col-12 col-sm-7 pl-md-0">
									<c-input
										type="number"
										id="edit_phone_number"
										inputClasses="rounded-0"
										titleAlign="top"
										:placeholder="$t('account.input_phone_number')"
										v-model="editPhone"
									>
										<template v-slot:prepend>
											<div
												v-if="editPhoneCountryCode"
												class="input-group-prepend"
											>
												<span
													class="input-group-text"
													style="font-size: 0.8rem"
												>
													{{ editPhoneCountryCode }}
												</span>
											</div>
										</template>
									</c-input>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="col-sm-12 col-md-6">
					<div class="row">
						<!-- telegram -->
						<div class="col-12 col-sm-4 py-1">Telegram：</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							<span v-if="!editAccInfo">{{ telegram || "-" }}</span>
							<c-input
								v-else
								inputClasses="rounded-0"
								id="edit_telegram"
								v-model="editTelegram"
							/>
						</div>
						<div class="col-12 col-sm-4 py-1">
							{{ $t("account.office") }}
						</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							<span v-if="!editAccInfo">{{ companyName || "-" }}</span>
							<c-input
								v-else
								inputClasses="rounded-0"
								id="edit_company_name"
								v-model="editCompanyName"
							/>
						</div>
						<div class="col-12 col-sm-4 py-1">
							{{ $t("account.office_address") }}
						</div>
						<div class="col-12 col-sm-8 py-1 mb-3 mb-sm-0">
							<span v-if="!editAccInfo">
								{{
									`${country ? country + "," : ""} ${address || "-"}`
								}}
							</span>
							<div v-else class="d-flex align-items-center">
								<c-select
									selectClasses="no-focus"
									:placeholder="$t('register.select_country')"
									:list="translate(editCountries, 'countries')"
									optionKey="text"
									v-model="editAddressCountry"
								/>
								<span class="mx-2">-</span>
								<c-input
									inputClasses="rounded-0"
									id="edit_company_address"
									v-model="editAddress"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- two factor -->
			<div
				class="
					row
					bg-lighter-gray
					justify-content-between
					align-items-center
					px-1
					py-3
					mx-0
					mt-3
				"
			>
				<div class="col-auto">
					<h5>
						<small class="font-weight-bold">
							{{ $t("account.two_fac") }}
						</small>
					</h5>
					<p class="mb-1">
						<small class="text-muted">
							{{ $t("account.two_fac_desc") }}
						</small>
					</p>
					<div v-if="twoVerify" class="d-flex align-items-center mt-2">
						<p class="mb-0">{{ $t("account.current_verify_phone") }}</p>
						<c-input
							type="number"
							classes="mx-0 mb-0"
							titleClasses="text-right"
							:title="twoFacPhoneCountryCode"
							:disabled="!editTwoFacPhone"
							v-model="twoFacPhone"
						/>
						<c-button
							:rounded="false"
							color="main"
							classes="px-2 ml-3"
							:text="$t('account.modify')"
							:clicked="clickEditTwoFactor"
						/>
					</div>
					<p v-else class="mb-sm-0">
						<small class="color-orange">
							{{ $t("account.protect_acc_after_verify") }}
						</small>
					</p>
				</div>
				<div class="col-auto">
					<c-button
						v-if="twoVerify"
						:rounded="false"
						color="outline-orange"
						classes="px-4"
						:text="$t('account.disable')"
						:clicked="clickDisableTwoFactor"
					/>
					<c-button
						v-else
						:rounded="false"
						color="outline-main"
						classes="px-3"
						:text="$t('account.add_verify')"
						:clicked="clickEnableTwoFactor"
					/>
				</div>
			</div>
			<!-- login record -->
			<h5 class="mt-3">
				<small class="font-weight-bold">
					{{ $t("account.login_record") }}
				</small>
			</h5>
			<c-table
				tableStyle="min-width: 600px"
				:columns="columns"
				:data="loginRecord"
			/>
		</div>
		<!-- modal -->
		<modal
			v-if="modalShow"
			ref="modal"
			:title="modalTitle"
			classes="modal-lg"
			:onClose="onModalClose"
		>
			<t-enable-two-fac
				v-if="modalCase == 'enable'"
				:data="enableTwoFacData"
				:cancel="clickCloseModal"
				:next="enableTwoFacNext"
			/>
			<t-verify-two-fac
				v-if="modalCase == 'verify'"
				:phone="enableTwoFacData.countryCode + '-' + enableTwoFacData.phone"
				:second="resendVerifySecond"
				:resend="resendOtp"
				:back="backToEnable"
				:next="verifyTwoFacNext"
			/>
			<flash-message-two-fac
				v-if="modalCase == 'flashMessage'"
				:state="flashState"
				:phone="phone"
				:clickFinish="clickCloseModal"
				:clickConfirm="clickConfirmDisable"
			/>
		</modal>
	</div>
</template>

<script>
	import { columnLoginRecord } from "@/components/table/data";
	import { api, keys, helper } from "@/utils";
	import { success } from "@/components/alert/index.js";

	let interval = null;

	export default {
		name: "account",
		components: {
			cButton: () => import("@/components/button"),
			cTable: () => import("@/components/table"),
			cInput: () => import("@/components/input"),
			cSelect: () => import("@/components/select"),
			modal: () => import("@/components/modal"),
			tEnableTwoFac: () => import("@/components/templates/enableTwoFac"),
			tVerifyTwoFac: () => import("@/components/templates/verifyTwoFac"),
			flashMessageTwoFac: () => import("@/components/flashMessage/twoFac"),
		},
		data() {
			return {
				// edit
				editAccInfo: false,
				editPwsdType: "password",
				editOldPassword: "",
				editNewPassword: "",
				editCountries: Object.keys(
					this.$i18n.messages[this.$i18n.locale].countries
				),
				editPhone: "",
				editPhoneCountry: "",
				editPhoneCountryCode: "",
				editTelegram: "",
				editCompanyName: "",
				editAddressCountry: "",
				editAddress: "",
				//
				twoFacPhoneCountryCode: "",
				twoFacPhone: "",
				editTwoFacPhone: false,
				//
				columns: columnLoginRecord(),
				// modal
				modalShow: false,
				modalTitle: "",
				modalCase: "",
				enableTwoFacData: null,
				resendVerifySecond: "",
				flashState: true,
			};
		},
		computed: {
			username() {
				return this.$store.getters.username;
			},
			phone() {
				return this.$store.getters.phone;
			},
			telegram() {
				return this.$store.getters.telegram;
			},
			companyName() {
				return this.$store.getters.companyName;
			},
			country() {
				return this.$store.getters.country;
			},
			address() {
				return this.$store.getters.address;
			},
			twoVerify() {
				return this.$store.getters.twoVerify;
			},
			loginRecord() {
				return this.$store.getters.loginRecord;
			},
		},
		methods: {
			// edit
			translate: helper.translate,
			clickEdit() {
				if (!this.editAccInfo) {
					// phone
					let sPhone = this.phone.split("-");
					let findCountryCode = "";
					Object.keys(keys.countryCodes).forEach((key) => {
						if (keys.countryCodes[key] === sPhone[0].split("+")[1]) {
							findCountryCode = key;
						}
					});
					let findCountryIndex = this.editCountries.findIndex(
						(i) => i === findCountryCode
					);
					this.editPhone = sPhone[1];
					this.editPhoneCountry = `${findCountryIndex}`;
					this.editPhoneCountryCode = sPhone[0];
					// telegram
					this.editTelegram = this.telegram;
					// company
					this.editCompanyName = this.companyName;
					// address
					let findAddressCountryCode = this.editCountries.findIndex(
						(key) =>
							this.$i18n.messages[this.$i18n.locale].countries[key] ===
							this.country
					);
					this.editAddressCountry = `${findAddressCountryCode}`;
					this.editAddress = this.address;

					this.editAccInfo = !this.editAccInfo;
					this.$store.dispatch("setIsLoading", false);
				} else {
					const newPhone = this.editPhoneCountryCode + "-" + this.editPhone;
					// const newCountry =
					// 	this.$i18n.messages["zh_cn"].countries[
					// 		this.editCountries[this.editAddressCountry]
					// 	];
					let params = {
						old_password: this.editOldPassword,
						new_password: this.editNewPassword,
						phone: newPhone !== this.phone ? newPhone : "",
						// qq string qq(选填)
						telegram:
							this.editTelegram !== this.telegram ? this.editTelegram : "",
						// country: newCountry !== this.country ? newCountry : "",
					};
					// clear empty value
					Object.keys(params).forEach((key) => {
						if (!params[key]) {
							delete params[key];
						}
					});
					//
					if (Object.keys(params).length) {
						const _this = this;
						api.pathchCompanyInfo(params)
							.then((response) => {
								_this.resetDataAndGetInfo(true);
								success(
									_this.$t("action_success", [_this.$t("account.edit")])
								);
								_this.$store.dispatch("setIsLoading", false);
							})
							.catch((err) => {
								_this.resetDataAndGetInfo();
								_this.$store.dispatch("setIsLoading", false);
							});
					} else {
						this.editAccInfo = false;
						this.$store.dispatch("setIsLoading", false);
					}
				}
			},
			resetDataAndGetInfo(getInfo = false) {
				this.editPwsdType = "password";
				this.editOldPassword = "";
				this.editNewPassword = "";
				this.editPhone = "";
				this.editPhoneCountry = "";
				this.editPhoneCountryCode = "";
				this.editTelegram = "";
				this.editCompanyName = "";
				this.editAddressCountry = "";
				this.editAddress = "";
				this.editAccInfo = false;
				if (getInfo) {
					this.getInfo();
				}
			},
			togglePassword() {
				this.inputPwsdType =
					this.inputPwsdType == "text" ? "password" : "text";
				$(".imgTogglePassword").attr(
					"src",
					this.inputPwsdType == "text"
						? require("@/assets/imgs/eyeClose.png")
						: require("@/assets/imgs/eyeOpen.png")
				);
			},
			changePhoneCountry(item) {
				this.editPhoneCountryCode = "+" + keys.countryCodes[item.key];
			},
			//
			clickEnableTwoFactor() {
				this.modalShow = true;
				this.modalTitle = this.$t("account.two_fac");
				this.modalCase = "enable";
			},
			clickEditTwoFactor() {
				this.editTwoFacPhone = true;
			},
			clickDisableTwoFactor() {
				this.modalShow = true;
				this.modalTitle = this.$t("account.disable");
				this.modalCase = "flashMessage";
				this.flashState = false;
			},
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.modalShow = false;
				this.modalTitle = "";
				this.modalCase = "";
				this.resendVerifySecond = "";
				clearInterval(interval);
				interval = null;
				this.$store.dispatch("setIsLoading", false);
			},
			enableTwoFacNext(data) {
				this.enableTwoFacData = data;
				this.sendOtp();
			},
			sendOtp() {
				const _this = this;
				api.getCaptcha({
					phone:
						this.enableTwoFacData.countryCode +
						"-" +
						this.enableTwoFacData.phone,
				})
					.then((response) => {
						_this.resendVerifySecond = 60;
						interval = setInterval(function () {
							_this.countDownSecond();
						}, 1000);
						_this.$store.dispatch("setIsLoading", false);
						_this.modalCase = "verify";
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			// count down for resend verification code
			countDownSecond() {
				if (this.resendVerifySecond && this.resendVerifySecond > 0) {
					this.resendVerifySecond -= 1;
				} else {
					this.resendVerifySecond = "";
					clearInterval(interval);
					interval = null;
				}
			},
			resendOtp() {
				this.sendOtp();
			},
			backToEnable() {
				clearInterval(interval);
				this.resendVerifySecond = "";
				this.modalCase = "enable";
			},
			verifyTwoFacNext(data) {
				const _this = this;
				api.postCaptcha({
					...data,
					phone:
						this.enableTwoFacData.countryCode +
						"-" +
						this.enableTwoFacData.phone,
				})
					.then((response) => {
						const { data } = response;
						if (data.token) {
							_this.$store.dispatch("fetchUser", data).then(() => {
								_this.clickCloseModal();
							});
						}
						_this.$store.dispatch("setIsLoading", false);
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			clickConfirmDisable() {
				const _this = this;
				api.pathchCompanyInfo({
					two_verify: 0,
				})
					.then((response) => {
						_this.clickCloseModal();
						_this.getInfo();
						_this.$store.dispatch("setIsLoading", false);
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
					});
			},
			// get account info
			getInfo() {
				this.$store
					.dispatch("getUserInfo")
					.then(() => {
						const vPhone = this.phone.split("-");
						this.twoFacPhoneCountryCode = vPhone[0] || "";
						this.twoFacPhone = vPhone[1] || "";
					})
					.catch((error) => {});
			},
		},
		created() {
			this.getInfo();
		},
	};
</script>
