var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.state || (_vm.isUploadVoucher && !_vm.noUpload))?_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mt-4",attrs:{"src":require("@/assets/imgs/orderSuccess.png"),"alt":"success"}}),_vm._v(" "),_c('h5',{staticClass:"font-weight-bold mt-4 mb-3"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t(
					("orderState." + (_vm.isUploadVoucher
							? "upload_certificate_success"
							: "success_purchase"))
				))+"\n\t\t")]),_vm._v(" "),_c('p',{staticClass:"text-muted mx-auto",staticStyle:{"max-width":"500px"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("orderState.success_purchase_desc"))+"\n\t\t")]),_vm._v(" "),_c('c-button',{attrs:{"classes":"mt-3 mb-4 px-5","text":_vm.$t('orderState.check_order'),"clicked":_vm.viewOrder}})],1):_c('div',{staticClass:"text-center"},[_c('img',{staticClass:"mt-4",attrs:{"src":require("@/assets/imgs/orderFail.png"),"alt":"fail"}}),_vm._v(" "),_c('h5',{staticClass:"font-weight-bold mt-4"},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t(
					("orderState." + (_vm.noUpload ? "fail_upload_payment" : "payment_failed"))
				))+"\n\t\t")]),_vm._v(" "),(_vm.noUpload)?_c('p',{staticClass:"text-muted mx-auto",staticStyle:{"max-width":"500px"}},[_vm._v("\n\t\t\t"+_vm._s(_vm.$t("orderState.fail_upload_payment_desc"))+"\n\t\t")]):_vm._e(),_vm._v(" "),_c('c-button',{attrs:{"classes":"my-4 px-5","text":_vm.$t(
					("orderState." + (_vm.noUpload ? 'upload_payment_voucher' : 'pay_again'))
				),"clicked":_vm.repayOrder}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }