<template>
	<div class="d-flex justify-content-center">
		<div style="max-width: 500px">
			<h5 class="text-center">
				<small>{{ $t("account.confirm_phone_valid") }}</small>
			</h5>
			<h5 class="text-center">
				<small>
					{{ $t("account.otp_sent_via") }}
					<span class="color-main">{{ phone }}</span>
				</small>
			</h5>
			<c-input
				type="number"
				id="otp"
				classes="mt-5"
				:placeholder="$t('account.input_otp')"
				v-model="otp"
			>
				<template v-slot:append>
					<div class="input-group-append">
						<div class="input-group-text bg-white">
							<button
								class="btn btn-sm color-main no-focus p-0"
								@click="resend"
								:disabled="!!second"
							>
								{{ $tc("account.resend", second ? 2 : 1, [second]) }}
							</button>
						</div>
					</div>
				</template>
			</c-input>
			<div class="d-flex justify-content-center mt-5">
				<c-button
					color="gray"
					classes="px-5 mr-2"
					:text="$t('account.prev_step')"
					:clicked="back"
				/>
				<c-button
					loading
					classes="px-5 ml-2"
					:text="$t('account.next_step')"
					:disabled="!otp"
					:clicked="clickNext"
				/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "verifyTwoFac",
		components: {
			cInput: () => import("@/components/input"),
			cButton: () => import("@/components/button"),
		},
		props: {
			phone: {
				type: String,
				default: "",
			},
			second: {
				type: [String, Number],
				default: "",
			},
			resend: {
				type: Function,
				default: () => {},
			},
			back: {
				type: Function,
				default: () => {},
			},
			next: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				otp: "",
			};
		},
		methods: {
			clickNext() {
				this.next({ captcha: this.otp });
			},
		},
	};
</script>
