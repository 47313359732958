<template>
	<div class="container">
		<div class="position-relative">
			<img
				src="@/assets/imgs/repeat_dot.png"
				alt="dot"
				class="position-absolute d-none d-sm-block"
				style="left: -37px; top: -26px; z-index: -1"
			/>
			<img
				src="@/assets/imgs/repeat_dot.png"
				alt="dot"
				class="position-absolute d-none d-sm-block"
				style="right: -50px; bottom: -41px; z-index: -1"
			/>
			<div v-if="!nextInformation" class="row">
				<div
					class="
						col-6
						bg-gradient-main
						rounded-left
						pt-5
						pb-4
						px-4
						d-none d-lg-block
					"
				>
					<h4 class="text-white font-weight-bold px-2">
						{{ $t("register.hi") }}
					</h4>
					<h4 class="text-white font-weight-bold px-2">
						{{ $t("register.please_create") }}
					</h4>
					<img
						src="@/assets/imgs/product.png"
						alt="product"
						class="mt-5"
					/>
				</div>
				<div class="col-lg-6 bg-white rounded-right py-5">
					<h4 class="text-center">
						<small class="font-weight-bold">
							{{ $t("register.create_your_account") }}
						</small>
					</h4>
					<div class="mx-sm-5 px-2 px-sm-5 mt-5">
						<!-- input username -->
						<c-input
							id="username"
							titleAlign="top"
							:title="$t('register.email')"
							:placeholder="$t('register.input_email')"
							v-model="username"
						>
							<template v-slot:prepend>
								<div class="input-group-prepend">
									<div class="input-group-text">
										<img src="@/assets/imgs/user.png" />
									</div>
								</div>
							</template>
						</c-input>
						<!-- input password -->
						<c-input
							class="mt-3"
							:type="inputPwsdType"
							id="password"
							titleAlign="top"
							:title="$t('register.password')"
							:placeholder="$t('register.input_password')"
							v-model="password"
						>
							<template v-slot:prepend>
								<div class="input-group-prepend">
									<div class="input-group-text">
										<img src="@/assets/imgs/lock.png" />
									</div>
								</div>
							</template>
							<template v-slot:append>
								<div class="input-group-append">
									<span
										class="input-group-text bg-transparent"
										@click="togglePassword()"
									>
										<img
											src="@/assets/imgs/eyeOpen.png"
											alt="show/hide"
											class="imgTogglePassword"
										/>
									</span>
								</div>
							</template>
						</c-input>
						<!-- btn next -->
						<div class="pt-4">
							<c-button
								fullWidth
								classes="mt-2"
								:text="$t('register.next_step')"
								:disabled="!username || !password"
								:clicked="clickNext"
							/>
						</div>
						<!-- login -->
						<div class="d-flex justify-content-center mt-2">
							<p class="text-muted">
								<small>{{ $t("register.got_account") }}</small>
							</p>
							<router-link :to="linkLogin">
								{{ $t("register.go_login") }}
							</router-link>
						</div>
					</div>
				</div>
			</div>
			<!-- Fill in the information -->
			<div v-else class="bg-white py-5 px-3 px-sm-0 mb-5">
				<div class="row justify-content-center">
					<div class="col-sm-9 col-md-7">
						<h5 class="text-center font-weight-bold mt-1 mb-4">
							{{ $t("register.fill_in") }}
						</h5>
						<!-- form -->
						<!-- input company_name -->
						<c-input
							id="company_name"
							titleAlign="top"
							:title="$t('register.office')"
							:placeholder="$t('register.input_office')"
							v-model="company_name"
						/>
						<!-- input country -->
						<c-select
							rounded
							class="mt-3"
							titleAlign="top"
							:title="$t('register.country')"
							:placeholder="$t('register.select_country')"
							:list="translate(countries, 'countries')"
							optionKey="text"
							v-model="country"
							:changed="changeCountry"
						/>
						<!-- input address -->
						<c-input
							class="mt-3"
							id="address"
							titleAlign="top"
							:title="$t('register.address')"
							:placeholder="$t('register.input_address')"
							v-model="address"
						/>
						<!-- input phone -->
						<c-input
							type="number"
							class="mt-3"
							id="phone"
							titleAlign="top"
							:title="$t('register.phone')"
							:placeholder="$t('register.input_phone')"
							v-model="phone"
						>
							<template v-slot:prepend>
								<div v-if="country" class="input-group-prepend">
									<span class="input-group-text f14">
										{{ countryCode }}
									</span>
								</div>
							</template>
						</c-input>
						<!-- input telegram -->
						<c-input
							class="mt-3"
							id="telegram"
							titleAlign="top"
							title="Telegram"
							:placeholder="$t('register.input_telegram')"
							v-model="telegram"
						/>
						<!-- TNC -->
						<div class="input-group mt-2">
							<checkbox id="tnc" v-model="tncChecked">
								<template>
									<small class="text-muted">
										{{ $t("register.click_register") }}
										<router-link :to="'/'" target="_blank">
											{{ $t("register.user_agreement") }}
										</router-link>
										{{ $t("register.and") }}
										<router-link :to="'/'" target="_blank">
											{{ $t("register.privacy_policy") }}
										</router-link>
									</small>
								</template>
							</checkbox>
						</div>
						<!-- btn register -->
						<div class="px-0 px-sm-4 px-md-5 mt-5">
							<c-button
								fullWidth
								loading
								:text="$t('register.create_account')"
								:disabled="!tncChecked"
								:clicked="clickRegister"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys, api, helper } from "@/utils";
	import { danger, success } from "@/components/alert/index.js";

	export default {
		name: "register",
		components: {
			cButton: () => import("@/components/button"),
			cSelect: () => import("@/components/select"),
			cInput: () => import("@/components/input"),
			checkbox: () => import("@/components/checkbox"),
		},
		data() {
			return {
				username: "",
				password: "",
				inputPwsdType: "password",
				nextInformation: false,
				company_name: "",
				country: "",
				countries: Object.keys(
					this.$i18n.messages[this.$i18n.locale].countries
				),
				countryCode: "",
				address: "",
				phone: "",
				telegram: "",
				tncChecked: false,
				linkLogin: keys.login,
			};
		},
		methods: {
			togglePassword() {
				this.inputPwsdType =
					this.inputPwsdType == "text" ? "password" : "text";
				$(".imgTogglePassword").attr(
					"src",
					this.inputPwsdType == "text"
						? require("@/assets/imgs/eyeClose.png")
						: require("@/assets/imgs/eyeOpen.png")
				);
			},
			clickNext() {
				if (!this.username || !helper.validateEmail(this.username)) {
					danger(this.$t("invalid_email"));
					return;
				}
				if (this.password.length < 6) {
					danger(this.$t("password_length"));
					return;
				}
				api.verifyAcc({ username: this.username }).then((response) => {
					if (response.code === 200) {
						this.nextInformation = true;
					}
				});
			},
			changeCountry(item) {
				this.countryCode = "+" + keys.countryCodes[item.key];
			},
			clickRegister() {
				const _this = this;
				api.register({
					username: this.username,
					password: this.password,
					company_name: this.company_name,
					country:
						this.$i18n.messages["zh_cn"].countries[
							this.countries[this.country]
						],
					address: this.address,
					phone:
						this.countryCode +
						(this.countryCode.includes("-") ? "" : "-") +
						this.phone,
					telegram: this.telegram,
					qq: "",
				})
					.then((response) => {
						_this.$store.dispatch("setIsLoading", false);
						_this.$store.dispatch("fetchUser", response.data);
						success(_this.$t("register_success"));
						_this.$router.push({
							name: keys.buyLine + keys.buyLinePurchaseSpec,
						});
					})
					.catch((err) => {
						_this.$store.dispatch("setIsLoading", false);
						// user exist, back to input email
						if (err.code === 1130) {
							_this.nextInformation = false;
						}
					});
			},
			translate: helper.translate,
		},
	};
</script>
