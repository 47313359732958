<template>
	<div class="px-2 px-sm-4 mt-4">
		<div class="row">
			<div class="col-12">
				<div
					class="form-group row align-items-center justify-content-center"
				>
					<label class="col-sm-3 col-form-label">
						{{ $tc("order.order_number", 2) }}
					</label>
					<div class="col-8 col-sm-6">
						<input
							id="inputOrderId"
							type="text"
							class="form-control no-disable-style"
							:value="orderId"
							disabled
						/>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-main-light btn-sm"
							@click="copy('inputOrderId')"
						>
							{{ $t("order.copy") }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div
					class="form-group row align-items-center justify-content-center"
				>
					<label class="col-sm-3 col-form-label">
						{{ $t("order.order_amount") }}
					</label>
					<div class="col-8 col-sm-6">
						<input
							id="inputOrderAmount"
							type="text"
							class="form-control no-disable-style color-dark-orange"
							:value="orderAmount"
							disabled
						/>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-main-light btn-sm"
							@click="copy('inputOrderAmount')"
						>
							{{ $t("order.copy") }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div
					class="form-group row align-items-center justify-content-center"
				>
					<label class="col-sm-3 col-form-label">
						{{ $t("order.account_bank") }}
					</label>
					<div class="col-8 col-sm-6">
						<input
							id="inputAccBank"
							type="text"
							class="form-control no-disable-style"
							:value="accBank"
							disabled
						/>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-main-light btn-sm"
							@click="copy('inputAccBank')"
						>
							{{ $t("order.copy") }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div
					class="form-group row align-items-center justify-content-center"
				>
					<label class="col-sm-3 col-form-label">
						{{ $t("order.payee") }}
					</label>
					<div class="col-8 col-sm-6">
						<input
							id="inputPayee"
							type="text"
							class="form-control no-disable-style"
							:value="payee"
							disabled
						/>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-main-light btn-sm"
							@click="copy('inputPayee')"
						>
							{{ $t("order.copy") }}
						</button>
					</div>
				</div>
			</div>
			<div class="col-12">
				<div
					class="form-group row align-items-center justify-content-center"
				>
					<label class="col-sm-3 col-form-label">
						{{ $t("order.receive_account_number") }}
					</label>
					<div class="col-8 col-sm-6">
						<input
							id="inputReceiveAcc"
							type="text"
							class="form-control no-disable-style"
							:value="receiveAcc"
							disabled
						/>
					</div>
					<div class="col-auto">
						<button
							type="button"
							class="btn btn-main-light btn-sm"
							@click="copy('inputReceiveAcc')"
						>
							{{ $t("order.copy") }}
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { helper } from "@/utils";
	import { success, danger } from "@/components/alert/index.js";
	export default {
		name: "transferInfo",
		props: {
			orderId: String,
			orderAmount: String,
			accBank: String,
			payee: String,
			receiveAcc: String,
		},
		data() {
			return {};
		},
		methods: {
			copy(id) {
				if (helper.copyToClipboard(id)) {
					success(this.$t("order.copy_success"));
				} else {
					danger(this.$t("order.unable_copy")); // Oops, unable to copy
				}
			},
		},
	};
</script>
