import Vue from "vue";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import { keys } from "@/utils";
import App from "@/App.vue";

// https://www.npmjs.com/package/skeleton-loader-vue
import VueSkeletonLoader from "skeleton-loader-vue";

import "bootstrap"; // Importing JavaScript
// Import plugins individually as needed:
// import 'bootstrap/js/dist/util';
// import 'bootstrap/js/dist/dropdown';

// router
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters.uid) {
      next({ name: keys.login }); // loginMode
    } else {
      next(); // go to wherever I'm going
    }
  } else if (to.matched.some(record => record.meta.disallowAuth)) {
    // this route disallow auth, check if logged out
    // if not, redirect to dashboard page.
    if (store.getters.uid) {
      next({ name: keys.buyLine + keys.buyLinePurchaseSpec });
    } else {
      // check selected line mode
      if (
        to.matched.some(record => record.meta.requiresLineMode) &&
        !store.getters.lineMode &&
        to.name !== keys.login
      ) {
        // loginMode
        next({ name: keys.login }); // loginMode
      } else {
        next(); // go to wherever I'm going
      }
    }
  } else {
    next(); // does not require auth, make sure to always call next()!
  }
});

// Register the component globally
Vue.component("vue-skeleton-loader", VueSkeletonLoader);
// use globally
Vue.use(require("vue-moment"));

new Vue({
  el: "#app",
  router,
  store,
  i18n,
  components: { App },
  template: "<App/>"
});
