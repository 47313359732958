<template>
	<select
		:id="'select_' + id"
		class="form-control rounded-0"
		:value="Number(value)"
		@input="$emit('input', $event.target.value)"
		@change="onSelectChange($event)"
	>
		<option class="d-none" value="" disabled selected>
			{{ placeholder }}
		</option>
		<option v-for="(opt, idx) in list" :key="idx" :value="idx">
			{{ optionKey ? opt[optionKey] : opt }}
		</option>
	</select>
	<!-- <vue-skeleton-loader
		v-else
		width="100%"
		height="38px"
		style="min-width: 176px"
		:rounded="false"
		:radius="0"
	/> -->
</template>

<script>
	export default {
		name: "tSelect",
		props: {
			id: String,
			value: [String, Number],
			data: Object,
			index: Number,
			column: Object,
			callback: Function,
		},
		computed: {
			list() {
				if (Array.isArray(this.column.rowData)) {
					return this.column.rowData;
				}
				if (
					this.data.operateSelect &&
					Array.isArray(this.data.operateSelect)
				) {
					return this.data.operateSelect;
				}
				return [];
			},
			placeholder() {
				return "";
			},
			optionKey() {
				return this.column.key;
			},
		},
		methods: {
			onSelectChange(e) {
				this.callback(
					{
						...this.list[e.target.value],
						index: Number(e.target.value),
					},
					this.index
				);
			},
		},
	};
</script>
