<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<order-state
				:state="state"
				:isUploadVoucher="isUploadVoucher"
				:noUpload="noUpload"
				:orderDetails="orderDetails"
				:repayRouteName="repayRouteName"
				:repayRouteParams="repayRouteParams"
				:viewRouteName="viewRouteName"
				:viewRouteParams="viewRouteParams"
			/>
			<!-- <template v-else>
				<div class="d-flex justify-content-center mb-3">
					<vue-skeleton-loader width="123px" height="98px" :radius="0" />
				</div>
				<div class="d-flex justify-content-center mb-5">
					<vue-skeleton-loader width="350px" height="38px" :radius="0" />
				</div>
				<div class="d-flex justify-content-center">
					<vue-skeleton-loader width="150px" height="38px" :radius="0" />
				</div>
			</template> -->
		</div>
	</div>
</template>

<script>
	import { keys } from "@/utils";

	export default {
		name: "lineManagementPayment",
		components: {
			orderState: () => import("@/components/flashMessage/order"),
		},
		data() {
			return {
				state: null,
				isUploadVoucher: false,
				noUpload: false,
				orderDetails: "",
				repayRouteName: "",
				repayRouteParams: "",
				viewRouteName: "",
				viewRouteParams: "",
			};
		},
		created() {
			if (!this.$route.params) {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementList,
				});
			} else {
				const {
					state,
					isUploadVoucher,
					noUpload,
					orderDetails,
					repayRouteName,
					repayRouteParams,
					viewRouteName,
					viewRouteParams,
				} = this.$route.params;

				this.state = state;
				this.isUploadVoucher = isUploadVoucher;
				this.noUpload = noUpload;
				this.orderDetails = orderDetails;
				this.repayRouteName = repayRouteName;
				this.repayRouteParams = repayRouteParams;
				this.viewRouteName = viewRouteName || "";
				this.viewRouteParams = viewRouteParams || "";
			}
		},
	};
</script>