<template>
	<div id="modal">
		<div id="myModal" class="modal fade" tabindex="-1" data-backdrop="static">
			<div :class="'modal-dialog modal-dialog-centered ' + classes">
				<div class="modal-content">
					<div class="modal-header">
						<h5 class="modal-title font-weight-bold">{{ title }}</h5>
						<button v-if="closable" type="button" class="close" @click="clickClose">
							<span aria-hidden="true">&times;</span>
						</button>
					</div>
					<div class="modal-body">
						<slot></slot>
					</div>
					<div class="modal-footer">
						<slot name="footer"></slot>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "modal",
		props: {
			closable: {
				type: Boolean,
				default: true,
			},
			classes: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			onClose: {
				type: Function,
				default: null,
			},
		},
		methods: {
			clickClose() {
				const _this = this;
				$("#myModal").modal("hide");
				$("#myModal").on("hidden.bs.modal", function () {
					if (_this.onClose) {
						_this.onClose();
					} else {
						setTimeout(function () {
							document.getElementById("modal").remove();
						}, 300);
					}
				});
			},
		},
		mounted() {
			$("#myModal").modal("show");
		},
	};
</script>

<style scoped>
	.modal-header {
		border-bottom: unset;
		background-color: #dce3ed;
		display: block;
		text-align: center;
		position: relative;
	}
	.modal-header .close {
		position: absolute;
		right: 1rem;
		top: 1.1rem;
	}
</style>