import Vue from "vue";
import i18n from "@/i18n";
Vue.use(require("vue-moment"));

import { FETCH_ORDER } from "@/store/mutations-type";
import { localS, keys, api, helper } from "@/utils";

const defaultState = {
  list: null,
  count: 0
};

const order = {
  state: { ...localS.getJSON(keys.stateOrder, defaultState) },
  getters: {
    orderList: state => state.list,
    orderCount: state => state.count
  },
  mutations: {
    [FETCH_ORDER](state, payload) {
      state.list = payload.list;
      state.count = payload.count;

      localS.setJSON(keys.stateOrder, state);
    }
  },
  actions: {
    fetchOrder({ commit, rootState }, params) {
      commit(FETCH_ORDER, { list: null, count: 0 });

      return new Promise((resolve, reject) => {
        api
          .getOrderList(params)
          .then(response => {
            const { data } = response;
            const paymentList = helper.mergeArrayObject(
              keys.defaultPayMethod,
              rootState.buy.payment_list ? rootState.buy.payment_list : [],
              "payment"
            );

            // type   0:新购.1:续费.2:修改带宽/终端数
            // audit_state   0:待确认.1:已确认
            // deploy_state   0:待配置.1:已配置
            let list = [];
            data.list.forEach(i => {
              list.push({
                ...i,
                typeText: i18n.t(
                  `order.${
                    i.type === 1
                      ? "renew"
                      : i.type === 2
                      ? "edit_bandwidth_device"
                      : "new_purchase"
                  }`
                ),
                amount: "$ " + helper.roundDecPoint(i.total_money / 100),
                paymentMethod:
                  paymentList.find(j => j.payment === i.payment).alias_name ||
                  "",
                paymentStatus: i18n.tc(i.state === 9 ? "paid" : "unpaid", 2),
                createdTime: i.created
                  ? Vue.moment(i.created * 1000).format("YYYY-MM-DD HH:mm:ss")
                  : "",
                status: i18n.t(
                  `order.${
                    params.tab === 1
                      ? "completed"
                      : params.tab === 2
                      ? "to_be_confirm"
                      : "to_be_config"
                  }`
                ), // component table
                statusColor:
                  params.tab === 1
                    ? "gray-light"
                    : params.tab === 2
                    ? "red-light"
                    : "orange-light", // component table
                operateBtns: i.voucher_url
                  ? [0, 6]
                  : i.audit_state === 0
                  ? [0, 5]
                  : [0], // component table
                btnImgUrl: i.voucher_url // component table
              });
            });

            commit(FETCH_ORDER, { list, count: response.count });
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    },
    exportOrder({ commit }, params) {
      api
        .getOrderList(params)
        .then(response => {
          const { data } = response;
          helper.exportToExcel(
            data.list.map(i => {
              i[3] += "M";
              i[4] += "M";
              return i;
            }),
            data.table_head
          );
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};

export default order;
