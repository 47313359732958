<template>
	<qrcode-vue
		:value="value"
		:size="size"
		:level="level"
		:margin="margin"
		:background="background"
		:foreground="foreground"
	/>
</template>
<script>
	import QrcodeVue from "qrcode.vue";

	export default {
		props: {
			value: String,
			size: {
				type: Number,
				default: 100,
			},
			level: {
				type: String,
				default: "H", // 'L' | 'M' | 'Q' | 'H'
			},
			margin: {
				type: Number,
				default: 0,
			},
			background: {
				type: String,
				default: "#ffffff",
			},
			foreground: {
				type: String,
				default: "#000000",
			},
		},
		components: {
			QrcodeVue,
		},
	};
</script>