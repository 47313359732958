<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<tab-bar>
				<tabs
					active
					id="all"
					:title="$t('subAccount.all')"
					:clicked="() => changeTab(1)"
				/>
				<tabs
					id="normal"
					:title="$t('subAccount.normal')"
					:clicked="() => changeTab(2)"
				/>
				<tabs
					id="expire"
					:title="$t('subAccount.expired')"
					:clicked="() => changeTab(3)"
				/>
			</tab-bar>
			<div class="border-right border-bottom border-left p-3">
				<!-- filter -->
				<div class="subacc-filterbar">
					<!-- min-width box -->
					<div class="container-fluid">
						<div class="row align-items-center">
							<div class="col-4">
								<c-input
									id="filter_ip"
									titleClasses="filter-input text-right pr-0"
									:title="$t('subAccount.ip_address')"
									:placeholder="$t('subAccount.input_ip_address')"
									v-model="filterIp"
								/>
							</div>
							<div class="col-4">
								<c-select
									rounded
									titleClasses="filter-input text-right pr-0"
									:title="$t('subAccount.line_name')"
									:placeholder="$t('subAccount.select_line_name')"
									:list="areaList"
									optionKey="area_name"
									v-model="filterAreaName"
								/>
							</div>
						</div>
						<div class="row align-items-center">
							<div class="col-4">
								<c-input
									id="filter_label"
									class="mb-0"
									titleClasses="filter-input text-right pr-0"
									:title="$t('subAccount.label')"
									:placeholder="$t('subAccount.input_label')"
									v-model="filterLabel"
								/>
							</div>
							<div class="col-4">
								<c-select
									rounded
									class="mb-0"
									titleClasses="filter-input text-right pr-0"
									:title="$t('subAccount.bandwidth')"
									:placeholder="$t('subAccount.select_bandwidth')"
									:list="bandwidthList"
									optionKey="bandwidthText"
									v-model="filterBandwidth"
								/>
							</div>
							<c-button
								loading
								class="col"
								classes="float-right px-4 mr-2"
								styles="width: unset; min-width: 105px"
								:clicked="clickSearch"
							>
								<img
									src="@/assets/imgs/search.png"
									alt="search"
									class="pr-1"
									style="margin-top: -3px"
								/>
								{{ $t("subAccount.search") }}
							</c-button>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bg-white p-4 my-2 shadow-sm">
			<div class="row">
				<template v-if="tab !== 3">
					<c-button
						class="col-12 col-sm-auto mb-3 mb-md-0"
						:rounded="false"
						color="main-light"
						classes="px-4"
						:clicked="clickBatchUpdateStatus"
					>
						<img
							src="@/assets/imgs/multipleAdd.png"
							alt="multiple add"
							class="pr-1"
							style="margin-top: -3px"
						/>
						{{ $t("subAccount.batch_enable") }}
					</c-button>
					<c-button
						class="col-12 col-sm-auto mb-3 mb-md-0"
						:rounded="false"
						color="orange"
						classes="px-4"
						:clicked="
							() => {
								clickBatchUpdateStatus(true);
							}
						"
					>
						<img
							src="@/assets/imgs/disable.png"
							alt="disable"
							class="pr-1"
							style="margin-top: -3px"
						/>
						{{ $t("subAccount.batch_disable") }}
					</c-button>
				</template>
				<c-button
					loading
					class="col-12 col-sm-auto mb-3 mb-md-0 ml-sm-auto"
					classes="px-4"
					:clicked="clickExport"
				>
					<img
						src="@/assets/imgs/export.png"
						alt="export"
						class="pr-1"
						style="margin-top: -3px"
					/>
					{{ $t("subAccount.export") }}
				</c-button>
			</div>
			<!-- table -->
			<c-table
				class="mt-3"
				:tableClass="
					subAccList && !subAccList.length
						? 'th-style1 table-hover-non'
						: 'th-style1'
				"
				tableStyle="min-width: 1000px"
				:columns="columns"
				:data="subAccList"
				:limit="limit"
				:rowCounts="count"
				:page="page"
				:toPage="onPageChanged"
			/>
		</div>
		<modal
			v-if="modalShow"
			ref="modal"
			:title="modalTitle"
			classes="modal-lg"
			:onClose="onModalClose"
		>
			<t-edit-password
				v-if="modalCase === 'editPswd'"
				loading
				:oldPassword="false"
				:cancel="clickCloseModal"
				:edit="clickEditPaswd"
			/>
			<t-confirm
				v-if="modalCase === 'editStatus' || modalCase === 'editBatchStatus'"
				loading
				:actionMsg="actionMsg"
				:cancel="clickCloseModal"
				:confirm="clickConfirmChangeStatus"
			/>
		</modal>
	</div>
</template>

<script>
	import { tabBar, tabs } from "@/components/tab";
	import { columnSubAccount } from "@/components/table/data";
	import { keys, api } from "@/utils";
	import { danger, success } from "@/components/alert/index.js";

	export default {
		name: "orderList",
		components: {
			tabBar,
			tabs,
			cSelect: () => import("@/components/select"),
			cInput: () => import("@/components/input"),
			cButton: () => import("@/components/button"),
			cTable: () => import("@/components/table"),
			modal: () => import("@/components/modal"),
			tEditPassword: () => import("@/components/templates/editPassword"),
			tConfirm: () => import("@/components/templates/confirm"),
		},
		data() {
			return {
				tab: 1,
				areaList: [],
				bandwidthList: [],
				// filter
				filterIp: "",
				filterLabel: "",
				filterAreaName: "",
				filterBandwidth: "",
				// table
				page: 1,
				limit: 15,
				columns: columnSubAccount({
					checkFunc: this.checkFunc,
					togglePassword: this.togglePassword,
					operate: this.operate,
				}),
				currentEdit: null,
				batchStatusTo: null,
				// modal
				modalShow: false,
				modalTitle: "",
				modalCase: "",
				actionMsg: "",
			};
		},
		computed: {
			subAccList() {
				return this.$store.getters.subAccList;
			},
			count() {
				return this.$store.getters.subAccCount;
			},
			postages() {
				return this.$store.getters.postages;
			},
		},
		watch: {
			subAccList: function (newArr) {
				if (newArr && newArr.length && this.filterBandwidth === "") {
					let bandwidthList = [];

					newArr.forEach((i) => {
						if (
							bandwidthList.filter((j) => j.bandwidth === i.bandwidth)
								.length <= 0
						) {
							bandwidthList.push({
								bandwidth: i.bandwidth,
								bandwidthText: i.bandwidthText,
							});
						}
					});

					this.bandwidthList = bandwidthList;
				}
			},
		},
		methods: {
			changeTab(tab) {
				this.tab = tab;
				this.page = 1;
				this.fetchSubAccount();
			},
			// filter
			clickSearch() {
				this.fetchSubAccount();
			},
			clickBatchUpdateStatus(disable = false) {
				this.batchStatusTo = disable ? 1 : 0;
				const actionMsg = this.$t(
					`subAccount.${disable ? "batch_disable" : "batch_enable"}`
				);
				if (!this.columns[0].checked.length) {
					danger(this.$t("subAccount.select_action_account", [actionMsg]));
				} else {
					this.modalShow = true;
					this.modalTitle = this.$t("subAccount.confirm");
					this.modalCase = "editBatchStatus";
					this.actionMsg =
						actionMsg +
						this.columns[0].checked.map((i, index) => {
							let uname = this.subAccList.find((j) => j.uid === i).username;
							return " " + uname;
						}) +
						" ";
				}
			},
			clickExport() {
				this.fetchSubAccount({
					uid: this.columns[0].checked,
					export: 1,
				});
			},
			// table
			checkFunc(checked, columnIdx, id) {
				let columns = [...this.columns];
				if (id) {
					columns[columnIdx].checked = columns[columnIdx].checked || []; // set default to array
					if (checked) {
						columns[columnIdx].checked.push(id);
					} else {
						columns[columnIdx].checked = columns[columnIdx].checked.filter(
							(i) => i !== id
						);
					}
				} else {
					if (checked) {
						columns[columnIdx].checked = this.subAccList.map((i) => i.uid);
					} else {
						columns[columnIdx].checked = [];
					}
				}
				this.columns = columns;
			},
			togglePassword(idx) {
				let columns = [...this.columns];

				columns[idx].toggle = columns[idx].toggle || "";
				columns[idx].toggle =
					columns[idx].toggle === "text" ? "password" : "text";

				this.columns = columns;
			},
			operate(idx, params) {
				switch (idx) {
					case 3:
						this.currentEdit = params;
						this.modalShow = true;
						this.modalTitle = this.$t("subAccount.change_password");
						this.modalCase = "editPswd";
						break;

					default:
						this.currentEdit = params;
						this.modalShow = true;
						this.modalTitle = this.$t("subAccount.confirm");
						this.modalCase = "editStatus";
						this.actionMsg =
							this.$t(`subAccount.${params.state ? "enable" : "disable"}`) +
							" " +
							params.username +
							" ";
						break;
				}
			},
			onCheckChanged(list) {
				this.checkedList = list;
			},
			onPageChanged(page) {
				this.page = page;
				this.fetchSubAccount();
			},
			viewDetails(i) {
				this.$router.push({
					name: keys.order + keys.orderDetails,
					params: { data: i },
				});
			},
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.modalShow = false;
				this.modalTitle = "";
				this.modalCase = "";
				this.actionMsg = "";
				//
				this.currentEdit = null;
				this.checkedList = [];
				this.batchStatusTo = null;
				this.$store.dispatch("setIsLoading", false);
			},
			clickEditPaswd(params) {
				api.patchCompanyUserInfo({
					uid: this.currentEdit.uid,
					password: params.newPassword,
					// uid[] password state
				})
					.then(() => {
						this.clickCloseModal();
						this.fetchSubAccount();
						success(
							this.$t("edit_success", [this.$t("subAccount.password")])
						);
					})
					.catch(() => {
						this.clickCloseModal();
					});
			},
			clickConfirmChangeStatus() {
				const toState =
					this.modalCase === "editBatchStatus"
						? this.batchStatusTo
						: this.currentEdit.state
						? 0
						: 1;

				api.patchCompanyUserInfo({
					uid:
						this.modalCase === "editBatchStatus"
							? this.checkedList.join(",")
							: this.currentEdit.uid,
					state: toState,
					// uid[] password state
				})
					.then(() => {
						this.clickCloseModal();
						this.fetchSubAccount();
						success(
							this.$t("action_success", [
								this.$t(`subAccount.${toState ? "disable" : "enable"}`),
							])
						);
					})
					.catch(() => {
						this.clickCloseModal();
					});
			},
			//
			fetchSubAccount(othersParams = {}) {
				let searchParams = {
					ip: this.filterIp,
					label: this.filterLabel,
					postage_id:
						this.filterAreaName !== ""
							? this.areaList[this.filterAreaName].id
							: "",
					bandwidth:
						this.filterBandwidth !== ""
							? this.bandwidthList[this.filterBandwidth].bandwidth
							: "",
				};

				Object.keys(searchParams).forEach((key) => {
					if (!searchParams[key]) {
						delete searchParams[key];
					}
				});

				let params = {
					page: this.page,
					limit: this.limit,
					tab: this.tab,
					searchParams: Object.keys(searchParams).length
						? JSON.stringify(searchParams)
						: "", // object to string
					...othersParams,
					// page limit uid export tab searchParams ip label created username
				};
				if (!params.searchParams) {
					delete params.searchParams;
				}
				this.$store
					.dispatch(
						"export" in params ? "exportSubAccount" : "fetchSubAccount",
						params
					)
					.then(() => {
						this.$store.dispatch("setIsLoading", false);
					})
					.catch(() => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
		},
		created() {
			this.$store
				.dispatch("fetchPostage")
				.then(() => {
					if (this.postages && this.postages.length) {
						const areaList = [];
						this.postages.forEach((i) => {
							if (
								areaList.filter((j) => j.area_name === i.area_name)
									.length <= 0
							) {
								areaList.push({
									area_name: i.area_name,
									area_name_en: i.area_name_en,
									id: i.id,
								});
							} else {
								const indexArea = areaList.findIndex(
									(j) => j.area_name === i.area_name
								);
								areaList[indexArea].id = [...areaList[indexArea].id, i.id];
							}
						});
						this.areaList = areaList;
					}
				})
				.catch(() => {
					this.areaList = [];
				});

			this.fetchSubAccount();
		},
	};
</script>
