<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<h5>
				<small class="font-weight-bold">
					{{ $t("order.basic_info") }}
				</small>
			</h5>
			<div class="row bg-lighter-gray color-darker-gray px-1 py-3 mx-0">
				<div class="col-12 col-sm-3 col-md-2 py-1">
					{{ $tc("order.order_number", 1) }}
				</div>
				<div class="col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0 text-break">
					{{ info ? info.parent_order_id : "-" }}
				</div>
				<div class="col-12 col-sm-3 col-md-2 py-1">
					{{ $tc("order.order_type", 1) }}
				</div>
				<div class="col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0">
					{{
						$t(
							`order.${
								info
									? info.type === 1
										? "renew"
										: info.type === 2
										? "edit_bandwidth_device"
										: "new_purchase"
									: "-"
							}`
						)
					}}
				</div>
				<div class="col-12 col-sm-3 col-md-2 py-1">
					{{ $tc("order.creation_time", 1) }}
				</div>
				<div class="col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0">{{ info ? info.createdText : "-" }}</div>
				<div class="col-12 col-sm-3 col-md-2 py-1">
					{{ $t("order.order_status") }}
				</div>
				<div class="col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0 color-main">
					{{
						info
							? $t(
									`order.${
										info.audit_state ? "confirmed" : "to_be_confirm"
									}`
							  )
							: "-"
					}}
				</div>
				<div class="col-12 col-sm-3 col-md-2 py-1">{{ $t("order.amount") }}</div>
				<div class="col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0">$ {{ info ? info.totalMoney : "-" }}</div>
			</div>
			<h5 class="mt-4">
				<small class="font-weight-bold">
					{{ $t("order.include_line_list") }}
				</small>
			</h5>
			<c-table
				:tableClass="
					rows && !rows.length ? 'th-style1 table-hover-non' : 'th-style1'
				"
				tableStyle="min-width: 750px"
				:columns="columns"
				:data="rows"
			></c-table>
		</div>
	</div>
</template>

<script>
	import { keys, api, helper } from "@/utils";
	import { columnOrderDetails } from "@/components/table/data";

	export default {
		name: "orderDetails",
		components: {
			cTable: () => import("@/components/table"),
		},
		data() {
			return {
				info: null,
				columns: columnOrderDetails(),
				rows: null,
				count: 0,
			};
		},
		created() {
			if (!this.$route.params.data) {
				this.$router.push({ name: keys.order + keys.orderList });
			} else {
				this.info = {
					...this.$route.params.data,
					createdText: this.$route.params.data.created
						? this.$moment(this.$route.params.data.created * 1000).format(
								"YYYY-MM-DD HH:mm:ss"
						  )
						: "",
					totalMoney: helper.roundDecPoint(
						this.$route.params.data.total_money / 100
					),
				};

				api.getOrderDetail({
					parent_order_id: this.$route.params.data.parent_order_id,
				}).then((response) => {
					if (
						response.data &&
						response.data.list &&
						response.data.list.length
					) {
						let newrows = [];
						response.data.list.forEach((i) => {
							// format days to text
							let formated = helper.getFormatedStringFromDays(i.day);
							formated = formated.filter((j) => !!j.number);
							// get status
							const currentDate = this.$moment();
							const expireDate = this.$moment(i.expire_time * 1000);
							const isExpired = expireDate.diff(currentDate, "days") < 0;

							newrows.push({
								...i,
								bandwidthText:
									(i.old_bandwidth
										? i.old_bandwidth +
										  "M" +
										  helper.decodeHTMLEntities(
												"<span class='color-main mx-1'>&rarr;</span>"
										  )
										: "") +
									i.bandwidth +
									"M",
								deviceNum:
									(i.old_device_num
										? " " +
										  i.old_device_num +
										  " " +
										  helper.decodeHTMLEntities(
												"<span class='color-main'>&rarr;</span>"
										  )
										: "") + i.device_num,
								month: formated.length
									? this.$t(formated[0].i18n, [formated[0].number])
									: "0",
								amount: "$ " + helper.roundDecPoint(i.money / 100),
								status: this.$t(
									`order.${
										isExpired
											? "expired"
											: i.route_state === 1
											? "in_use"
											: i.route_state === 2
											? "paused"
											: "to_be_config"
									}`
								),
								statusColor: isExpired
									? "gray-light"
									: i.route_state === 1
									? "main-light"
									: i.route_state === 2
									? "orange-light"
									: "orange-light",
							});
						});
						this.rows = newrows;
						this.count = response.count;
					} else {
						this.rows = [];
					}
				});
			}
		},
	};
</script>