<template>
	<div>
		<div class="row">
			<div v-for="(item, index) in list" :key="index" class="col-auto mb-2">
				<div
					:class="
						'bg-white border position-relative' +
						(index === value ? ' border-main' : '')
					"
					style="min-width: 192px"
					@click="clicked(index)"
				>
					<!-- icon and text -->
					<div class="text-center py-2 mx-2">
						<img
							v-if="getImg(item[keyImg])"
							:src="getImg(item[keyImg])"
							:alt="item[keyText]"
							style="max-height: 24px"
						/>
						<span
							class="user-select-none align-middle ml-1"
							style="font-size: 16px"
						>
							{{ item[keyText] }}
						</span>
					</div>
					<!-- border blue box white tick -->
					<img
						v-show="index === value"
						src="@/assets/imgs/rightBottomBlueTick.png"
						alt="white tick"
						class="position-absolute"
						style="bottom: -1px; right: -1px"
					/>
				</div>
			</div>
		</div>
		<!-- <div v-else class="d-flex">
			<vue-skeleton-loader
				v-for="index in listLength"
				:key="index"
				width="192px"
				height="40px"
				style="margin-right: 30px"
			/>
		</div> -->
	</div>
</template>

<script>
	export default {
		name: "paymentMethod",
		props: {
			list: {
				type: Array,
				default: [],
			},
			listLength: {
				type: Number,
				default: 4,
			},
			keyImg: {
				type: String,
				default: "",
			},
			keyText: {
				type: String,
				default: "",
			},
			defaultSelect: {
				type: [Number, String],
				default: "",
			},
			onClicked: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				value: this.defaultSelect,
			};
		},
		methods: {
			getImg(imgName) {
				let src = "";
				if(imgName.indexOf("ALIPAY")>-1){
					imgName = "ALIPAY";
				}else if(imgName.indexOf("UNIONPAY")>-1){
					imgName = "UNIONPAY";
				}
				try {
					src = require(`@/assets/imgs/${imgName}.png`);
				} catch (error) {}
				return src;
			},
			clicked(index) {
				this.value = index;
				this.onClicked(this.list[index], index);
			},
		},
	};
</script>
