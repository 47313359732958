<template>
	<div class="container-fluid">
		<!-- margin-bottom for footer total price -->
		<div
			class="bg-white p-4 shadow-sm"
			style="margin-top: 0.5rem; margin-bottom: 100px"
		>
			<div class="row mt-3">
				<div class="col-auto" style="width: 150px">
					<p class="f14 font-weight-bold">
						{{ $t("lineManagement.unify_edit_bandwidth") }}
					</p>
				</div>
				<div class="col-sm-10">
					<c-select
						rounded
						styles="max-width: 250px"
						placeholder="请选择带宽"
						:list="bandwidthList"
						optionKey="bandwidthText"
						v-model="bandwidth"
						:changed="batchChangeBandwidth"
					/>
					<small class="form-text color-orange">
						{{ $t("lineManagement.unify_edit_bandwidth_desc") }}
					</small>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-auto" style="width: 150px">
					<p class="f14 font-weight-bold">
						{{ $t("lineManagement.pending_order") }}
					</p>
				</div>
				<div class="col-sm-10">
					<c-table
						tableClass="th-style2  table-hover-non"
						tableStyle="min-width: 900px"
						:columns="columns"
						:data="rows"
					/>
				</div>
			</div>
			<div class="row mt-4 mb-3">
				<div class="col-auto" style="width: 150px">
					<p class="f14 font-weight-bold">
						{{ $t("lineManagement.payment_method") }}
					</p>
				</div>
				<div class="col-sm-10">
					<payment-method
						:list="paymentList"
						keyImg="payment"
						keyText="alias_name"
						:defaultSelect="paymentMethodIndex"
						:onClicked="clickSelectPayment"
					/>
				</div>
			</div>
			<!-- bottom price -->
			<div class="fixed-bottom dashboard-bottom-bar">
				<div
					class="
						d-flex
						justify-content-center justify-content-sm-end
						p-2 p-sm-4
					"
				>
					<div class="d-flex align-items-center">
						<p class="f14 mb-0 mr-1 user-select-none">
							{{ $t("lineManagement.current_config_price") }}
						</p>
						<p class="f14 mb-0 mr-1 user-select-none color-dark-orange">
							$
						</p>
						<h5
							class="
								f24
								mb-0
								mr-3
								user-select-none
								color-dark-orange
								font-weight-bold
							"
						>
							{{ price }}
						</h5>
					</div>
					<c-button
						loading
						classes="px-4 px-sm-5"
						:text="$t('lineManagement.settlement')"
						:disabled="!payment || !clickable"
						:clicked="clickSettle"
					/>
				</div>
			</div>
		</div>
		<payment-modal
			v-if="modalShow"
			:modalTitle="$t('buyLine.transfer_info')"
			:payment="payment"
			:transferInfo="{ ...transferInfo }"
			:callback="paymentModalCallback"
		/>
	</div>
</template>

<script>
	import { keys, api, helper } from "@/utils";
	import { columnModifyBandwidth } from "@/components/table/data";
	import { danger } from "@/components/alert/index.js";

	export default {
		name: "lineManagementModifyBandwidth",
		components: {
			cSelect: () => import("@/components/select"),
			cTable: () => import("@/components/table"),
			paymentMethod: () => import("@/components/button/paymentMethod"),
			cButton: () => import("@/components/button"),
			paymentModal: () => import("@/components/templates/paymentModal"),
		},
		data() {
			return {
				bandwidthObj: {},
				bandwidthList: [],
				bandwidth: null,
				columns: columnModifyBandwidth({
					changeBandwidth: this.onChangedBandwidth,
					changeDeviceNum: this.onChangedDeviceNum,
				}),
				rows: null,
				paymentMethodIndex: "",
				payment: "",
				price: 0,
				// modal
				modalShow: false,
				transferInfo: null,
				// btn
				clickable: false,
			};
		},
		computed: {
			modifyList() {
				return this.$store.getters.buyList;
			},
			paymentList() {
				return this.$store.getters.paymentList;
			},
			postageList() {
				return this.$store.getters.postageList;
			},
			deviceUnitPrice() {
				return this.$store.getters.deviceUnitPrice;
			},
		},
		methods: {
			// batch
			batchChangeBandwidth(parmas, idx) {
				let rows = [];
				let item = {};
				let amount = 0;
				this.rows.forEach((j) => {
					const bwByBandwidth = this.bandwidthObj[j.area_name].find(
						(k) => k.bandwidth === parmas.bandwidth
					);

					if (bwByBandwidth) {
						item = {
							...j,
							bandwidthTo: idx,
							postage_id: bwByBandwidth.id, // update id for api
							area_name: bwByBandwidth.area_name,
							bandwidth: bwByBandwidth.bandwidth,
							flag_name: bwByBandwidth.flag_name,
							max_device_num: bwByBandwidth.max_device_num,
							max: bwByBandwidth.max_device_num, // for component counter
							moneyTo: bwByBandwidth.money,
						};

						if (item.deviceNumTo > bwByBandwidth.max_device_num) {
							item.deviceNumTo = bwByBandwidth.max_device_num;
						}

						amount = this.calcPrice(item);
						item.amount = amount;
						item.amountText = "$ " + amount;
					} else {
						item = { ...j };
					}

					rows.push(item);
				});
				this.rows = rows;
				//
				this.calcTotalPrice();
			},
			// modify
			onChangedBandwidth(params, idx) {
				// clear selected
				this.bandwidth = null;
				//
				let rows = [...this.rows];
				rows[idx].postage_id = params.id; // update id for api
				rows[idx].bandwidthTo = params.index; //
				rows[idx].area_name = params.area_name; // display
				rows[idx].bandwidth = params.bandwidth; //
				rows[idx].flag_name = params.flag_name; //
				rows[idx].max_device_num = params.max_device_num; // calculate
				rows[idx].max = params.max_device_num; // for component counter
				rows[idx].moneyTo = params.money; // calculate

				if (rows[idx].deviceNumTo > params.max_device_num) {
					rows[idx].deviceNumTo = params.max_device_num;
				}

				const amount = this.calcPrice(rows[idx]);
				rows[idx].amount = amount;
				rows[idx].amountText = "$ " + amount;
				this.rows = rows;
				//
				this.calcTotalPrice();
			},
			onChangedDeviceNum(value, idx) {
				let rows = [...this.rows];

				rows[idx].deviceNumTo =
					value > rows[idx].max_device_num ? rows[idx].max_device_num : value;

				const amount = this.calcPrice(rows[idx]);
				rows[idx].amount = amount;
				rows[idx].amountText = "$ " + amount;
				this.rows = rows;

				this.calcTotalPrice();
			},
			// 计算价格
			calcPrice(line) {
				let bandwidthTo =
					this.bandwidthObj[line.area_name][line.bandwidthTo].bandwidth;
				if (
					bandwidthTo > line.bandwidthDefault ||
					line.deviceNumTo > line.device_num
				) {
					// format = ((nowBandwidthPrice - orginBandwidthPrice) / 30) * overDay + devicePrice * (nowDeviceNum-oldDeviceNum) * (float64(overDay)/30)
					let daysLeft = parseInt(
						Math.ceil(
							(line.expire_time - this.$moment().valueOf() / 1000) / 86400
						)
					);

					// bandwidth
					let bandwidthPrice = 0;
					if (bandwidthTo > line.bandwidthDefault) {
						const newBandwidthPrice = line.moneyTo || 0;
						bandwidthPrice = (newBandwidthPrice - line.money) / 30;
						bandwidthPrice *= daysLeft;
						bandwidthPrice = bandwidthPrice < 0 ? 0 : bandwidthPrice;
					}

					// device
					const deviceNumTo =
						line.deviceNumTo > line.device_num
							? line.deviceNumTo
							: line.device_num;
					let devicePrice = this.deviceUnitPrice;
					devicePrice *= deviceNumTo - line.device_num;
					devicePrice *= parseFloat(daysLeft) / 30;

					const total = helper.roundDecPoint(
						(bandwidthPrice + devicePrice) / 100
					);
					return total < 0 ? 0 : total;
				} else {
					return 0;
				}
			},
			calcTotalPrice() {
				if (this.rows.length) {
					let total = 0;
					let clickable = false;
					this.rows.forEach((i) => {
						total += i.amount;
						if (
							this.bandwidthObj[i.area_name][i.bandwidthTo].bandwidth !==
								i.bandwidthDefault ||
							i.deviceNumTo !== i.device_num
						) {
							clickable = true;
						}
					});

					this.price = helper.roundDecPoint(total);
					this.clickable = clickable;
				}
			},
			clickSelectPayment(item, idx) {
				this.paymentMethodIndex = idx;
				this.payment = item.payment;
			},
			// pay
			clickSettle() {
				if (!this.payment) {
					danger(this.$t("lineManagement.select_payment_method"));
					this.$store.dispatch("setIsLoading", false);
					return;
				}

				let list = [];
				this.rows.forEach((i) => {
					list.push({
						id: i.id,
						flag_name: i.flag_name,
						number: i.number, // for new buy only
						bandwidth: this.bandwidthObj[i.area_name][i.bandwidthTo].bandwidth,
						device_num: i.deviceNumTo,
						// day: 0, // for new buy and renew
						postage_id: i.postage_id, // for new buy only
						money: parseInt(i.amount * 100), // for api
					});
				});

				// post order
				api.postOrderPay({
					type: 2,
					payment: this.payment,
					money: parseInt(this.price * 100), // for api
					list,
				})
					.then((response) => {
						const { data } = response;
						if (data.link_url !== undefined || data.bank_info) {
							if (
								this.payment === "ALIPAY" ||
								this.payment === "OFFLINEPAY"
							) {
								const price = data.total_origin_money;
								this.transferInfo = {
									...data,
									price: helper.roundDecPoint(price / 100),
									totalMoney: helper.roundDecPoint(
										data.total_money / 100
									),
								};

								if (data.link_url === "" && data.total_money === 0) {
									// direct to success page
									this.paymentModalCallback();
								} else {
									// force loading
									this.$store.dispatch("setIsLoading", false);
									// show modal
									this.modalShow = true;
								}
							} else {
								this.$store.dispatch("setIsLoading", false);
								window.open(data.link_url, "_self");
							}
						} else {
							// force loading
							this.$store.dispatch("setIsLoading", false);
							danger(this.$t("please_try_again"));
						}
					})
					.catch((err) => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
			// modal
			paymentModalCallback(params = {}) {
				if (params.closeModal) {
					this.modalShow = false;
				} else {
					this.$store.dispatch("setIsLoading", true);

					// check payment is success
					api.getOrderList({
						page: 1, // int
						limit: 15, // int  defaut:15
						// tab: 2, // to be comfirm
						searchParams: JSON.stringify({
							parent_order_id: this.transferInfo.parent_order_id,
						}),
					})
						.then((response) => {
							this.$store.dispatch("setIsLoading", false);

							if (params.clickCloseModal) {
								params.clickCloseModal();
							}

							const { data } = response;
							let routeName =
								keys.lineManagement + keys.lineManagementModifyBandwidth;
							let state = true;

							if (params.noUpload) {
								routeName += keys.lineManagementPaymentVNotUploaded;
								state = false;
							} else if (params.isUploadVoucher) {
								routeName += keys.lineManagementPaymentVUploaded;
							} else if (
								data.list.length &&
								data.list[0].parent_order_id ===
									this.transferInfo.parent_order_id &&
								data.list[0].state === 9
							) {
								routeName += keys.lineManagementPaymentS;
							} else {
								routeName += keys.lineManagementPaymentF;
								state = false;
							}

							// to status page
							this.$router.push({
								name: routeName,
								params: {
									state,
									isUploadVoucher: params.isUploadVoucher
										? params.isUploadVoucher
										: false,
									noUpload: params.noUpload,
									orderDetails: data.list[0],
									repayRouteName:
										keys.lineManagement +
										keys.lineManagementModifyBandwidth,
									repayRouteParams: {
										id: this.$route.params.id,
										rows: this.rows,
										paymentIdx: this.paymentMethodIndex,
										...this.transferInfo,
									},
								},
							});
						})
						.catch(() => {
							this.$store.dispatch("setIsLoading", false);
							danger(this.$t("please_try_again"));
						});
				}
			},
		},
		created() {
			if (!this.$route.params.id) {
				this.$router.push({
					name: keys.lineManagement + keys.lineManagementList,
				});
			} else {
				const { id, rows, paymentIdx, ...res } = this.$route.params;

				if (rows) {
					this.paymentMethodIndex = paymentIdx;
					this.transferInfo = { ...res };
				}

				// get modify info
				this.$store
					.dispatch("fetchBuyInfo", { id: this.$route.params.id, type: 2 })
					.then(() => {
						// create bandwidth list
						let bandwidthObj = {};
						let bandwidthList = [];
						this.postageList.forEach((i) => {
							// arrange by area name
							if (
								!bandwidthObj[i.area_name] ||
								bandwidthObj[i.area_name].filter(
									(j) =>
										j.flag_name === i.flag_name &&
										j.area_name === i.area_name
								)
							) {
								// set array
								bandwidthObj[i.area_name] =
									bandwidthObj[i.area_name] || [];
								// push
								bandwidthObj[i.area_name].push({
									...i,
									bandwidthTo: i.bandwidthText,
								});
							}

							// filter list for bandwidth select batch change
							if (
								bandwidthList.filter((j) => j.bandwidth === i.bandwidth)
									.length <= 0
							) {
								bandwidthList.push({
									...i,
									bandwidthTo: i.bandwidthText,
								});
							}
						});
						this.bandwidthObj = bandwidthObj; // for batch change
						this.bandwidthList = bandwidthList;

						if (rows) {
							this.payment = this.paymentList[paymentIdx].payment;
							this.rows = rows;

							this.modalShow = true;
						} else {
							let newRows = [];
							this.modifyList.forEach((i) => {
								// get bandwidth data by id
								const bwIndexById = this.postageList.findIndex(
									(j) => j.id === i.postage_id
								);
								// find index in operateSelect list
								const bwIndexInOp = bandwidthObj[
									this.postageList[bwIndexById].area_name
								].findIndex((j) => j.id === i.postage_id);

								let rowObj = {
									...i,
									bandwidthDefault: i.bandwidth, // for calculate
									bandwidthText: i.bandwidth + "M",
									bandwidthTo: bwIndexInOp,
									max_device_num:
										this.postageList[bwIndexById].max_device_num,
									area_name: this.postageList[bwIndexById].area_name, // update data
									max: this.postageList[bwIndexById].max_device_num, // for component counter
									deviceNumTo: i.device_num,
									daysLeft: Math.ceil(
										this.$moment(i.expire_time * 1000).diff(
											this.$moment(),
											"days",
											true
										)
									),
									operateSelect:
										bandwidthObj[
											this.postageList[bwIndexById].area_name
										], // for component select
								};

								// calculate price
								const amount = this.calcPrice(rowObj);
								rowObj.amount = amount;
								rowObj.amountText = "$ " + amount;

								newRows.push(rowObj);
							});
							this.rows = newRows;
						}

						this.calcTotalPrice();
					});
			}
		},
	};
</script>