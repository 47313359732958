// sys
export const SET_LINEMODE = "SET_LINEMODE";
export const SET_LOADING = "SET_LOADING";
export const SET_NOTIFICATIONS = "SET_NOTIFICATIONS";
export const FETCH_POSTAGE = "FETCH_POSTAGE";

// user
export const FETCH_USER = "FETCH_USER";
export const FETCH_USER_INFO = "FETCH_USER_INFO";
export const FETCH_USER_LOGIN_DEVICE = "FETCH_USER_LOGIN_DEVICE";

// buy
export const FETCH_BUY = "FETCH_BUY";
export const FETCH_PLACEORDER = "FETCH_PLACEORDER";

// line
export const FETCH_LINE = "FETCH_LINE";

// order
export const FETCH_ORDER = "FETCH_ORDER";

// sub account
export const FETCH_SUBACC = "FETCH_SUBACC";
