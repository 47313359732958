import { keys } from "@/utils";

export const routes = [
  {
    path: "/",
    name: keys.login, // loginMode,
    component: () =>
      import(/* webpackChunkName: "home" */ `@/pages/${keys.login}`),
    meta: { disallowAuth: true }
  },
  // {
  //   path: `/${keys.login}`,
  //   name: keys.login,
  //   component: () =>
  //     import(/* webpackChunkName: "login" */ `@/pages/${keys.login}`)
  // },
  {
    path: `/${keys.register}`,
    name: keys.register,
    component: () =>
      import(/* webpackChunkName: "register" */ `@/pages/${keys.register}`)
  },
  {
    path: `/${keys.forgetPassword}`,
    name: keys.forgetPassword,
    component: () =>
      import(
        /* webpackChunkName: "forgetPassword" */ `@/pages/${keys.forgetPassword}`
      )
  },
  {
    path: `/${keys.resetPassword}`,
    name: keys.resetPassword,
    component: () =>
      import(
        /* webpackChunkName: "resetPassword" */ `@/pages/${keys.resetPassword}`
      )
  },
  // dashboard
  {
    path: `/${keys.buyLine}`,
    component: () =>
      import(/* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}`),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: keys.buyLine + keys.buyLinePurchaseSpec,
        component: () =>
          import(
            /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/${keys.buyLinePurchaseSpec}`
          ),
        meta: { requiresAuth: true }
      },
      {
        path: keys.buyLinePayOrder,
        component: () =>
          import(/* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}`),
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: keys.buyLine + keys.buyLinePayOrder,
            component: () =>
              import(
                /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/${keys.buyLinePayOrder}`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.buyLineOrderS,
            name: keys.buyLine + keys.buyLinePayOrder + keys.buyLineOrderS,
            component: () =>
              import(
                /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/orderState`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.buyLineOrderF,
            name: keys.buyLine + keys.buyLinePayOrder + keys.buyLineOrderF,
            component: () =>
              import(
                /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/orderState`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.buyLineOrderVUploaded,
            name:
              keys.buyLine + keys.buyLinePayOrder + keys.buyLineOrderVUploaded,
            component: () =>
              import(
                /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/orderState`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.buyLineOrderVNotUploaded,
            name:
              keys.buyLine + keys.buyLinePayOrder + keys.buyLineOrderVNotUploaded,
            component: () =>
              import(
                /* webpackChunkName: "buyLine" */ `@/pages/${keys.buyLine}/orderState`
              ),
            meta: { requiresAuth: true }
          }
        ]
      }
    ]
  },
  {
    path: `/${keys.lineManagement}`,
    component: () =>
      import(
        /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}`
      ),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: keys.lineManagement + keys.lineManagementList,
        component: () =>
          import(
            /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/${keys.lineManagementList}`
          ),
        meta: { requiresAuth: true }
      },
      {
        path: keys.lineManagementRenew,
        component: () =>
          import(
            /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}`
          ),
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: keys.lineManagement + keys.lineManagementRenew,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/${keys.lineManagementRenew}`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentS,
            name:
              keys.lineManagement +
              keys.lineManagementRenew +
              keys.lineManagementPaymentS,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentF,
            name:
              keys.lineManagement +
              keys.lineManagementRenew +
              keys.lineManagementPaymentF,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentVUploaded,
            name:
              keys.lineManagement +
              keys.lineManagementRenew +
              keys.lineManagementPaymentVUploaded,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentVNotUploaded,
            name:
              keys.lineManagement +
              keys.lineManagementRenew +
              keys.lineManagementPaymentVNotUploaded,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: keys.lineManagementModifyBandwidth,
        component: () =>
          import(
            /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}`
          ),
        meta: { requiresAuth: true },
        children: [
          {
            path: "",
            name: keys.lineManagement + keys.lineManagementModifyBandwidth,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/${keys.lineManagementModifyBandwidth}`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentS,
            name:
              keys.lineManagement +
              keys.lineManagementModifyBandwidth +
              keys.lineManagementPaymentS,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentF,
            name:
              keys.lineManagement +
              keys.lineManagementModifyBandwidth +
              keys.lineManagementPaymentF,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentVUploaded,
            name:
              keys.lineManagement +
              keys.lineManagementModifyBandwidth +
              keys.lineManagementPaymentVUploaded,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          },
          {
            path: keys.lineManagementPaymentVNotUploaded,
            name:
              keys.lineManagement +
              keys.lineManagementModifyBandwidth +
              keys.lineManagementPaymentVNotUploaded,
            component: () =>
              import(
                /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/payment`
              ),
            meta: { requiresAuth: true }
          }
        ]
      },
      {
        path: keys.lineManagementDetails,
        name: keys.lineManagement + keys.lineManagementDetails,
        component: () =>
          import(
            /* webpackChunkName: "lineManagement" */ `@/pages/${keys.lineManagement}/${keys.lineManagementDetails}`
          ),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: `/${keys.order}`,
    component: () =>
      import(/* webpackChunkName: "order" */ `@/pages/${keys.order}`),
    meta: { requiresAuth: true },
    children: [
      {
        path: "",
        name: keys.order + keys.orderList,
        component: () =>
          import(
            /* webpackChunkName: "order" */ `@/pages/${keys.order}/${keys.orderList}`
          ),
        meta: { requiresAuth: true }
      },
      {
        path: keys.orderDetails,
        name: keys.order + keys.orderDetails,
        component: () =>
          import(
            /* webpackChunkName: "order" */ `@/pages/${keys.order}/${keys.orderDetails}`
          ),
        meta: { requiresAuth: true }
      }
    ]
  },
  {
    path: `/${keys.account}`,
    name: keys.account,
    component: () =>
      import(/* webpackChunkName: "account" */ `@/pages/${keys.account}`),
    meta: { requiresAuth: true }
  },
  {
    path: `/${keys.subAccount}`,
    name: keys.subAccount,
    component: () =>
      import(/* webpackChunkName: "subAccount" */ `@/pages/${keys.subAccount}`),
    meta: { requiresAuth: true }
  },
  {
    path: "*", // 404
    redirect: "/"
  }
];

export const getSitemapPath = () => {
  let paths = [];
  const d = new Date();
  const lastMod =
    d.getFullYear() +
    "-" +
    (d.getMonth() < 10 ? "0" : "") +
    (d.getMonth() + 1) +
    "-" +
    (d.getDate() < 10 ? "0" : "") +
    d.getDate();

  routes.map(item => {
    paths.push({
      path: item.path,
      lastMod
    });

    if (item.children) {
      item.children.forEach(itemChild => {
        paths.push({
          path: item.path + "/" + itemChild.path,
          lastMod
        });
      });
    }
  });

  return paths;
};
