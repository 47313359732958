<template>
	<button
		class="btn btn-sm p-0"
		style="width: unset"
		@click="callback(data, index)"
	>
		<img
			:src="require(`@/assets/imgs/switch${value ? 'On' : 'Off'}.png`)"
			alt="switch"
		/>
	</button>
</template>

<script>
	export default {
		name: "tSwitch",
		props: {
			value: [Number, Boolean],
			index: Number,
			data: Object,
			column: Object,
			callback: Function,
		},
	};
</script>
