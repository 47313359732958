<template>
	<div :class="'tab-pane fade p-3' + (active ? ' show active' : '')" :id="id">
		<slot />
	</div>
</template>

<script>
	export default {
		name: "tabPane",
		props: {
			id: {
				type: String,
				default: "tabs1",
			},
			active: {
				type: Boolean,
				default: false,
			},
		},
	};
</script>
