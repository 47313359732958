import Vue from "vue";
import i18n from "@/i18n";
Vue.use(require("vue-moment"));

import { FETCH_LINE } from "@/store/mutations-type";
import { localS, keys, api, helper } from "@/utils";

const defaultState = {
  area_list: null,
  list: null,
  count: 0
};

const line = {
  state: { ...localS.getJSON(keys.stateLine, defaultState) },
  getters: {
    lineList: state => state.list,
    areaList: state => state.area_list,
    lineCount: state => state.count
  },
  mutations: {
    [FETCH_LINE](state, payload) {
      state.area_list = payload.area_list;
      state.list = payload.list;
      state.count = payload.count;

      localS.setJSON(keys.stateLine, state);
    }
  },
  actions: {
    fetchLineInfo({ commit, state }, params) {
      commit(FETCH_LINE, { ...state, list: null, count: 0 });

      return new Promise((resolve, reject) => {
        api
          .getRouteInfo(params)
          .then(response => {
            const { data } = response;

            let list = [];
            data.list.forEach(i => {
              const currentDate = Vue.moment();
              const expireDate = Vue.moment(i.expire_time * 1000);
              const isExpired = expireDate.diff(currentDate, "days") < 0;
              list.push({
                ...i,
                bandwidthText: i.bandwidth + "M",
                expireTime: expireDate.format("YYYY-MM-DD HH:mm:ss"),
                createdTime: i.created
                  ? Vue.moment(i.created * 1000).format("YYYY-MM-DD HH:mm:ss")
                  : "",
                status: i18n.t(
                  isExpired
                    ? "lineManagement.expired"
                    : i.state === 1
                    ? "lineManagement.in_use"
                    : i.state === 2
                    ? "lineManagement.paused"
                    : "lineManagement.to_be_config"
                ),
                statusColor: isExpired
                  ? "gray-light"
                  : i.state === 1
                  ? "main-light"
                  : i.state === 2
                  ? "orange-light"
                  : "orange-light"
              });
            });

            commit(FETCH_LINE, { ...data, list, count: response.count });
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    },
    exportLineInfo({ commit }, params) {
      api
        .getRouteInfo(params)
        .then(response => {
          const { data } = response;
          helper.exportToExcel(
            data.list.map(i => {
              i[3] += "M";
              return i;
            }),
            data.table_head
          );
        })
        .catch(err => {
          console.error(err);
        });
    }
  }
};

export default line;
