<template>
	<div v-if="!title" :style="styles">
		<select
			:class="`form-control ${!rounded ? 'rounded-0' : ''} ` + selectClasses"
			:value="value"
			@input="$emit('input', $event.target.value)"
			@change="onSelectChange($event)"
			:disabled="disabled"
			required
		>
			<option class="d-none" value="" disabled selected>
				{{ placeholder }}
			</option>
			<option v-show="optionAll" value="">
				{{ $t("select.option.all") }}
			</option>
			<option v-for="(item, index) in list" :key="index" :value="index">
				{{ optionStart + (optionKey ? item[optionKey] : item) + optionEnd }}
			</option>
		</select>
		<!-- <vue-skeleton-loader
			v-else
			width="100%"
			height="38px"
			style="min-width: 176px"
			:rounded="rounded"
			:radius="rounded ? 5 : 0"
		/> -->
	</div>
	<div v-else-if="titleAlign === 'left'" class="form-group row">
		<label
			:class="'col-auto col-form-label ' + titleClasses"
			:style="titleStyles"
		>
			{{ title }}
		</label>
		<div class="col-7 p-0">
			<select
				:class="
					`form-control ${!rounded ? 'rounded-0' : ''} ` + selectClasses
				"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="onSelectChange($event)"
				:disabled="disabled"
				required
			>
				<option class="d-none" value="" disabled selected>
					{{ placeholder }}
				</option>
				<option v-show="optionAll" value="">
					{{ $t("select.option.all") }}
				</option>
				<option v-for="(item, index) in list" :key="index" :value="index">
					{{
						optionStart + (optionKey ? item[optionKey] : item) + optionEnd
					}}
				</option>
			</select>
			<!-- <vue-skeleton-loader
				v-else
				width="100%"
				height="38px"
				style="min-width: 176px"
				:rounded="rounded"
				:radius="rounded ? 5 : 0"
			/> -->
		</div>
	</div>
	<div v-else-if="titleAlign === 'top'">
		<label :class="titleClasses" :style="titleStyles">{{ title }}</label>
		<div class="input-group">
			<select
				:class="
					`form-control ${!rounded ? 'rounded-0' : ''} ` + selectClasses
				"
				:value="value"
				@input="$emit('input', $event.target.value)"
				@change="onSelectChange($event)"
				:disabled="disabled"
				required
			>
				<option class="d-none" value="" disabled selected>
					{{ placeholder }}
				</option>
				<option v-show="optionAll" value="">
					{{ $t("select.option.all") }}
				</option>
				<option v-for="(item, index) in list" :key="index" :value="index">
					{{
						optionStart + (optionKey ? item[optionKey] : item) + optionEnd
					}}
				</option>
			</select>
			<!-- <vue-skeleton-loader
				v-else
				width="100%"
				height="38px"
				style="min-width: 175px"
				:rounded="rounded"
				:radius="rounded ? 5 : 0"
			/> -->
		</div>
	</div>
</template>

<script>
	export default {
		name: "cSelect",
		props: {
			rounded: {
				type: Boolean,
				default: false,
			},
			optionAll: {
				type: Boolean,
				default: true,
			},
			styles: {
				type: String,
				default: "",
			},
			titleAlign: {
				type: String,
				default: "left",
			},
			titleClasses: {
				type: String,
				default: "",
			},
			titleStyles: {
				type: String,
				default: "",
			},
			selectClasses: {
				type: String,
				default: "",
			},
			title: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: "-",
			},
			list: {
				type: [Array, null],
				default: [],
			},
			optionKey: {
				type: String,
				default: "",
			},
			optionStart: {
				type: String,
				default: "",
			},
			optionEnd: {
				type: String,
				default: "",
			},
			value: {
				type: String,
				default: "",
			},
			changed: {
				type: Function,
				default: () => {},
			},
			disabled: {
				type: Boolean,
				default: false,
			},
		},
		methods: {
			onSelectChange(e) {
				this.changed(this.list[e.target.value], Number(e.target.value));
			},
		},
	};
</script>
