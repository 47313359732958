import Vue from "vue";
import Vuex from "vuex";

import sys from "@/store/modules/sys";
import user from "@/store/modules/user";
import buy from "@/store/modules/buy";
import line from "@/store/modules/line";
import order from "@/store/modules/order";
import subacc from "@/store/modules/subacc";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: { sys, user, buy, line, order, subacc }
});

export default store;
