<template>
	<div class="table-responsive">
		<table :class="'table ' + tableClass" :style="tableStyle">
			<thead>
				<tr>
					<th
						v-for="(column, idx) in columns"
						:key="idx"
						:class="column.class || ''"
						:style="column.style || ''"
					>
						<template v-if="column.title">{{ column.title }}</template>
						<!-- sort icon btn -->
						<button
							v-if="column.sortable"
							type="button"
							class="btn btn-sm rounded-circle no-focus p-0"
							style="width: unset"
							@click="$emit('sort-column', { ...column, index: idx })"
						>
							<div class="d-flex">
								<span
									:class="
										'm-0' +
										(column.sortMode &&
											column.sortMode === 'desc' &&
											' color-main')
									"
									>&uarr;</span
								>
								<span
									:class="
										'm-0' +
										(column.sortMode &&
											column.sortMode === 'asc' &&
											' color-main')
									"
									>&darr;</span
								>
							</div>
						</button>
						<!-- checkbox -->
						<checkbox
							v-if="column.checkable"
							id="all"
							classes="mb-0"
							:value="
								!!(
									column.checked &&
									column.checked.length === data.length
								)
							"
							:onCheck="(checked) => column.checkFunc(checked, idx)"
						/>
						<!-- component -->
						<component
							v-if="column.component"
							:is="column.component"
							:id="`column${idx}`"
							:index="idx"
							:column="{ ...column }"
							:callback="column.callback"
						/>
					</th>
				</tr>
			</thead>
			<tbody class="bg-white">
				<template v-if="data === null">
					<tr class="table-hover-non">
						<td :colspan="columns.length" class="text-center">
							<img
								src="@/assets/imgs/loading.gif"
								class="my-3"
								style="width: 35px"
							/>
						</td>
					</tr>
				</template>
				<tr v-else-if="!data || !data.length" class="table-hover-non">
					<td :colspan="columns.length" class="text-center py-4">
						<img src="@/assets/imgs/noData.png" alt="No Data!" />
						<h5 class="font-weight-bold pt-3 pb-2">
							{{ $t("no_data") }}
						</h5>
					</td>
				</tr>
				<tr v-for="(row, idx) in data" :key="idx">
					<td
						v-for="(column, idxC) in columns"
						:key="idxC"
						:class="column.class"
					>
						<!-- checkbox -->
						<checkbox
							v-if="column.checkable"
							:id="`row${idx}_column${idxC}`"
							classes="mb-1"
							:value="
								!!(
									column.checked &&
									column.checked.includes(row[column.key])
								)
							"
							:onCheck="
								(checked) =>
									column.checkFunc(checked, idxC, row[column.key])
							"
						/>
						<!-- component -->
						<component
							v-else-if="column.rowComponent"
							:is="column.rowComponent"
							:id="`row${idx}_column${idxC}`"
							:value="row[column.key]"
							:index="idx"
							:data="{ ...row }"
							:column="{ ...column }"
							:callback="column.rowCallback"
						/>
						<!-- normal -->
						<div
							v-else-if="!column.checkable"
							v-html="row[column.key]"
							:class="column.rowClass"
						>
							{{ row[column.key] }}
						</div>
					</td>
				</tr>
				<tr
					v-show="rowCounts / limit > 1"
					class="border-top table-hover-non"
				>
					<td :colspan="columns.length" class="text-center">
						<pagination
							noBorder
							align="end"
							:limit="limit"
							:total="rowCounts"
							:current="page"
							:onPageChange="toPage"
						/>
					</td>
				</tr>
				<slot name="tr"></slot>
			</tbody>
		</table>
	</div>
</template>

<script>
	export default {
		name: "cTable",
		components: {
			checkbox: () => import("@/components/checkbox"),
			pagination: () => import("@/components/pagination"),
		},
		props: {
			tableClass: {
				type: String,
				default: "th-style1",
			},
			tableStyle: {
				type: String,
				default: "",
			},

			columns: Array,
			// key: String
			// title: String
			// rowClass: String

			// rowComponent
			// rowCallback

			// sortable: Boolean
			// sortMode: "asc" | "desc"

			// checkable: Boolean
			// checked: []
			// checkFunc: () => {}

			// component
			// callback

			data: Array,
			// @sort-column

			// pagination
			limit: Number,
			rowCounts: Number,
			page: Number,
			toPage: Function,
		},
	};
</script>
