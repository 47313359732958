var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"bg-white p-4 my-2 shadow-sm"},[_c('h5',[_c('small',{staticClass:"font-weight-bold"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("order.basic_info"))+"\n\t\t\t")])]),_vm._v(" "),_c('div',{staticClass:"row bg-lighter-gray color-darker-gray px-1 py-3 mx-0"},[_c('div',{staticClass:"col-12 col-sm-3 col-md-2 py-1"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tc("order.order_number", 1))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0 text-break"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.info ? _vm.info.parent_order_id : "-")+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-3 col-md-2 py-1"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tc("order.order_type", 1))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t(
						("order." + (_vm.info
								? _vm.info.type === 1
									? "renew"
									: _vm.info.type === 2
									? "edit_bandwidth_device"
									: "new_purchase"
								: "-"))
					))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-3 col-md-2 py-1"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$tc("order.creation_time", 1))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0"},[_vm._v(_vm._s(_vm.info ? _vm.info.createdText : "-"))]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-3 col-md-2 py-1"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("order.order_status"))+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0 color-main"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.info
						? _vm.$t(
								("order." + (_vm.info.audit_state ? "confirmed" : "to_be_confirm"))
						  )
						: "-")+"\n\t\t\t")]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-3 col-md-2 py-1"},[_vm._v(_vm._s(_vm.$t("order.amount")))]),_vm._v(" "),_c('div',{staticClass:"col-12 col-sm-9 col-md-10 py-1 mb-3 mb-sm-0"},[_vm._v("$ "+_vm._s(_vm.info ? _vm.info.totalMoney : "-"))])]),_vm._v(" "),_c('h5',{staticClass:"mt-4"},[_c('small',{staticClass:"font-weight-bold"},[_vm._v("\n\t\t\t\t"+_vm._s(_vm.$t("order.include_line_list"))+"\n\t\t\t")])]),_vm._v(" "),_c('c-table',{attrs:{"tableClass":_vm.rows && !_vm.rows.length ? 'th-style1 table-hover-non' : 'th-style1',"tableStyle":"min-width: 750px","columns":_vm.columns,"data":_vm.rows}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }