<template>
	<div class="container">
		<div class="bg-white shadow py-5 mb-5">
			<h5 class="text-center font-weight-bold mb-5">
				{{ $t("loginMode.subtitle") }}
			</h5>
			<div class="row justify-content-center mb-5">
				<div class="col-auto">
					<div
						:class="
							'card text-center' +
							(lineMode === 2 ? ' border-main' : ' border-gray')
						"
						@click="clickLine(2)"
					>
						<div class="card-body">
							<div>
								<img
									src="@/assets/imgs/exclusiveLine.png"
									alt="exclusive line"
									style="height: 110px"
								/>
							</div>
							<h6 class="mt-3">{{ $t("loginMode.exclusive_line") }}</h6>
							<p class="text-muted" style="max-width: 200px">
								<small>
									{{ $t("loginMode.exclusive_line_desc") }}
								</small>
							</p>
						</div>
					</div>
				</div>
				<div class="col-auto">
					<div
						:class="
							'card text-center' +
							(lineMode === 1 ? ' border-main' : ' border-gray')
						"
						@click="clickLine(1)"
					>
						<div class="card-body">
							<div>
								<img
									src="@/assets/imgs/sharedLine.png"
									alt="shared line"
									style="height: 110px"
								/>
							</div>
							<h6 class="mt-3">{{ $t("loginMode.shared_line") }}</h6>
							<p class="text-muted" style="max-width: 200px">
								<small>
									{{ $t("loginMode.shared_line_desc") }}
								</small>
							</p>
						</div>
					</div>
				</div>
			</div>
			<div class="row justify-content-center">
				<div class="col-auto">
					<c-button
						styles="min-width: 300px"
						:text="$t('loginMode.next_step')"
						:disabled="!lineMode"
						:clicked="clickNext"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { keys } from "@/utils";

	export default {
		name: "loginMode",
		components: {
			cButton: () => import("@/components/button"),
		},
		computed: {
			lineMode() {
				return this.$store.getters.lineMode;
			},
		},
		methods: {
			clickLine(line) {
				this.$store.dispatch("setLineMode", line);
			},
			clickNext() {
				this.$router.push({ name: keys.login });
			},
		},
	};
</script>
