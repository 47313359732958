import i18n from "@/i18n";

export const tablePayOrder = () => [
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.quantity"),
    key: "number"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_device"),
    key: "device_num"
  },
  {
    class: "text-center",
    title: i18n.tc("table.purchase_time", 1),
    key: "month"
  },
  {
    class: "text-center",
    title: i18n.t("table.amount"),
    key: "moneyText",
    rowClass: "color-orange"
  }
];

const lineManagementTabs = [
  // 1
  ({ checkFunc, editLabel, operate }) => [
    {
      class: "text-center",
      key: "id",
      checkable: true,
      checkFunc: checkFunc
    },
    {
      class: "text-center",
      title: i18n.t("table.line_id"),
      key: "id"
    },
    {
      class: "text-center",
      title: i18n.t("table.ip_address"),
      key: "ip"
    },
    {
      class: "text-center",
      title: i18n.t("table.line_name"),
      key: "area_name"
    },
    {
      class: "text-center",
      title: i18n.t("table.bandwidth"),
      key: "bandwidthText"
    },
    {
      class: "text-center",
      title: i18n.t("table.num_of_device"),
      key: "device_num"
    },
    {
      class: "text-center",
      title: i18n.t("table.expire_time"),
      key: "expireTime",
      sortable: true,
      field: "expire_time"
    },
    {
      class: "text-center",
      title: i18n.t("table.creation_time"),
      key: "createdTime",
      sortable: true,
      field: "created"
    },
    {
      class: "text-center",
      title: i18n.t("table.line_label"),
      key: "label",
      rowComponent: () => import("@/components/table/components/editableLabel"),
      rowCallback: editLabel
    },
    {
      class: "text-center",
      title: i18n.t("table.state"),
      key: "status",
      rowComponent: () => import("@/components/table/components/status")
    },
    {
      class: "text-center",
      title: i18n.t("table.operate"),
      rowComponent: () => import("@/components/table/components/buttons"),
      rowCallback: operate,
      rowData: [1, 0]
    }
  ],
  // 2
  ({ checkFunc }) => [
    {
      class: "text-center",
      key: "id",
      checkable: true,
      checkFunc: checkFunc
    },
    {
      class: "text-center",
      title: i18n.t("table.line_id"),
      key: "id"
    },
    {
      class: "text-center",
      title: i18n.t("table.line_name"),
      key: "area_name"
    },
    {
      class: "text-center",
      title: i18n.t("table.bandwidth"),
      key: "bandwidthText"
    },
    {
      class: "text-center",
      title: i18n.t("table.num_of_device"),
      key: "device_num"
    },
    {
      class: "text-center",
      title: i18n.t("table.expire_time"),
      key: "expireTime",
      sortable: true,
      field: "expire_time"
    },
    {
      class: "text-center",
      title: i18n.t("table.creation_time"),
      key: "createdTime",
      sortable: true,
      field: "created"
    },
    {
      class: "text-center",
      title: i18n.t("table.state"),
      key: "status",
      rowComponent: () => import("@/components/table/components/status")
    }
  ],
  // 3
  ({ checkFunc, editLabel, operate }) => [
    {
      class: "text-center",
      key: "id",
      checkable: true,
      checkFunc: checkFunc
    },
    {
      class: "text-center",
      title: i18n.t("table.line_id"),
      key: "id"
    },
    {
      class: "text-center",
      title: i18n.t("table.ip_address"),
      key: "ip"
    },
    {
      class: "text-center",
      title: i18n.t("table.line_name"),
      key: "area_name"
    },
    {
      class: "text-center",
      title: i18n.t("table.bandwidth"),
      key: "bandwidthText"
    },
    {
      class: "text-center",
      title: i18n.t("table.num_of_device"),
      key: "device_num"
    },
    {
      class: "text-center",
      title: i18n.t("table.expire_time"),
      key: "expireTime",
      sortable: true,
      field: "expire_time"
    },
    {
      class: "text-center",
      title: i18n.t("table.line_label"),
      key: "label",
      rowComponent: () => import("@/components/table/components/editableLabel"),
      rowCallback: editLabel
    },
    {
      class: "text-center",
      title: i18n.t("table.state"),
      key: "status",
      rowComponent: () => import("@/components/table/components/status")
    },
    {
      class: "text-center",
      title: i18n.t("table.operate"),
      rowComponent: () => import("@/components/table/components/buttons"),
      rowCallback: operate,
      rowData: [0]
    }
  ]
];
export const columnLineManagement = (tab, params) =>
  lineManagementTabs[tab - 1](params);

export const columnRenewLine = () => [
  {
    class: "text-center",
    title: i18n.t("table.line_id"),
    key: "id"
  },
  {
    class: "text-center",
    title: i18n.t("table.ip_address"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_device"),
    key: "device_num"
  },
  {
    class: "text-center",
    title: i18n.tc("table.purchase_time", 2),
    key: "monthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.amount"),
    key: "amount",
    rowClass: "color-orange"
  }
];

export const columnModifyBandwidth = ({ changeBandwidth, changeDeviceNum }) => [
  {
    class: "text-center",
    title: i18n.t("table.line_id"),
    key: "id"
  },
  {
    class: "text-center",
    title: i18n.t("table.ip_address"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.modify_bandwidth"),
    key: "bandwidthTo",
    rowComponent: () => import("@/components/table/components/select"),
    rowCallback: changeBandwidth,
    rowData: "" // operateSelect
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_device"),
    key: "device_num"
  },
  {
    class: "text-center",
    title: i18n.t("table.modify_num_of_device"),
    key: "deviceNumTo",
    component: () => import("@/components/table/components/popover"),
    popover: {
      content: i18n.t("table.modify_num_of_device_desc")
    },
    rowComponent: () => import("@/components/table/components/counter"),
    rowCallback: changeDeviceNum
  },
  {
    class: "text-center",
    title: i18n.tc("table.remaining_time", 2),
    key: "daysLeft"
  },
  {
    class: "text-center",
    style: "width: 110px",
    title: i18n.t("table.amount"),
    key: "amountText",
    rowClass: "color-orange"
  }
];

export const columnLineDetails = () => [
  {
    class: "text-center",
    title: i18n.t("table.line_id"),
    key: "id"
  },
  {
    class: "text-center",
    title: i18n.t("table.ip_address"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_device"),
    key: "device_num"
  },
  {
    class: "text-center",
    title: i18n.tc("table.expire_time", 1),
    key: "expireTime"
  },
  {
    class: "text-center",
    title: i18n.t("table.state"),
    key: "status",
    rowComponent: () => import("@/components/table/components/status")
  }
];

export const columnLineSubAccount = ({ switchState, operate }) => [
  {
    class: "text-center",
    title: i18n.t("table.number"),
    key: "uid"
  },
  {
    class: "text-center",
    title: i18n.t("table.sub_account"),
    key: "username"
  },
  {
    class: "text-center",
    title: i18n.t("table.sub_account_password"),
    key: "password"
  },
  {
    class: "text-center",
    title: i18n.t("table.disable_status"),
    key: "state",
    rowComponent: () => import("@/components/table/components/switch"),
    rowCallback: switchState
  },
  {
    class: "text-center",
    title: i18n.t("table.operate"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: [3]
  }
];

export const columnLineWhitelist = ({ operate }) => [
  {
    class: "text-center",
    title: i18n.t("table.number"),
    key: "id"
  },
  {
    class: "text-center",
    title: i18n.t("table.connect_address"),
    key: "white"
  },
  {
    class: "text-center",
    title: i18n.t("table.operate"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: [2]
  }
];

export const columnOrder = ({ operate }) => [
  {
    class: "text-center",
    title: i18n.t("table.order_number"),
    key: "parent_order_id"
  },
  {
    class: "text-center",
    title: i18n.t("table.order_type"),
    key: "typeText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_line"),
    key: "child_count"
  },
  {
    class: "text-center",
    title: i18n.t("table.amount"),
    key: "amount"
  },
  {
    class: "text-center",
    title: i18n.t("table.payment_method"),
    key: "paymentMethod"
  },
  {
    class: "text-center",
    title: i18n.t("table.creation_time"),
    key: "createdTime"
  },
  {
    class: "text-center",
    title: i18n.t("table.state"),
    key: "status",
    rowComponent: () => import("@/components/table/components/status")
  },
  {
    class: "text-center",
    title: i18n.t("table.operate"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: "" // operateBtns
  }
];

export const columnOrderToBeConfirm = ({ operate }) => [
  {
    class: "text-center",
    title: i18n.t("table.order_number"),
    key: "parent_order_id"
  },
  {
    class: "text-center",
    title: i18n.t("table.order_type"),
    key: "typeText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_line"),
    key: "child_count"
  },
  {
    class: "text-center",
    title: i18n.t("table.amount"),
    key: "amount"
  },
  {
    class: "text-center",
    title: i18n.t("table.payment_method"),
    key: "paymentMethod"
  },
  {
    class: "text-center",
    title: i18n.t("table.payment_status"),
    key: "paymentStatus"
  },
  {
    class: "text-center",
    title: i18n.t("table.payment_voucher"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: [7]
  },
  {
    class: "text-center",
    title: i18n.t("table.creation_time"),
    key: "createdTime"
  },
  {
    class: "text-center",
    title: i18n.t("table.state"),
    key: "status",
    rowComponent: () => import("@/components/table/components/status")
  },
  {
    class: "text-center",
    title: i18n.t("table.operate"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: "" // operateBtns
  }
];

export const columnOrderDetails = () => [
  {
    class: "text-center",
    title: i18n.t("table.line_id"),
    key: "route_id"
  },
  {
    class: "text-center",
    title: i18n.t("table.ip_address"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.num_of_device"),
    key: "deviceNum"
  },
  {
    class: "text-center",
    title: i18n.tc("table.purchase_time", 1),
    key: "month"
  },
  {
    class: "text-center",
    title: i18n.t("table.state"),
    key: "status",
    rowComponent: () => import("@/components/table/components/status")
  },
  {
    class: "text-center",
    title: i18n.t("table.amount"),
    key: "amount",
    rowClass: "color-orange"
  }
];

export const columnSubAccount = ({ checkFunc, togglePassword, operate }) => [
  {
    class: "text-center",
    key: "uid",
    checkable: true,
    checkFunc: checkFunc
  },
  {
    class: "text-center",
    title: "ID",
    key: "uid"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_ip"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_name"),
    key: "area_name"
  },
  {
    class: "text-center",
    title: i18n.t("table.bandwidth"),
    key: "bandwidthText"
  },
  {
    class: "text-center",
    title: i18n.t("table.line_label"),
    key: "label"
  },
  {
    class: "text-center",
    title: i18n.t("table.account"),
    key: "username"
  },
  {
    class: "text-center",
    title: i18n.t("table.password"),
    key: "password",
    component: () => import("@/components/table/components/togglePassword"),
    callback: togglePassword,
    rowComponent: () => import("@/components/table/components/toggleText")
  },
  {
    class: "text-center",
    title: i18n.t("table.last_login_time"),
    key: "lastLoginTime"
  },
  {
    class: "text-center",
    title: i18n.t("table.state"),
    key: "status",
    rowComponent: () => import("@/components/table/components/status")
  },
  {
    class: "text-center",
    title: i18n.t("table.operate"),
    rowComponent: () => import("@/components/table/components/buttons"),
    rowCallback: operate,
    rowData: [3, 4]
  }
];

export const columnLoginRecord = () => [
  {
    class: "text-center",
    title: i18n.t("table.location"),
    key: "region"
  },
  {
    class: "text-center",
    title: i18n.t("table.device"),
    key: "ua"
  },
  {
    class: "text-center",
    title: i18n.t("table.ip_address"),
    key: "ip"
  },
  {
    class: "text-center",
    title: i18n.t("table.login_time"),
    key: "loginTime"
  }
];
