<template>
	<ul class="nav nav-tabs">
		<slot />
	</ul>
</template>

<script>
	export default {
		name: "tabBar",
	};
</script>
