<template>
	<div class="container-fluid">
		<div class="bg-white p-4 my-2 shadow-sm">
			<filter-tab :onClickSearch="onClickSearch" />
		</div>
		<!-- table list -->
		<div class="bg-white p-4 my-2 shadow-sm">
			<!-- button -->
			<div class="row flex-wrap">
				<c-button
					v-if="filterParams.tab === 1"
					class="col-12 col-sm-auto mb-3 mb-md-0"
					:rounded="false"
					color="main-light"
					classes="px-4"
					:clicked="actionBatchRenew"
					:disabled="!lineList || !lineList.length"
				>
					<img
						src="@/assets/imgs/renew.png"
						alt="renew"
						class="pr-1"
						style="margin-top: -3px"
					/>
					{{ $t("lineManagement.batch_renew") }}
				</c-button>
				<c-button
					v-if="filterParams.tab === 1"
					class="col-12 col-sm-auto mb-3 mb-md-0"
					:rounded="false"
					color="main-light"
					classes="px-4"
					:clicked="actionBatchEditBandwidth"
					:disabled="!lineList || !lineList.length"
				>
					<img
						src="@/assets/imgs/bandwidth.png"
						alt="bandwidth"
						class="pr-1"
						style="margin-top: -3px"
					/>
					{{ $t("lineManagement.batch_edit_bandwidth_device") }}
				</c-button>
				<c-button
					v-if="filterParams.tab === 1"
					class="col-12 col-sm-auto mb-3 mb-md-0"
					:rounded="false"
					color="main-light"
					classes="px-4"
					:clicked="actionBatchAddWhitelist"
					:disabled="!lineList || !lineList.length"
				>
					<img
						src="@/assets/imgs/whitelist.png"
						alt="whitelist"
						class="pr-1"
						style="margin-top: -3px"
					/>
					{{ $t("lineManagement.batch_add_whitelist") }}
				</c-button>
				<c-button
					class="col-12 col-sm-auto ml-lg-auto"
					classes="mb-3 px-4"
					:clicked="actionExport"
					:disabled="!lineList || !lineList.length"
				>
					<img
						src="@/assets/imgs/export.png"
						alt="export"
						class="pr-1"
						style="margin-top: -3px"
					/>
					{{ $t("lineManagement.export") }}
				</c-button>
			</div>
			<!-- table -->
			<c-table
				class="mt-3"
				:tableClass="
					lineList && !lineList.length
						? 'th-style1 table-hover-non'
						: 'th-style1'
				"
				tableStyle="min-width: 1050px"
				:columns="columns"
				:data="lineList"
				@sort-column="sortLineList"
				:limit="limit"
				:rowCounts="count"
				:page="page"
				:toPage="onPageChanged"
			/>
		</div>
		<modal
			v-if="modalShow"
			ref="modal"
			:title="modalTitle"
			classes="modal-lg"
			:onClose="onModalClose"
		>
			<t-whitelist
				v-if="modalCase === 'whitelist'"
				loading
				:clickAdd="clickAddWhitelist"
			/>
			<template v-if="modalCase === 'lineLabel'">
				<div class="d-flex justify-content-center">
					<div style="width: 300px">
						<c-input
							id="edit_label"
							classes="mb-4"
							:placeholder="$t('lineManagement.input_label')"
							v-model="editLabelVal"
						/>
						<c-button
							fullWidth
							loading
							:text="$t('lineManagement.modify')"
							:clicked="clickModifyLabel"
						/>
					</div>
				</div>
			</template>
		</modal>
	</div>
</template>

<script>
	import { columnLineManagement } from "@/components/table/data";
	import { keys, api } from "@/utils";
	import { danger, success } from "@/components/alert/index.js";

	export default {
		name: "lineManagementList",
		components: {
			filterTab: () => import("@/pages/lineManagement/list/filterTab"),
			cButton: () => import("@/components/button"),
			cTable: () => import("@/components/table"),
			modal: () => import("@/components/modal"),
			tWhitelist: () => import("@/components/templates/whitelist"),
			cInput: () => import("@/components/input"),
		},
		data() {
			return {
				// filter
				filterParams: { tab: 1 },
				// table
				columns: [],
				limit: 15,
				page: 1,
				// modal
				modalShow: false,
				modalTitle: "",
				modalCase: "",
				//
				currentEdit: null,
				editLabelVal: "",
			};
		},
		computed: {
			lineList() {
				return this.$store.getters.lineList;
			},
			count() {
				return this.$store.getters.lineCount;
			},
		},
		methods: {
			// click filter search
			onClickSearch(params) {
				this.filterParams = params;
				this.page = 1;
				// change table head
				this.columns = columnLineManagement(params.tab, {
					checkFunc: this.checkFunc,
					editLabel: this.editLabel,
					operate: this.operate,
				});

				if (params.field && params.order) {
					this.getLineInfo({
						field: params.field,
						order: params.order,
					});
				} else {
					this.getLineInfo();
				}
			},
			// action
			actionBatchRenew() {
				const checked = this.columns[0].checked || [];
				if (checked.length) {
					this.$router.push({
						name: keys.lineManagement + keys.lineManagementRenew,
						params: { id: checked.join(",") },
					});
				} else {
					danger(this.$t("lineManagement.select_renew_line"));
				}
			},
			actionBatchEditBandwidth() {
				const checked = this.columns[0].checked || [];
				if (checked.length) {
					this.$router.push({
						name: keys.lineManagement + keys.lineManagementModifyBandwidth,
						params: { id: checked.join(",") },
					});
				} else {
					danger(this.$t("lineManagement.select_edit_line"));
				}
			},
			actionBatchAddWhitelist() {
				const checked = this.columns[0].checked || [];
				if (checked.length) {
					this.modalTitle = this.$t("lineManagement.batch_add_whitelist");
					this.modalCase = "whitelist";
					this.modalShow = true;
				} else {
					danger(this.$t("lineManagement.select_add_whitelist_line"));
				}
			},
			actionExport() {
				this.getLineInfo({ export: 1 });
			},
			// table
			checkFunc(checked, columnIdx, id) {
				let columns = [...this.columns];
				if (id) {
					columns[columnIdx].checked = columns[columnIdx].checked || []; // set default to array
					if (checked) {
						columns[columnIdx].checked.push(id);
					} else {
						columns[columnIdx].checked = columns[columnIdx].checked.filter(
							(i) => i !== id
						);
					}
				} else {
					if (checked) {
						columns[columnIdx].checked = this.lineList.map((i) => i.id);
					} else {
						columns[columnIdx].checked = [];
					}
				}
				this.columns = columns;
			},
			sortLineList({ field, sortMode, index }) {
				const order = sortMode ? (sortMode === "desc" ? "asc" : "") : "desc";
				let columns = columnLineManagement(this.filterParams.tab, {
					checkFunc: this.checkFunc,
					editLabel: this.editLabel,
					operate: this.operate,
				});
				// update table column
				columns[index].sortMode = order;

				this.getLineInfo({
					field, // string order column
					order, // string desc.asc
				});
				this.columns = columns;
			},
			onPageChanged(page) {
				this.page = page;
				this.getLineInfo();
			},
			// table row action
			editLabel(item) {
				this.modalTitle = this.$t("lineManagement.line_label");
				this.modalCase = "lineLabel";
				this.modalShow = true;
				this.currentEdit = item;
				this.editLabelVal = item.label;
			},
			operate(index, params) {
				switch (index) {
					case 1:
						this.$router.push({
							name: keys.lineManagement + keys.lineManagementRenew,
							params: { id: params.id },
						});
						break;

					default:
						this.$router.push({
							name: keys.lineManagement + keys.lineManagementDetails,
							params: { data: params },
						});
						break;
				}
			},
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.modalShow = false;
				this.modalTitle = "";
				this.$store.dispatch("setIsLoading", false);
			},
			clickAddWhitelist(whitelist) {
				const _this = this;
				api.postRouteWhitelist({
					route_list: this.columns[0].checked,
					white: whitelist.join("\n"),
				})
					.then(() => {
						_this.clickCloseModal();
						_this.getLineInfo();
						success(
							_this.$t("add_success", [
								_this.$t("lineManagement.whitelist"),
							])
						);
					})
					.catch((err) => {
						_this.clickCloseModal();
					});
			},
			clickModifyLabel() {
				const _this = this;
				api.patchRouteInfo({
					id: this.currentEdit.id,
					label: this.editLabelVal,
					// id label state
				})
					.then(() => {
						_this.clickCloseModal();
						_this.getLineInfo();
						success(
							_this.$t("edit_success", [
								_this.$t("lineManagement.line_label"),
							])
						);
					})
					.catch((err) => {
						_this.clickCloseModal();
					});
			},
			// get list
			getLineInfo(params = {}) {
				this.$store
					.dispatch("export" in params ? "exportLineInfo" : "fetchLineInfo", {
						page: this.page, // int
						limit: this.limit, // int  defaut:15
						...this.filterParams,
						...params,
					})
					.then(() => {
						this.$store.dispatch("setIsLoading", false);
					})
					.catch(() => {
						this.$store.dispatch("setIsLoading", false);
					});
			},
		},
	};
</script>