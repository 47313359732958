<template>
	<div>
		<div v-if="state || (isUploadVoucher && !noUpload)" class="text-center">
			<img src="@/assets/imgs/orderSuccess.png" alt="success" class="mt-4" />
			<h5 class="font-weight-bold mt-4 mb-3">
				{{
					$t(
						`orderState.${
							isUploadVoucher
								? "upload_certificate_success"
								: "success_purchase"
						}`
					)
				}}
			</h5>
			<p class="text-muted mx-auto" style="max-width: 500px">
				{{ $t("orderState.success_purchase_desc") }}
			</p>
			<c-button
				classes="mt-3 mb-4 px-5"
				:text="$t('orderState.check_order')"
				:clicked="viewOrder"
			/>
		</div>
		<div v-else class="text-center">
			<img src="@/assets/imgs/orderFail.png" alt="fail" class="mt-4" />
			<h5 class="font-weight-bold mt-4">
				{{
					$t(
						`orderState.${
							noUpload ? "fail_upload_payment" : "payment_failed"
						}`
					)
				}}
			</h5>
			<p v-if="noUpload" class="text-muted mx-auto" style="max-width: 500px">
				{{ $t("orderState.fail_upload_payment_desc") }}
			</p>
			<c-button
				classes="my-4 px-5"
				:text="
					$t(
						`orderState.${
							noUpload ? 'upload_payment_voucher' : 'pay_again'
						}`
					)
				"
				:clicked="repayOrder"
			/>
		</div>
	</div>
</template>

<script>
	import { keys } from "@/utils";

	export default {
		name: "flashMessageOrder",
		components: {
			cButton: () => import("@/components/button"),
		},
		props: {
			state: {
				type: Boolean,
				default: true,
			},
			isUploadVoucher: {
				type: Boolean,
				default: false,
			},
			noUpload: {
				type: Boolean,
				default: false,
			},
			orderDetails: {
				type: [Object, String],
				default: "",
			},
			viewRouteName: {
				type: String,
				default: "",
			},
			viewRouteParams: {
				type: [Object, String],
				default: "",
			},
			repayRouteName: {
				type: String,
				default: "",
			},
			repayRouteParams: {
				type: [Object, String],
				default: "",
			},
		},
		methods: {
			viewOrder() {
				this.$router.push({
					name: this.viewRouteName
						? this.viewRouteName
						: keys.order + keys.orderDetails,
					params: this.viewRouteParams
						? this.viewRouteParams
						: { data: this.orderDetails },
				});
			},
			repayOrder() {
				this.$router.push({
					name: this.repayRouteName,
					params: this.repayRouteParams,
				});
			},
		},
	};
</script>
