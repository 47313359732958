import Vue from "vue";
import i18n from "@/i18n";
Vue.use(require("vue-moment"));

import { FETCH_SUBACC } from "@/store/mutations-type";
import { localS, keys, api, helper } from "@/utils";

const defaultState = {
  list: [],
  count: 0
};

const subacc = {
  state: { ...localS.getJSON(keys.stateSubAcc, defaultState) },
  getters: {
    subAccList: state => state.list,
    subAccCount: state => state.count
  },
  mutations: {
    [FETCH_SUBACC](state, payload) {
      state.list = payload.list;
      state.count = payload.count;

      localS.setJSON(keys.stateSubAcc, state);
    }
  },
  actions: {
    fetchSubAccount({ commit }, params) {
      commit(FETCH_SUBACC, { list: null, count: 0 });

      return new Promise((resolve, reject) => {
        api
          .getCompanyUserInfo(params)
          .then(response => {
            const { data } = response;

            let list = [];
            data.list.forEach(i => {
              const currentDate = Vue.moment();
              const expireDate = Vue.moment(i.route_expire_time * 1000);
              const isExpired = expireDate.diff(currentDate, "days") < 1;

              list.push({
                ...i,
                label: i.label || "-",
                bandwidthText: i.bandwidth + "M",
                lastLoginTime: i.last_login_time
                  ? Vue.moment(i.last_login_time * 1000).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                  : "-",
                status: i18n.t(
                  `subAccount.${
                    isExpired ? "expired" : i.state ? "disable" : "normal"
                  }`
                ),
                statusColor: isExpired
                  ? "gray-light"
                  : i.state
                  ? "orange-light"
                  : "main-light",
                btnActionStatus: i18n.t(
                  `subAccount.${i.state ? "enable" : "disable"}`
                ),
                btnColor: i.state ? "main" : "orange"
              });
            });

            commit(FETCH_SUBACC, { list: list, count: response.count });
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    },
    exportSubAccount({ commit }, params) {
      api
        .getCompanyUserInfo(params)
        .then(response => {
          const { data } = response;
          helper.exportToExcel(
            data.list.map(i => {
              i[3] += "M";
              return i;
            }),
            data.table_head
          );
        })
        .catch(err => {});
    }
  }
};

export default subacc;
