import instanceAxios from "@/utils/axios";
import { get, getJSON, getStoreValue } from "@/utils/localstorage";
import keys from "@/utils/key";
import { toFormData } from "@/utils/helper";

export function verifyAcc(params) {
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/verify/username",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded"
    },
    data: toFormData(params)
  });
}

export function register(params) {
  const channel = get(keys.channel);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/register",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      channel: channel ? channel : "official"
    },
    data: params
  });
}

export function login(params) {
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/login",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode")
    },
    data: toFormData(params)
  });
}

export function getCaptcha(params) {
  const user = getJSON(keys.stateUser);
  return instanceAxios({
    url: "/captcha",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
  });
}

export function postCaptcha(params) {
  const user = getJSON(keys.stateUser);
  return instanceAxios({
    url: "/captcha",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
  });
}

// 用户信息
export function getCompanyInfo() {
  const user = getJSON(keys.stateUser);
  return instanceAxios({
    url: "/company/info",
    method: "get",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    }
  });
}

// 修改用户信息
export function pathchCompanyInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/company/info",
    method: "post", // patch
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // old_password new_password
    // phone qq telegram
    // two_verify
  });
}

// 子账户信息
export function getCompanyUserInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/company/user/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // page limit uid export tab searchParams ip label created username
  });
}

// 更新子账户信息
export function patchCompanyUserInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/company/user/info",
    method: "post", // patch
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // uid[] password state
  });
}

// 获取线路列表信息
export function getRouteInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
  });
}

// 更新线路信息
export function patchRouteInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/info?id=" + params.id || "",
    method: "post", // patch
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // id label state
  });
}

// 0:新购信息 1:续费 2:带宽或终端数
// 批量或者单独购买信息
export function getBuyInfo(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/buy/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // type id
  });
}

// 线路白名单列表
export function getRouteWhitelist(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/white/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // page limit searchParams route_id
  });
}

// 添加线路白名单
export function postRouteWhitelist(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/white/info",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: params
    // route_list white
  });
}

// 删除线路白名单
export function delRouteWhitelist(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/route/white/info",
    method: "delete",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // route_list id
  });
}

// 订单列表
export function getOrderList(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/order/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // page limit export tab searchParams type parent_order_id created
  });
}

// 订单详细信息
export function getOrderDetail(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/order/detail",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    params
    // parent_order_id
  });
}

// 下单支付
export function postOrderPay(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/order/pay",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: params
    // "type": 0, 0:新购.1:续费.2:修改带宽/终端数
    // "payment": "ALIPAY",
    // "list": [
    //   {
    //     "id": 12,
    //     "flag_name": "hk",
    //     "number": 0,
    //     "bandwidth": 100,
    //     "device_num": 5,
    //     "day": 180,
    //     "postage_id": 12
    //   }
    // ]
  });
}

// 公告列表
export function getBoardcast() {
  const user = getJSON(keys.stateUser);
  return instanceAxios({
    url: "/broadcast/info",
    method: "get",
    headers: {
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    }
  });
}

// 上传图片
export function postUploadImg(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/upload",
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // file
  });
}

// 反馈
export function postFeedback(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/feedback/info",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // email content image_url
  });
}

// 上传支付凭证（线下转账）
export function postPayVoucher(params) {
  const user = getJSON(keys.stateUser);
  if (!params) {
    params = {};
  }
  return instanceAxios({
    url: "/order/pay/voucher",
    method: "post",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    },
    data: toFormData(params)
    // parent_order_id voucher_url
  });
}

// 套餐列表
export function getPostage() {
  const user = getJSON(keys.stateUser);
  return instanceAxios({
    url: "/postage/info",
    method: "get",
    headers: {
      "Content-Type": "application/query",
      "Line-Mode": getStoreValue(keys.stateSys, "lineMode"),
      Authorization: user.token || ""
    }
  });
}
