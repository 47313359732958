<template>
	<nav>
		<ol
			class="
				breadcrumb
				bg-transparent
				borderl-6
				border-main
				rounded-0
				my-4
				ml-4
				ml-sm-5
				py-0
			"
		>
			<li
				v-for="(i, index) in breadcrumbs"
				:key="index"
				:class="
					'user-select-none breadcrumb-item' +
					(breadcrumbs.length > 1 && index === breadcrumbs.length - 1
						? ' active'
						: '')
				"
			>
				<h5
					v-if="breadcrumbs.length < 2 || index !== breadcrumbs.length - 1"
					class="breadcrumb-text d-inline-block font-weight-bold mb-0"
				>
					<router-link
						v-if="typeof i === 'object'"
						:to="i.link"
						class="text-decoration-none"
					>
						{{ i.title }}
					</router-link>
					<template v-else>{{ i }}</template>
				</h5>
				<span v-else class="breadcrumb-subtext align-middle">{{ i }}</span>
			</li>
		</ol>
	</nav>
</template>

<script>
	export default {
		name: "breadcrumb",
		props: {
			breadcrumbs: {
				type: Array,
				default: [],
			},
		},
	};
</script>

<style scoped>
	.breadcrumb-item + .breadcrumb-item::before {
		content: ">";
	}

	.breadcrumb-text {
		font-size: 1rem;
	}

	.breadcrumb-subtext {
		font-size: 0.9rem;
	}
</style>