<template>
	<div
		:id="'dropdown-' + id"
		:class="'btn-group ' + groupClasses"
		role="group"
	>
		<button
			type="button"
			:class="`btn btn-${color} no-focus ` + classes"
			@click="onClick"
			:data-toggle="dropdownItems.length ? 'dropdown' : ''"
			:aria-haspopup="!!dropdownItems.length"
			aria-expanded="false"
			:title="btnTitle"
		>
			<slot>
				{{ text }}
			</slot>
		</button>
		<div
			:class="'dropdown-menu ' + dropdownClasses"
			v-if="dropdownItems.length"
		>
			<template v-for="(item, index) in dropdownItems">
				<div
					v-if="item === 'hr'"
					:key="index"
					class="dropdown-divider"
				></div>
				<div
					v-else-if="item['content' + curLang]"
					:key="index"
					class="px-3"
				>
					<div
						:class="
							'd-flex w-100 justify-content-between pb-2 mt-2 ' +
							(index !== dropdownItems.length - 1 ? 'border-bottom' : '')
						"
					>
						<div class="d-flex">
							<div v-if="!item.seen" class="ml-1 mr-3 mt-2">
								<div
									class="bg-red rounded"
									style="width: 8px; height: 8px"
								></div>
							</div>
							<!-- title -->
							<div>
								<!-- <p class="font-weight-bold mb-1">
									{{ item["title" + curLang] }}
								</p> -->
								<p
									class="mb-0 mr-3"
									style="min-width: 200px; line-height: 1.2"
								>
									<small>
										{{ item["content" + curLang] }}
										<router-link v-if="item.link" :to="item.link">
											{{ $t("go_to_view") }} >
										</router-link>
									</small>
								</p>
							</div>
						</div>
						<!-- date -->
						<small class="text-muted">{{ item.date }}</small>
					</div>
				</div>
				<router-link
					v-else-if="item.link"
					:key="index"
					:class="
						'dropdown-item ' +
						(index === currentItem ? 'active ' : '') +
						item.classes
					"
					:to="item.link"
				>
					<template v-if="item.prepend">
						<div class="d-flex">
							<div v-if="item.prepend">
								<img
									v-if="item.prepend.type === 'img'"
									:src="item.prepend.src"
									alt="icon"
									:class="'mr-2 ' + item.prepend.classes"
								/>
							</div>
							{{ item.text || item }}
							<div v-if="item.append">
								<img
									v-if="item.append.type === 'img'"
									:src="item.append.src"
									alt="icon"
									:class="'mr-2 ' + item.append.classes"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						{{ item.text || item }}
						<p v-if="item && item.subText" class="text-muted m-0">
							<small>{{ item.subText }}</small>
						</p>
					</template>
				</router-link>
				<button
					v-else
					:key="index"
					:class="
						'dropdown-item ' +
						(index === currentItem ? 'active ' : '') +
						item.classes
					"
					@click="clickChange(index)"
				>
					<template v-if="item.prepend">
						<div class="d-flex">
							<div v-if="item.prepend">
								<img
									v-if="item.prepend.type === 'img'"
									:src="item.prepend.src"
									alt="icon"
									:class="'mr-2 ' + item.prepend.classes"
								/>
							</div>
							{{ item.text || item }}
							<div v-if="item.append">
								<img
									v-if="item.append.type === 'img'"
									:src="item.append.src"
									alt="icon"
									:class="'mr-2 ' + item.append.classes"
								/>
							</div>
						</div>
					</template>
					<template v-else>
						{{ item.text || item }}
						<p v-if="item && item.subText" class="color-dark-gray m-0">
							<small>{{ item.subText }}</small>
						</p>
					</template>
				</button>
			</template>
		</div>
	</div>
</template>

<script>
	export default {
		name: "buttonGroup",
		props: {
			id: {
				type: String,
				default: "",
			},
			groupClasses: {
				type: String,
				default: "", // btn-group-lg, btn-group-sm
			},
			color: {
				type: String,
				default: "main",
			},
			classes: {
				type: String,
				default: "",
			},
			btnTitle: {
				type: String,
				default: "",
			},
			text: {
				type: String,
				default: "",
			},
			dropdownClasses: {
				type: String,
				default: "",
			},
			dropdownItemsDefalut: {
				type: Number,
				default: -1,
			},
			dropdownItems: {
				type: Array,
				default: () => [],
			},
			onClick: {
				type: Function,
				default: () => {},
			},
			onChange: {
				type: Function,
				default: () => {},
			},
			onCloseCallback: {
				type: Function,
				default: () => {},
			},
			// for notification
			curLang: {
				type: String,
				default: "",
			},
		},
		data() {
			return {
				currentItem: this.dropdownItemsDefalut,
			};
		},
		methods: {
			clickChange(index) {
				this.currentItem = index;
				this.onChange(this.dropdownItems[index]);
			},
		},
		watch: {
			dropdownItemsDefalut: function (newVal) {
				this.currentItem = newVal;
			},
			"$i18n.locale": function () {
				this.curLang =
					this.$i18n.locale.split("_")[0] === "en"
						? "_" + this.$i18n.locale.split("_")[0]
						: "";
			},
		},
		mounted() {
			// set default
			this.curLang =
				this.$i18n.locale.split("_")[0] === "en"
					? "_" + this.$i18n.locale.split("_")[0]
					: "";

			if (this.id) {
				const _this = this;
				$(`#dropdown-${this.id}`).on("hide.bs.dropdown", function () {
					_this.onCloseCallback();
				});
			}
		},
	};
</script>
