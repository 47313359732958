<template>
	<span :class="`badge badge-${color} py-1`" style="min-width: 66px">
		{{ value }}
	</span>
</template>

<script>
	export default {
		name: "tStatus",
		props: {
			value: {
				type: String,
				default: "",
			},
			data: Object,
		},
		computed: {
			color() {
				return this.data.statusColor;
			},
		},
	};
</script>
