<template>
	<modal
		ref="modal"
		:title="modalTitle"
		classes="modal-lg"
		:onClose="onModalClose"
	>
		<!-- show Img -->
		<div class="row text-center">
			<div class="col-12">
				<img :src="imgUrl" />
			</div>
		</div>
	</modal>
</template>

<script>
	export default {
		name: "modalImage",
		components: {
			cButton: () => import("@/components/button"),
			modal: () => import("@/components/modal"),
		},
		props: {
			modalTitle: {
				type: String,
				default: "",
			},
			imgUrl: {
				type: String,
				default: "",
			},
			callback: Function,
		},
		methods: {
			// modal
			clickCloseModal() {
				this.$refs.modal.clickClose();
			},
			onModalClose() {
				this.$store.dispatch("setIsLoading", false);
				this.callback({
					clickCloseModal: this.clickCloseModal,
					closeModal: true,
				});
			},
		},
	};
</script>
