import i18n from "@/i18n";

import { FETCH_BUY, FETCH_PLACEORDER } from "@/store/mutations-type";
import { localS, keys, api, helper } from "@/utils";
import { tablePayOrder } from "@/components/table/data";

const defaultState = {
  device_unit_price: 0,
  list: null,
  payment_list: null,
  postage_list: null,
  rate_list: null,
  place_order_type: null,
  place_order_info: null,
  place_order_rows: []
};

const buy = {
  state: { ...localS.getJSON(keys.stateBuy, defaultState) },
  getters: {
    buyList: state => state.list,
    postageList: state => state.postage_list,
    rateList: state => state.rate_list,
    paymentList: state => state.payment_list,
    placeOrderInfo: state => state.place_order_info,
    placeOrderRows: state => state.place_order_rows,
    deviceUnitPrice: state => state.device_unit_price
  },
  mutations: {
    [FETCH_BUY](state, payload) {
      state.device_unit_price = payload.device_unit_price;
      state.list = payload.list;
      state.payment_list = payload.payment_list;
      state.postage_list = payload.postage_list;
      state.rate_list = payload.rate_list;

      localS.setJSON(keys.stateBuy, state);
    },
    [FETCH_PLACEORDER](state, payload) {
      state.place_order_type = payload.type;
      state.place_order_info = payload.info;
      state.place_order_rows = payload.rows;

      localS.setJSON(keys.stateBuy, state);
    }
  },
  actions: {
    fetchBuyInfo({ commit }, params) {
      return new Promise((resolve, reject) => {
        api
          .getBuyInfo(params)
          .then(response => {
            const { data } = response;
            let postage_list = [],
              rate_list = [];

            if (data.postage_list && data.postage_list.length) {
              data.postage_list.forEach(i => {
                postage_list.push({
                  ...i,
                  bandwidthText: i.bandwidth + "M"
                });
              });
            }
            if (data.rate_list && data.rate_list.length) {
              data.rate_list.forEach(item => {
                let formated = helper.getFormatedStringFromDays(item.day);
                formated = formated.filter(j => !!j.number);
                rate_list.push({
                  ...item,
                  month: formated.length ? formated[0].number : "0",
                  title: formated.length
                    ? i18n.t(formated[0].i18n, [formated[0].number])
                    : "0",
                  discount: 100 - item.rate
                });
              });
            }
            commit(FETCH_BUY, { ...data, postage_list, rate_list });
            resolve();
          })
          .catch(err => {
            reject();
          });
      });
    },
    fetchPlaceOrderList({ commit, state }, { type, ...params }) {
      return new Promise((resolve, reject) => {
        let orderRow = {};

        tablePayOrder().forEach(i => {
          if (i.key === "month") {
            let formated = helper.getFormatedStringFromDays(
              params.purchaseTime.day
            );
            formated = formated.filter(j => !!j.number);
            orderRow[i.key] = formated.length
              ? i18n.t(formated[0].i18n, [formated[0].number])
              : "0";
          } else if (i.key in params) {
            orderRow[i.key] = params[i.key];
          } else if (
            i.key in params.bandwidthInfo ||
            i.key === "bandwidthText" ||
            i.key === "moneyText"
          ) {
            if (i.key === "bandwidthText") {
              orderRow[i.key] = params.bandwidthInfo.bandwidth + "M";
            } else if (i.key === "moneyText") {
              // num of device
              const devices = params.device_num - 1;
              // (price * months)
              let purchasePrice = params.bandwidthInfo.money;
              purchasePrice += devices * state.device_unit_price;
              purchasePrice *= params.purchaseTime.month;
              // discount
              const discount = params.purchaseTime.rate / 100;
              purchasePrice *= discount;
              // qty
              const purchaseQty = params.number;
              // total price
              // format = (price + ((numOfDevice - 1) * deviceUnitPrice)*months * discount
              const price = purchasePrice * purchaseQty;
              const roundedPrice = helper.roundDecPoint(price / 100);

              orderRow.money = roundedPrice;
              orderRow[i.key] = "$ " + roundedPrice;
            } else {
              orderRow[i.key] = params.bandwidthInfo[i.key];
            }
          }
        });

        commit(FETCH_PLACEORDER, {
          type: type,
          info: { ...params },
          rows: [orderRow]
        });
        resolve();
      });
    }
  }
};

export default buy;
