<template>
	<button
		type="button"
		class="btn btn-sm rounded-circle no-focus p-0 ml-1"
		style="width: unset"
		@click="callback(index)"
	>
		<span class="'m-0">
			<img
				v-if="toggle === 'password'"
				src="@/assets/imgs/eyeOpen.png"
				alt="hide"
			/>
			<img
				v-if="toggle === 'text'"
				src="@/assets/imgs/eyeClose.png"
				alt="show"
			/>
		</span>
	</button>
</template>

<script>
	export default {
		name: "tTogglePassword",
		props: {
			index: Number,
			column: Object,
			callback: Function,
		},
		computed: {
			toggle() {
				return this.column.toggle || "password";
			},
		},
	};
</script>
