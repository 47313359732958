<template>
	<div class="d-flex justify-content-center">
		<div v-for="(btn, idx) in buttons" :key="idx" class="d-flex">
			<button
				type="button"
				:class="`btn btn-sm btn-text-${
					btn.key ? color : btn.color || 'main'
				} p-1`"
				style="width: unset"
				@click="callback(btn.btnIdx, data)"
			>
				<img
					v-if="btn.imgBtn"
					:src="data.btnImgUrl"
					style="width: 30px; max-height: 30px"
				/>
				{{ btn.key ? title(btn.key) : btn.text }}
			</button>
			<div
				v-if="idx !== buttons.length - 1"
				class="divider-vertical"
				style="height: 50%"
			>
				<div></div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "tButtons",
		props: {
			data: Object,
			column: Object,
			callback: Function,
		},
		data() {
			return {
				list: [
					{
						// 0
						text: this.$t("table.details"),
						btnIdx: 0,
					},
					{
						// 1
						color: "orange",
						text: this.$t("table.renew"),
						btnIdx: 1,
					},
					{
						// 2
						color: "orange",
						text: this.$t("table.remove"),
						btnIdx: 2,
					},
					{
						// 3
						text: this.$t("table.change_password"),
						btnIdx: 3,
					},
					{
						// 4
						key: "btnActionStatus",
						btnIdx: 4,
					},
					{
						// 5
						text: this.$t("table.upload_payment_voucher"),
						btnIdx: 5,
					},
					{
						// 6
						text: this.$t("table.modify_payment_voucher"),
						btnIdx: 6,
					},
					{
						// 7
						text: "",
						imgBtn: true,
						btnIdx: 7,
					},
				],
			};
		},
		computed: {
			buttons() {
				const btns = this.column.rowData;
				if (Array.isArray(btns)) {
					return btns.map((i) => this.list[i]);
				}
				return this.data.operateBtns.map((i) => this.list[i]);
			},
			color() {
				return this.data.btnColor;
			},
		},
		methods: {
			title(key) {
				return this.data[key];
			},
		},
	};
</script>
