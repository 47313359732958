<template>
	<div class="d-flex justify-content-center mt-3">
		<div style="width: 400px">
			<c-input
				v-if="oldPassword"
				type="password"
				id="oldPswd"
				titleStyles="width: 130px"
				colInputClasses="col-12 col-sm-7"
				:title="$t('account.old_password')"
				:placeholder="$t('account.input_old_password')"
				v-model="oldPswd"
			/>
			<c-input
				:type="inputPwsdType"
				id="newPswd"
				titleStyles="width: 130px"
				colInputClasses="col-12 col-sm-7"
				:title="$t('account.new_password')"
				:placeholder="$t('account.input_new_password')"
				v-model="newPswd"
			>
				<template v-slot:append>
					<div class="input-group-append">
						<span
							class="input-group-text bg-transparent"
							@click="togglePassword()"
						>
							<img
								src="@/assets/imgs/eyeOpen.png"
								alt="show/hide"
								class="imgTogglePassword"
							/>
						</span>
					</div>
				</template>
			</c-input>
			<c-input
				:type="inputPwsdType"
				id="confirmNewPswd"
				titleStyles="width: 130px"
				colInputClasses="col-12 col-sm-7"
				:title="$t('account.confirm_new_password')"
				:placeholder="$t('account.input_confirm_new_password')"
				v-model="confirmNewPswd"
			>
				<template v-slot:append>
					<div class="input-group-append">
						<span
							class="input-group-text bg-transparent"
							@click="togglePassword()"
						>
							<img
								src="@/assets/imgs/eyeOpen.png"
								alt="show/hide"
								class="imgTogglePassword"
							/>
						</span>
					</div>
				</template>
			</c-input>
			<!-- button -->
			<div class="row justify-content-center mt-5">
				<div class="col-auto">
					<c-button
						:loading="loading"
						classes="btn-gray px-4 px-sm-5 mb-3 mb-sm-0"
						:text="$t('account.cancel')"
						:clicked="cancel"
					/>
				</div>
				<div class="col-auto">
					<c-button
						:loading="loading"
						classes="px-4 px-sm-5 mb-3 mb-sm-0"
						:text="$t('account.modify')"
						:clicked="clickEdit"
					/>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { danger } from "@/components/alert/index.js";

	export default {
		name: "editPassword",
		components: {
			cInput: () => import("@/components/input"),
			cButton: () => import("@/components/button"),
		},
		props: {
			loading: {
				type: Boolean,
				default: false,
			},
			oldPassword: {
				type: Boolean,
				default: true,
			},
			cancel: {
				type: Function,
				default: () => {},
			},
			edit: {
				type: Function,
				default: () => {},
			},
		},
		data() {
			return {
				oldPswd: "",
				inputPwsdType: "password",
				newPswd: "",
				confirmNewPswd: "",
			};
		},
		methods: {
			togglePassword() {
				this.inputPwsdType =
					this.inputPwsdType == "text" ? "password" : "text";
				$(".imgTogglePassword").attr(
					"src",
					this.inputPwsdType == "text"
						? require("@/assets/imgs/eyeClose.png")
						: require("@/assets/imgs/eyeOpen.png")
				);
			},
			clickEdit() {
				if (this.newPswd.length < 6) {
					danger(this.$t("password_length"));
					this.$store.dispatch("setIsLoading", false);
				} else if (this.newPswd !== this.confirmNewPswd) {
					danger(this.$t("password_not_match"));
					this.$store.dispatch("setIsLoading", false);
				} else {
					this.edit({
						oldPassword: this.oldPswd,
						newPassword: this.newPswd,
					});
				}
			},
		},
	};
</script>