import axios from "axios";
import { danger } from "@/components/alert/index.js";
import i18n from "@/i18n";
import keys from "@/utils/key";
import { logout } from "@/utils/helper";

// instance axios
let instanceAxios = axios.create({
  baseURL: keys.baseApi, // api base url
  timeout: 8000 // time out
});

// Add a request interceptor
instanceAxios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
instanceAxios.interceptors.response.use(
  function(response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    const data = response.data;
    if (data.code !== 200 && data.code !== 401) {
      let msg = data.msg || i18n.t("please_try_again");
      if (data.code === 1130) {
        msg = i18n.t("user_exists");
      }
      if (data.code === 4072) {
        msg = i18n.t("invalid_verification_code");
      }
      danger(msg);
      // login fail
      if (data.code == 4003) {
        logout();
      }
      return Promise.reject(data);
    }
    return data;
  },
  function(error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    danger(error);
    return Promise.reject(error);
  }
);

export default instanceAxios;
