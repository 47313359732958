<template>
	<div v-if="!title" :class="'input-group ' + classes">
		<slot name="prepend"></slot>
		<input
			:type="type"
			:class="
				(type === 'file' ? 'form-control-file ' : 'form-control ') +
				inputClasses
			"
			:id="'id_input_' + id"
			:placeholder="placeholder"
			:value="value"
			@input="$emit('input', $event.target.value)"
			:disabled="disabled"
			@change="onChange($event)"
		/>
		<slot name="append"></slot>
	</div>
	<div v-else-if="titleAlign === 'left'" :class="'form-group row ' + classes">
		<label
			:for="'id_input_' + id"
			:class="'col-auto col-form-label font-weigit-bold ' + titleClasses"
			:style="titleStyles"
		>
			{{ title }}
		</label>
		<div :class="colInputClasses ? colInputClasses : 'col-sm-7 p-0'">
			<div class="input-group">
				<slot name="prepend"></slot>
				<input
					:type="type"
					:class="
						(type === 'file' ? 'form-control-file ' : 'form-control ') +
						inputClasses
					"
					:id="'id_input_' + id"
					:placeholder="placeholder"
					:value="value"
					@input="$emit('input', $event.target.value)"
					:disabled="disabled"
					@change="onChange($event)"
				/>
				<slot name="append"></slot>
			</div>
		</div>
	</div>
	<div v-else-if="titleAlign === 'top'" :class="classes">
		<label
			:for="'id_input_' + id"
			:class="'font-weigit-bold ' + titleClasses"
			:style="titleStyles"
			>{{ title }}</label
		>
		<div class="input-group">
			<slot name="prepend"></slot>
			<input
				:type="type"
				:class="
					(type === 'file' ? 'form-control-file ' : 'form-control ') +
					inputClasses
				"
				:id="'id_input_' + id"
				:placeholder="placeholder"
				:value="value"
				@input="$emit('input', $event.target.value)"
				:disabled="disabled"
				@change="onChange($event)"
			/>
			<slot name="append"></slot>
		</div>
	</div>
</template>

<script>
	export default {
		name: "cInput",
		props: {
			titleAlign: {
				type: String,
				default: "left",
			},
			classes: {
				type: String,
				default: "",
			},
			titleClasses: {
				type: String,
				default: "",
			},
			titleStyles: {
				type: String,
				default: "",
			},
			colInputClasses: {
				type: String,
				default: "",
			},
			inputClasses: {
				type: String,
				default: "",
			},
			id: {
				type: String,
				default: "",
			},
			type: {
				type: String,
				default: "text",
			},
			title: {
				type: String,
				default: "",
			},
			placeholder: {
				type: String,
				default: "",
			},
			value: {
				type: String,
				default: "",
			},
			disabled: {
				type: Boolean,
				default: false,
			},
			onChange: {
				type: Function,
				default: () => {},
			},
			onEnter: {
				type: Function,
				default: null,
			},
			rendered: {
				type: Function,
				default: null,
			},
		},
		mounted() {
			if (this.rendered) {
				this.rendered();
			}

			if (this.onEnter) {
				const _this = this;
				const input = document.getElementById("id_input_" + this.id);
				input.addEventListener("keyup", function (e) {
					var key = e.key || e.keyCode;
					if (key === "Enter" || key === 13) {
						_this.onEnter();
					}
				});
			}
		},
	};
</script>