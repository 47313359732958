const buyLine = "buyLine";
const lineManagement = "lineManagement";
const order = "order";
const subAccount = "subAccount";

const keys = {
  // api
  baseApi: process.env.BASE_API,
  // localStorage
  v: "version",
  lang: "language",
  stateSys: "36bcbb801f5052739af8220c6ea51434",
  stateUser: "ee11cbb19052e40b07aac0ca060c23ee",
  stateBuy: "0461ebd2b773878eac9f78a891912d65",
  stateLine: "6438c669e0d0de98e6929c2cc0fac474",
  stateSubAcc: "cc1c4e1a275bea05bbe7862ba0e13294",
  stateOrder: "70a17ffa722a3985b86d30b034ad06d7",
  channel: "channel",
  // path
  loginMode: "loginMode",
  login: "login",
  register: "register",
  forgetPassword: "forgetPassword",
  resetPassword: "resetPassword",
  buyLine: buyLine,
  buyLinePurchaseSpec: "purchaseSpec",
  buyLinePayOrder: "payOrder",
  buyLineOrderS: "success",
  buyLineOrderF: "fail",
  buyLineOrderVUploaded: "uploaded",
  buyLineOrderVNotUploaded: "notuploaded",
  lineManagement: lineManagement,
  lineManagementList: "list",
  lineManagementRenew: "renew",
  lineManagementModifyBandwidth: "modifyBandwidth",
  lineManagementPaymentS: "success",
  lineManagementPaymentF: "fail",
  lineManagementPaymentVUploaded: "uploaded",
  lineManagementPaymentVNotUploaded: "notuploaded",
  lineManagementDetails: "details",
  order: order,
  orderList: "list",
  orderDetails: "details",
  account: "account",
  subAccount: subAccount,
  // place order list item
  placeOrderItems: [
    "flag_name",
    "number",
    "bandwidth",
    "device_num",
    "day",
    "postage_id",
    "money"
  ],
  // payment methods
  defaultPayMethod: [
    { payment: "ALIPAY", alias_name: "支付宝" },
    { payment: "UNION", alias_name: "云闪付" },
    { payment: "UNIONPAY", alias_name: "银联" },
    { payment: "OFFLINEPAY", alias_name: "线下转账" }
  ],
  // side nav menu
  sideMenu: [
    {
      el: "link",
      icon: require("@/assets/imgs/market.png"),
      title: "buy_line",
      to: `/${buyLine}`,
      style: "padding-top: 23px; padding-bottom: 23px"
    },
    { el: "hr" },
    {
      el: "link",
      icon: require("@/assets/imgs/eart.png"),
      title: "manage_line",
      to: `/${lineManagement}`,
      style: "padding-top: 14px; padding-bottom: 14px"
    },
    {
      el: "link",
      icon: require("@/assets/imgs/list.png"),
      title: "my_order",
      to: `/${order}`,
      style: "padding-top: 14px; padding-bottom: 14px"
    },
    {
      el: "link",
      icon: require("@/assets/imgs/accountTree.png"),
      title: "sub_account",
      to: `/${subAccount}`,
      style: "padding-top: 14px; padding-bottom: 14px"
    }
  ],
  // country codes
  countryCodes: {
    AF: "93",
    AL: "355",
    AD: "376",
    AO: "244",
    AI: "1-264",
    AR: "54",
    AU: "61",
    AT: "43",
    AE: "971",
    BS: "1-242",
    BD: "880",
    BY: "375",
    BE: "32",
    BM: "1-441",
    BR: "55",
    CA: "1",
    CF: "236",
    CL: "56",
    CN: "86",
    CO: "57",
    CH: "41",
    CG: "242",
    CU: "53",
    CZ: "420",
    DK: "45",
    DE: "49",
    EG: "20",
    ES: "34",
    FI: "358",
    FR: "33",
    GR: "30",
    GB: "44",
    HK: "852",
    HU: "36",
    IS: "354",
    IN: "91",
    ID: "62",
    IE: "353",
    IL: "972",
    IT: "39",
    JM: "1-876",
    JP: "81",
    JO: "962",
    KZ: "7",
    KE: "254",
    KP: "850",
    KR: "82",
    KH: "855",
    KM: "269",
    KW: "965",
    LA: "856",
    LK: "94",
    LY: "218",
    LT: "370",
    LU: "352",
    MO: "853",
    MG: "261",
    MY: "60",
    MV: "960",
    MX: "52",
    MM: "95",
    NL: "31",
    NZ: "64",
    OM: "968",
    PK: "92",
    PA: "507",
    PE: "51",
    PH: "51",
    PL: "48",
    PT: "351",
    SG: "65",
    SE: "46",
    TW: "886",
    TH: "66",
    TR: "90",
    UA: "380",
    UK: "44",
    US: "1",
    VN: "84",
    ZA: "27",
    ZR: "243",
    ZM: "260",
    ZW: "263"
  }
};

export default keys;
